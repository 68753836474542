.c-lifestyle-tips {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-items: stretch;
    height: 100%;
    padding: 25px 0;

    h3 {
        @apply font-bold text-20;
        line-height: 26px;
    }

    >* {
        flex: 1;
    }

    &__content {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        @apply font-light text-16;
        line-height: 44px;
    }
}