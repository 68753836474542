table {
    width: 100%;

    &.light {
        margin-bottom: 1rem;
        border-collapse: inherit;
        border: 1px solid;
        border-radius: 10px;
        border-color: rgb(18, 22, 34);

        thead {
            text-align: left;
            line-height: 1rem;
            border-bottom: 1px solid;

            th {
                padding: 8px;
            }
        }

        tbody {
            text-align: left;
        }

        tbody tr:hover,
        tr td:hover {
            background-color: #ccc;
        }

        td {
            padding: 8px;
        }
    }

    &.dark {
        thead {
            tr {
                @apply text-xs font-medium text-gray-dark text-left;
            }
        }

        th {
            padding: 0.5rem;
        }

        tbody {
            @apply text-sm font-normal text-gray-dark;

            tr {
                @apply w-full py-2 bg-black-gray-5 hover:bg-blue-light text-black-gray border-b-8 border-white h-20;

                td {
                    padding: 0.5rem;
                    // max-width: 10rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                td:first-child {
                    border-radius: 10px 0 0 10px;
                }

                td:last-child {
                    border-radius: 0 10px 10px 0;
                }

                &.disabled {
                    @apply italic bg-gray-dark text-white;
                }
            }
        }
    }
}

.list__table-container {
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
}

.sort-icons-container {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 767.98px) {
    .list__table-container {
        thead {
            display: none;
        }

        tr {
            td {
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
                row-gap: 0.5rem;
                align-items: center;

                &:first-of-type {
                    padding-left: 1rem;
                }

                &:last-of-type {
                    justify-content: space-between;
                }

                .c-button {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}