.c-tag {
    @apply text-sm inline-flex items-center text-black-gray bg-yellow-dark rounded-full mb-1.5 mr-1;

    &.-small {
        @apply mb-0;
        max-height: 32px;

        .c-tag__content {
            @apply py-2 px-3 text-xs text-center;
        }
    }

    &__content {
        @apply py-2 pl-4;
    }

    &__close-icon {
        @apply pl-2 pr-2 cursor-pointer text-black;

        height: 40px;
        width: 40px;
    }
}