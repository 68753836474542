.c-cancellation-management {
  h3 {
    line-height: 2.5rem;
  }

  label {
    font-weight: 300 !important;
    font-size: 14px;
  }

  strong {
    font-weight: 500;
    font-size: 14px;
  }

  &__cancellation-details {
    display: grid;
    gap: 1.5rem;
  }


  .cancellation-details.visible {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2rem;
    margin: 1rem 0;
    border-left: 8px solid rgba(18, 22, 34, 0.05)
  }

  input[type="radio"] {
    margin-right: 1rem;
    margin-left: 1rem;
    height: 22px;
    width: 22px;
    accent-color: rgba(18, 22, 34, 1);
    cursor: pointer;
    display: block;
  }

  textarea {
    @apply border border-gray rounded-2xl text-base leading-6 py-2.5 px-5 w-1/2;
    font-size: 13px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
  }

  .reason.visible {
    background-color: white;
    border-radius: 5px;
  }

  label {
    margin-bottom: 0px !important;
    font-weight: normal !important;
    cursor: pointer;
  }

  .reason {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }

  .cancellation-button {
    margin-top: 1rem;

    &-white {
      button {
        border: 2px solid rgba(18, 22, 34, 1);
        color: rgba(18, 22, 34, 1);
        background-color: white;
      }
    }
  }

  .contact-us {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 1.5rem;
  }

  &__coupon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;

    h3 {
      font-size: 26px;
      text-align: center;
    }

    h1 {
      margin-bottom: 0.5rem !important;
    }

    &_offer {
      @apply bg-green-light;

      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 2.5rem;
      border-radius: 10px;
    }
  }

  &__hubspot-form {
    width: 66%;
  }

  &__reasons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__description {
    margin: 2rem 0;
    font-size: 16px !important;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__checkbox-row {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  }

  &__checkbox-wrapper {
    input {
      height: 1.25rem !important;
      width: 1.25rem !important;

      &:checked {
        accent-color: #121622;
      }
    }
  }
}

@media only screen and (max-width: 639.98px) {
  .c-cancellation-management {
    &__coupon-box {
      padding: 0;
    }
  }
}