.c-screen-intro {
    @apply font-light;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    padding: 0 var(--ion-margin, 2rem);
    color: var(--ion-color-main);
    justify-content: space-around;

    img {
        border-radius: 20px;
    }

    &__image {
        width: 42%;
    }


    &__note {
        @apply text-14;
        font-style: italic;
        padding-top: 10px;
    }

    &__footer {
        flex: none;
        padding: 20px 40px 10px;
        border-radius: 30px 30px 0px 0px;
        background: var(--ion-color-main-contrast);
        height: 230px;
        width: 42%;
        position: relative;
        text-align: center;

        h2 {
            @apply text-12 font-bold;
        }

        h4 {
            @apply font-light text-26;
            margin-bottom: 10px;
        }

        h5 {
            @apply text-12;
            text-transform: uppercase;
            margin-bottom: 4px;
            letter-spacing: 0.03em;
            color: rgba(0, 0, 0, 0.5);
        }

        p {
            @apply text-14;
            line-height: 24px;
            margin: 4px 0 0;
        }
    }
}

.c-movement-screen.-lifestyle {
    .c-screen-intro {

        &__footer {
            height: 290px;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-screen-intro {
        &__footer {
            width: auto;
            flex: auto;
            display: flex;
            flex-wrap: wrap;

            h2,
            h4,
            h5,
            p {
                width: 100%;
            }
        }

        &__image {
            width: 66%;
        }
    }
}