@import "headers";
@import "forms";
@import "tables";
@import "select";
@import "tooltip";

html,
body {
  @apply text-black-gray;
}

@media (hover: none) {
  .c-button {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .show-on-mobile {
    display: flex !important;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .show-on-mobile {
    display: none !important;
  }

  .show-on-desktop {
    display: flex !important;
  }
}

@media only screen and (max-width: 991.98px) {
  .hide-on-tablet {
    display: none !important;
  }
}