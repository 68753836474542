.c-symmio-license-checkbox {
    &__checkbox-wrapper {
        @apply border-2 border-solid border-black-gray-5;
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        padding: 32px 40px;


        input {
            @apply accent-black-gray;
            width: 22px;
            height: 22px;
        }

        .hello {
            background-color: black;
            padding: 20px;
            margin: 20px;
        }
    }

    &__is-symmio-access {
        @apply bg-black-gray-5;
    }

    &__checkbox-text {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        h3 {
            @apply text-16 font-bold;
            font-style: normal;
            line-height: normal;
        }
    }

    &__checkbox-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 24px;
        margin-bottom: 8px;

        h5 {
            @apply text-14 font-medium;
            font-style: normal;
            line-height: normal;
        }

        a {
            @apply text-14 font-light;
            font-style: normal;
            line-height: normal;
            text-decoration-line: underline;
        }
    }
}