.c-checkoutform {
  container-name: checkoutFormContainer;
  container-type: inline-size;

  &__container {
    display: flex;
    flex-flow: wrap;
    gap: 3rem;
    margin: 1.25rem 3rem;
  }

  &__container-modal {
    display: flex;
    flex-flow: wrap;
    gap: 3rem;
    margin: 1.25rem 0rem;
  }

  &__form {
    width: 45%;
  }

  &__form-title {
    @apply text-20 font-light;
    line-height: normal;
  }

  &__summary {
    width: 45%;
    background-color: #F3F3F4;
    border-radius: 1rem;
  }

  &_summary_line {
    @apply flex justify-between mx-5 my-5 font-light text-sm gap-6
  }

  &__upgrade-divider {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  &__discount-field {
    margin-bottom: 0rem !important;
  }

  &_divider {
    @apply text-gray;
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 0 !important;
  }

  &__summary-disclaimer {
    @apply text-12 font-light;
  }

  &__bottom-text {
    @apply text-12 font-light;
    margin: 0 8rem;
  }

  &__bottom-text-modal {
    @apply text-12 font-light;
    margin: 2.5rem 3rem 1.5rem 3rem;
  }

  &__start-subscription {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2.5rem;

    button {
      margin: 0;
    }
  }
}

@container checkoutFormContainer (max-width: 767.98px) {
  .c-checkoutform {
    &__form {
      width: 100%;
    }

    &__summary {
      width: 100%;
    }

    &__bottom-text {
      margin: 0 2rem;
      text-align: center;
    }

    &__start-subscription {
      display: flex;
      justify-content: center;
    }
  }
}