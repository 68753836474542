.c-notification-menu {
    @apply ml-4 flex items-center md:ml-6;

    &__button {
        @apply max-w-xs flex items-center text-sm rounded-full;
        background-color: #EFF9FF;
        width: 110px;
        height: 48px;
        justify-content: center;

        img {
            @apply w-12 rounded-full;
        }
    }

    &__scrollable {
        max-height: 27.5rem;
        overflow-y: auto;
    }

    &__notification {
        @apply flex flex-row text-sm font-bold;

        svg {
            @apply mx-2 h-5 w-5;
        }
    }

    &__count {
        @apply font-bold;
        margin-right: 0.75rem;
    }

    &__menu {
        @apply origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black-gray ring-opacity-5 focus:outline-none;

        //padding-top: 0.625rem;
        //padding-bottom: 0.625rem;
        margin-right: 1rem;
    }

    &__header {
        @apply block px-6 py-4 text-black-gray;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #F3F3F4;
    }

    &__item {
        @apply flex px-4 py-2 text-sm text-black-gray;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #F3F3F4;
        flex-direction: row;
        justify-content: space-between;

        &__info {
            @apply flex;
            height: 100%;
            flex-direction: row;
            align-items: center;
        }

        &__clear {
            display: none;
        }

        &:hover {
            //@apply bg-gray;
            background-color: #F3F3F4;
        }

        &:hover &__clear {
            //@apply bg-gray;
            display: block;
            margin-top: 1rem;
            cursor: pointer;
        }
    }

}

@media only screen and (max-width: 767.98px) {
    .c-notification-menu {
        margin-left: 0;

        &__button {
            background-color: #FFF;

            svg {
                @apply text-white;
            }
        }
    }
}