.c-date-input-option {
    padding-left: calc(var(--ion-safe-area-left, 0) + 16px);
    padding-right: calc(var(--ion-safe-area-right, 0) + 16px);
    text-align: center;
    margin-bottom: 31.3%;

    ion-item {
        @apply border-2 border-solid border-transparent;
        border-radius: 10px;
        --background: rgba(var(--ion-color-main-rgb), 0.05);
    }

    input {
        width: 291px;
        height: 90px;
        text-align: center;
        border-radius: 10px;
        font-size: 28px;
        letter-spacing: 2px;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    // .DatePicker {
    //     color: #000000;
    //     width: 291px;
    //     text-align: center;
    //     font-weight: 300;
    //     font-size: 28px;
    //     text-align: center;
    //     text-transform: uppercase;
    //     --placeholder-color: rgba(var(--ion-color-main-rgb), 0.05);
    //     margin-bottom: 21rem;
    // }
}