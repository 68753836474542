.p-resources {
    &__header {
        line-height: normal;
        padding-bottom: 10px;
    }

    &__card {
        @apply flex flex-col bg-black-gray-5 h-full;

        border-radius: 10px;
    }

    &__card,
    &__image-wrapper {
        width: 360px;
        max-width: 100%;

        img {
            border-radius: 10px 10px 0 0;
        }
    }
}