.c-line-chart {
    @apply bg-black;
    direction: rtl;
    overflow-x: auto;

    &__data-point {
        fill: white;
        transition: all 0.2s ease-in-out;

        &.-highlight {
            @apply fill-blue-dark;
            stroke: white;
            stroke-width: 3px;
        }

        &__label {
            @apply text-14 font-medium;
            fill: white;

            &:first-child {
                color: white;
            }
        }
    }

    &__data-point-filter {
        @apply fill-white;
        opacity: 0;
        -webkit-filter: url("#selected-blur");
        filter: url("#selected-blur");
        transition: opacity 0.2s ease-in-out;

        &.-selected {
            opacity: 1;
        }
    }

    &__data-point-hitarea {
        @apply fill-transparent;
        stroke: none;
    }

    &__content {
        direction: ltr;
    }

    &__line {
        @apply stroke-blue-dark;
        fill: none;
        stroke-width: 4px;
    }

    &__point-line {
        fill: none;
        transition: all 0.2s ease-in-out;

        &.-selected {
            stroke: white;
            stroke-width: 2px;
            stroke-dasharray: 2 2;
        }
    }

    &__grid {
        line {
            stroke: white;
            stroke-width: 0.5px;
            opacity: 0.4;
        }

        path {
            stroke-width: 0;
        }
    }

    &__axis {
        text {
            @apply text-12 font-bold;
            color: white;
            opacity: 0.6;
            transition: opacity 0.2s ease-in-out;

            &.-selected {
                opacity: 1;
            }
        }
    }

    &__wrapper {
        @apply rounded-3xl overflow-hidden;
        position: relative;

        &:after {
            content: "";
            background: linear-gradient(90deg, rgba(18, 22, 34, 0) 0%, #121622 70.83%);
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 30px;
            pointer-events: none;
        }

        &:before {
            content: "";
            background: linear-gradient(270deg, rgba(18, 22, 34, 0) 0%, #121622 70.83%);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 30px;
            z-index: 1;
            pointer-events: none;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            height: 20px;
            width: auto;
        }

        span {
            @apply text-12 font-bold;
            color: white;
            line-height: 40px;
            text-transform: uppercase;
        }
    }
}