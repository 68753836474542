.c-assessment-results {
    h1 {
        font-size: 26px;
        font-weight: 300;
        line-height: 34px;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
    }

    h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;
    }

    strong {
        font-weight: 500;
    }

    &__video-container-modal {
        .app-content-container {
            border: none;
            border-radius: unset;
        }
    }

    &__user-menu-container {
        background-color: rgba(18, 22, 34, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 3rem 0 3rem;

        .user-menu {
            background-color: unset;
        }
    }

    &__score-section-card {
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 1.5rem;
        width: 270px;
        min-width: 270px;
        max-width: 326px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__donut-chart {
        width: 220px;

        .c-donut-chart {
            width: 100%;
        }
    }

    &__report-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding: 1.5rem 2.5rem;
        text-align: center;
        background-color: rgba(18, 22, 34, 0.05);
        border-radius: 10px;
    }

    &__video-section {
        @apply bg-red-light;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
        padding: 2rem;
        border-radius: 10px;
    }

    &__wellness-section {
        @apply bg-blue-light;

        display: flex;
        flex-flow: wrap;
        justify-content: center;
        gap: 2.5rem;
        border-radius: 10px;
        padding: 2rem;
    }

    &__wellness-image-wrapper {
        max-width: 100%;
        width: 375px;
    }

    &__video-container {
        background-color: rgba(16, 21, 27, 1);
        padding: 0.25rem;
        border-radius: 10px;
        pointer-events: none;

        iframe {
            --video-width: 292px;
            width: var(--video-width);
            height: calc(var(--video-width)/2);
            max-width: 100%;

            &.embedded-video {
                margin: 0 auto;
            }
        }
    }

    &__video-wrapper {
        width: 1000px;
        max-width: 100%;
        height: 237px;

        iframe {
            max-width: 100%;
            width: 100%;
            height: 100%;
            max-height: 100%;

            &.embedded-video {
                margin: 0 auto;
            }
        }
    }

    &__header-companion {
        flex: 1;
    }

    &__video-companion {
        min-width: 50%;
        max-width: 50%;
    }

    &__video-container-modal {
        iframe {
            --video-width: 620px;
            width: var(--video-width);
            height: calc(var(--video-width)/2);
            max-width: 100%;

            &.embedded-video {
                margin: 0 auto;
            }
        }
    }

    &__prof-consultation-text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        flex: 1;
    }

    &__back {
        svg {
            @apply inline-block mr-2 align-middle;
            height: 20px;
            width: 20px;
        }
    }

    &__fms-clients {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 5%;
    }

    &__focus-area-vertical {
        @apply text-black-gray;

        font-weight: 300;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem;
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        max-width: 195px;
        min-width: 155px;
        width: 195px;
        gap: 0.5rem;

        div {
            max-width: 2.5rem;
            min-width: 2.5rem;
            padding: 0.5rem;
            border-radius: 9999px;
        }
    }

    &__step {
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 1.5rem;
        min-width: 268px;
        max-width: 326px;
        width: 268px;
    }

    &__card-number {
        @apply bg-blue-medium;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        width: 32px;
        height: 32px;
        text-align: center;
        border-radius: 40px;
    }

    &__badge {
        @apply bg-green-dark;

        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        border-radius: 0.25rem;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: normal;
        padding: 0.25rem 0.5rem;
        width: fit-content;
        min-width: 4.5rem;
        gap: 6px;
    }

    &__card-image-wrapper {
        min-width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__card-image {
        width: 414px;
        height: auto;

        &__380 {
            width: 380px;
            height: auto;
        }
    }

    &__card-image-text {
        display: flex;
        flex-direction: column;
        flex: 1;

        h2 {
            text-align: left;
        }
    }

    &__review {
        background-color: rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
        min-width: 268px;
        max-width: 326px;
        width: 268px;
        height: fit-content;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-assessment-results {
        &__user-menu-container {
            background-color: white;
            margin-bottom: 1.5rem;
            padding: 1rem 0 0 0;
        }

        &__prof-consultation-text {
            width: 100%;
            text-align: center;
            justify-content: center;
            flex: none;

            h3 {
                text-align: center;
            }
        }

        &__wellness-section {
            text-align: center;

            h3 {
                text-align: center;
            }
        }

        &__header-companion {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            h1 {
                text-align: center;
            }
        }

        &__video-companion {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            display: flex;
            justify-content: center;
        }

        &__video-wrapper {
            height: 186px;
        }

        &__review {
            min-width: 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-assessment-results {
        &__card-image-wrapper {
            width: 100%;
            max-width: 100%;
        }

        &__card-image-text {
            text-align: center;
            justify-content: center;

            h2 {
                text-align: center;
            }
        }

        &__review {
            height: auto;
        }
    }
}