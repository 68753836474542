.c-report-viewer {
    @apply flex flex-col;

    &__header {
        @apply mb-6 flex justify-between;
    }

    &__report {
        iframe {
            width: 100%;
            height: 100vh;
        }
    }

    &__export {
        @apply font-bold text-14;
        background-color: rgb(18 22 34);
        height: 40px;
        color: white;
        border-radius: 9999px;
        text-align: center;
        line-height: 40px;
        padding: 0px 20px;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-report-viewer {
        &__header {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            text-align: center;
            row-gap: 1rem;
        }

        &__actions {
            width: 100%;

            button {
                width: 100%;
            }
        }
    }
}