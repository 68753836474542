.c-account {
  margin-top: 1rem;

  &__container {
    @apply border-b border-solid border-black-gray-20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .c-account {
    text-align: center;
  }
}

@media only screen and (max-width: 991.98px) {
  .c-account {
    &__info {
      div {
        width: 100%;
      }
    }
  }
}