.question-screen-flex-row {
    flex-direction: row !important;
    padding-top: 2rem;
    // margin-left: 4rem;
    // margin-right: 4rem;
}

#BALANCE_REACH_2_LEFT {
    margin: auto !important;
}

.question-screen-flex-column {
    flex-direction: column !important;
    justify-content: flex-start !important;

    .c-question-screen__container {
        margin: 0 auto;
    }

}

.reach-left-question,
.reach-right-question {
    height: auto !important;
    display: inline !important;
    margin: auto;
}

// .reach-right-question {
//     height: 61.5% !important;
//     justify-content: flex-end !important;
//     display: inline !important;
//     margin: auto;

//     h4 {
//         margin-right: 18px;
//     }
// }

.c-question-screen {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 3rem;
    // height: 84.9%;

    ion-list-header {
        color: var(--ion-color-main);
        padding-left: calc(var(--ion-safe-area-left, 0) + 20px);
        padding-right: calc(var(--ion-safe-area-right, 0) + 20px);

        ion-label {
            & h4 {
                @apply font-bold text-12;
                margin-top: 31px;
                padding-bottom: 20px;
                text-transform: uppercase;
                margin-left: 5px;
            }
        }
    }

    &__container {
        max-width: 50%;
    }

    .reach-left-right-label {
        margin-bottom: 10px;
        margin-left: 20px;
    }

    .reach-left-question-container {
        max-width: 100% !important;
    }

    .reach-right-question-container {
        max-width: 100% !important;
    }

    &__header-container {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.5rem;
        gap: 1rem;
    }

    &__header {
        @apply text-20 font-light;
        line-height: 32px;
        padding-top: 10px;
        padding-bottom: 20px;
        text-align: center;
        margin-top: 1rem;

        &::first-letter {
            text-transform: uppercase;
        }

        &.-move {
            padding-top: 15px;
        }
    }

    &__section-title {
        @apply font-light text-16;
        padding-top: 30px;
        padding-bottom: 5px;
    }

    &__integer-options {
        width: 165px;
        height: 90px;
        top: 15px;
        margin: 10px auto;
        border-radius: 10px;
        --background: rgba(var(--ion-color-main-rgb), 0.05);

        ion-input {
            text-align: center;
            font-size: 50px;
            line-height: 22px;
        }

        &.item-has-focus {
            border: 2px solid black;
        }
    }

    &__integer-range-container {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center;
        margin-left: 10px;
        flex-wrap: wrap;
        gap: 13px;
    }

    &__integer-range-days-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 13px;
    }


    &__disclaimer-text-content {
        @apply text-14 font-light;
        text-align: center;
        line-height: 22px;
        padding-top: 1rem;
        width: 331px;
        padding-left: calc(var(--ion-safe-area-left, 0) + 16px);
        padding-right: calc(var(--ion-safe-area-right, 0) + 16px);
    }

    &__disclaimer-text-container {
        display: flex;
        justify-content: center;
    }

    &__field-label {
        @apply text-12 font-bold;
        margin-bottom: 0;
        margin-top: 0;
    }

    &__buttons {
        width: 100%;
        margin-top: auto;
    }
}

@media only screen and (max-width: 767.98px) {
    .question-screen-flex-row {
        padding-top: 0;
    }

    .c-question-screen {
        margin: 0;
        padding: 1rem;
        justify-content: center;

        &__custom-options {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
        }

        &__container {
            max-width: 100%;
        }

        &__header {
            padding-top: 0;
        }
    }
}