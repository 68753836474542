.c-settings {
    &__city {
        @apply flex;
    }

    &__user-name {
        @apply flex;
    }

    &__user-details {
        @apply flex;
    }

    position: relative;

    &__main {
        @apply flex bg-black-gray-5 px-20 py-10 mt-16 mb-16 relative;
        width: calc(100% + 10rem);
        margin-left: -5rem;
        flex-flow: wrap;
    }

    &__account-info {
        &__row-1 {
            display: flex;
            justify-content: space-between;
            width: 100%;
            row-gap: 1rem;
        }
    }

    &__field-container {
        @apply flex w-full mt-10;
        flex-flow: wrap;
    }

    &__fieldgroup {
        margin-bottom: 24px;
        width: 33%;

        label {
            @apply text-14 font-bold;
            font-style: normal;
            line-height: 24px;
        }

        p {
            @apply font-medium text-20;
            font-style: normal;
            line-height: 24px;
        }
    }

    &__contact-container {

        .c-button {
            @apply mt-10 mb-20;

            min-width: 10rem;
        }

        &::after {
            content: "";
            display: table;
            clear: both;
        }
    }

    &__button-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        width: 100%;
    }

    &__contact {
        @apply px-10 py-10 mt-10 border border-gray rounded-3xl;

        word-break: break-word;
        width: 100%;
        max-width: 100%;

        >div {
            @apply mt-6 mb-10 relative;
        }

        p {
            white-space: pre-line;
        }

        .c-button {
            @apply absolute;
            right: 0;
            top: 0;
        }
    }

    &__disclaimer {
        @apply mt-6 font-light;
        width: 100%;
        margin-bottom: 2.5rem;
    }

    &__actions {
        .c-button {
            min-width: 10rem;
        }

        &.left {
            text-align: left;
        }
    }

    &__edit {

        @apply mb-6;

        .c-settings__control {
            @apply rounded-3xl;
        }

        .c-settings__value-container {
            @apply py-2.5 px-5;
            line-height: 18px;
        }

        .c-settings__indicator-separator {
            @apply hidden;
        }

        &.-content {

            textarea {
                @apply w-full mt-10;
            }

            #appContent {
                min-height: 200px;
            }
        }

        &.-billing {

            #firstName {
                display: inline-block;
            }

            #lastName {
                margin-left: 1rem;
                display: inline-block;
            }

            #phone {
                margin-left: 1rem;
            }
        }

        input[type=text] {
            @apply border border-gray rounded-3xl text-base leading-6 py-2.5 px-5 mb-4;
        }

        &.-address {

            #line1,
            #line2 {
                width: 100%;
            }

            #city,
            #state,
            #postalCode {
                display: inline-block;
            }

            #state {
                margin-left: 1rem;
                width: calc(30% - 0.75rem);
            }

            #postalCode {
                margin-left: 1rem;
            }
        }

        label {
            @apply block text-gray text-sm font-bold mb-2;
        }
    }
}

.ql-container {
    font-family: inherit;
    font-size: inherit;
}

@media only screen and (max-width: 767.98px) {
    .c-settings {
        &__main {
            width: calc(100% + 7rem);
        }

        &__account-info {
            &__row-1 {
                display: flex;
                flex-flow: wrap-reverse;
                justify-content: space-between;
                width: 100%;
                margin-left: 3rem;
            }
        }

        &__fieldgroup {
            width: 100%;
        }

        &__contact-container {
            width: 100%;

            .c-button {
                width: 100%;
            }
        }

        &__actions {
            width: 100%;

            .c-button {
                width: 100%;
            }
        }

        &__edit {
            &.-address {

                #city,
                #state,
                #postalCode {
                    width: 100%;
                    margin-left: 0;
                }
            }

            &.-billing {
                #phone {
                    width: 100%;
                }
            }

            input[type=text] {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .c-settings {
        &__main {
            width: calc(100% + 7rem);
        }

        &__edit {
            &.-address {
                #city {
                    width: 40%;
                }

                #state {
                    width: 50%;
                }

                #postalCode {
                    margin-left: 0;
                    width: 30%;
                }
            }
        }
    }
}