.c-movement-screen {
    height: inherit;
    max-width: 100%;
    width: 100%;

    &.-collapse-scroll {
        .c-movement-screen__content.has-button {
            justify-content: flex-start;

            .c-screen-slide {
                // Removed for lifestyle assessment
                // height: auto;
            }

            .c-movement-screen__actions {
                // margin-top: 2rem;

                ion-button {
                    display: inline-block;
                    text-transform: none;
                    --padding-start: 3rem;
                    --padding-end: 3rem;
                }
            }
        }
    }

    &__wrapper {
        height: 100%;

        &:not(.-intro) {
            position: relative;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        height: 100%;

        &.has-button {
            .c-screen-slide {
                // Took out for lifestyle assessment
                // height: calc(100% - 66px - 1rem);
            }
        }
    }

    &__actions {
        //removed for submit button of assessment intro
        //margin: 0 var(--ion-margin, 2rem) 1rem;
        text-align: center;
        background: var(--ion-card-background);
        // padding-top: 6px;
        z-index: 1;

    }

    &__optional-text {
        @apply text-14;
        line-height: 32px;
        text-decoration-line: underline;
        --color: var(--ion-color-main);
        --padding-top: 0.5rem;
        --padding-bottom: 1rem;
        display: block;
        justify-content: center;
    }
}