.c-cancellation-dialog-box {
  h3 {
    line-height: 2.5rem;
  }

  p {
    @apply text-14 font-light;
  }

  strong {
    @apply font-medium;
  }

  .cancellation-details {
    overflow: hidden;
    max-height: 0;
    transition: max-height .3s ease-in-out;
  }


  .cancellation-details.visible {
    @apply border-l-8 border-solid border-l-black-gray-5;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2rem;
    margin: 1rem 0;
  }

  input[type="radio"] {
    @apply accent-black-gray;
    margin-right: 1rem;
    margin-left: 1rem;
    height: 22px;
    width: 22px;
    cursor: pointer;
    display: block;
  }

  .reason.visible {
    @apply bg-black-gray-5;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;
  }

  label {
    margin-bottom: 0px !important;
    font-weight: normal !important;
    cursor: pointer;
  }

  .reason {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .cancellation-button {
    margin-top: 1rem;

    &-white {
      button {
        @apply border-2 border-solid border-black-gray text-black-gray bg-white;
      }
    }
  }

  .contact-us {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 1.5rem;
  }

  &__hubspot-form {
    width: 66%;
  }

  &__reasons {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__description {
    margin: 2rem 0;
    font-size: 16px !important;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__checkbox-row {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  }

  &__checkbox-wrapper {
    input {
      height: 1.25rem !important;
      width: 1.25rem !important;

      &:checked {
        @apply accent-black-gray;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .c-cancellation-dialog-box {
    &__hubspot-form {
      width: 100%;
    }
  }
}