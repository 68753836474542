.p-deactivated-account {
    width: 100%;

    h1 {
        @apply font-light text-26;
        margin-bottom: 2rem;
        line-height: 50px;
        text-align: center;
    }

    p {
        @apply text-16 font-light;
        line-height: 24px;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 5rem 6rem;
    }

    &__actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 2.5rem;
        text-align: left;
    }

    &__clickable {
        @apply bg-black-gray-5;
        cursor: pointer;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        gap: 1.5rem;
        padding: 2.5rem;
        border-radius: 10px;

        &:hover {
            background-color: rgba(18, 22, 34, 0.15);
        }
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h4 {
            @apply text-20 font-light;
            line-height: 24px;
        }

        p {
            @apply text-14 font-light;
            line-height: 22px;
        }
    }
}