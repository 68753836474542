.c-notifications {
  margin-top: 1rem;

  .button-gap {
    gap: 50px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: #F3F3F4;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  &__notificationText {
    @apply text-14;
    width: 85%;
  }
}

@media only screen and (max-width: 767.98px) {
  .c-account {
    text-align: center;
  }
}

@media only screen and (max-width: 991.98px) {
  .c-account {
    &__info {
      div {
        width: 100%;
      }
    }
  }
}