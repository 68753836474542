.c-user-status-badge {

    &__status-badge {
        @apply text-12 font-bold uppercase;
        width: fit-content;
        padding: 6px 8px;
        border-radius: 4px;

        &.active {
            @apply bg-green-dark;
        }

        &.deactivated {
            @apply bg-red-dark text-white;
        }

        &.inactive {
            @apply bg-yellow-dark;
        }
    }
}