.c-organization-list {
    display: flex;
    flex-wrap: wrap;

    &__actions {
        @apply float-right;
    }

    &__edit {
        @apply mb-6;

        #orgStatus {
            @apply w-1/2;
        }

        #orgName {
            @apply border border-gray rounded-3xl text-base leading-6 py-2.5 px-5 w-9/12 mt-6;
        }

        .c-organization-list__control {
            @apply rounded-3xl;
        }

        .c-organization-list__indicator-separator {
            @apply hidden;
        }
    }

    &__button {
        @apply bg-blue-light hover:bg-blue-light text-white font-bold py-2 px-4 rounded;
    }

    &__header {
        @apply w-full mb-4;
    }

    &__list {
        @apply overflow-x-auto;

        &__table {
            @apply table-auto border-collapse w-full text-left;

            th:first-child,
            td:first-child {
                @apply pl-8;
            }

            // Set border-radius on the top-left and bottom-left of the first table data on the table row
            td:first-child,
            th:first-child {
                border-radius: 10px 0 0 10px;
            }

            // Set border-radius on the top-right and bottom-right of the last table data on the table row
            td:last-child,
            th:last-child {
                border-radius: 0 10px 10px 0;
            }

            th {
                padding: 0.5rem 0.5rem 0.5rem 1rem;
            }
        }
    }

    &__item {
        @apply w-full py-2 bg-black-gray-5 hover:bg-blue-light text-black-gray border-b-8 border-white;

        &:hover {
            .c-button {
                @apply opacity-100;
            }
        }

        .c-button {
            @apply opacity-0 mr-6;
        }

        &.-deactivated {
            @apply bg-gray-dark text-white;
        }

        td.users-cell {
            &.red {
                @apply text-red-dark;
            }
        }

        td.status-cell {
            div {
                @apply text-14 font-bold;
                text-transform: uppercase;
            }
        }

        &.-active {
            td.status-cell {
                div {
                    @apply bg-green-dark;
                }
            }
        }

        &.-expired {
            td.status-cell {
                div {
                    @apply bg-red-dark text-white;
                }
            }
        }

        &.-deactivated {
            td.status-cell {
                div {
                    @apply bg-white text-black;
                }
            }
        }

        &__content {
            @apply flex-1 min-w-0;

            p {
                @apply text-sm text-gray truncate text-left;
            }
        }

        td {
            padding: 1rem 0.5rem 1rem 1rem;

            &.status-cell {
                div {
                    text-align: center;
                    border-radius: 4px;
                    width: fit-content;
                    padding: 1px 12px;
                }
            }

            &:last-of-type {
                justify-content: right;
                display: flex;
                flex-wrap: wrap;
                row-gap: 0.5rem;
            }
        }

        svg {
            width: 26px;
        }
    }

    &__pagination {
        @apply text-center mt-6;

        ul>li {
            @apply inline-block py-2;

            &.ais-Pagination-item {
                &--disabled {
                    @apply text-gray;
                }

                &--firstPage {
                    @apply hidden;
                }
            }

            .ais-Pagination-link {
                @apply px-3 py-2 font-bold rounded;

                &--selected {
                    @apply bg-gray-dark hover:bg-gray-dark text-white;
                }
            }
        }
    }

    &__search {
        @apply rounded-3xl text-base leading-6 py-2.5 px-5 w-1/2 mt-10;

        button {
            @apply hidden;
        }

        form {
            margin-bottom: 0;
        }

        input {
            @apply w-full;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }

    .ais-SortBy {
        @apply hidden;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-organization-list {
        justify-content: center;

        &__header {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            text-align: center;
        }

        &__actions {
            width: 100%;

            button {
                width: 100%;
            }
        }

        &__search {
            width: 100%;
            padding: 0 0 1rem 0;
            margin-top: 1rem;
        }
    }
}