.c-assessment-intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 var(--ion-margin, 2rem);
    height: 100%;
    justify-content: space-around;
    row-gap: 2rem;

    &__content-container {
        width: 38%;
    }

    h1 {
        @apply font-light text-26;
    }

    img {
        height: 300px;
        width: 300px;
        margin-top: 30px;
    }

    &__content {
        @apply text-14 font-light;
        color: var(--ion-color-main);
        line-height: 24px;
        text-align: left;

        &__header {
            @apply text-12 font-bold;
            color: rgba(0, 0, 0, 0.5);
            line-height: 30px;
            letter-spacing: 1px;
            margin-bottom: 0;
            text-transform: uppercase;

            +p {
                margin-top: 0;
            }
        }

        &__section {
            margin-top: 30px;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-assessment-intro {
        &__content-container {
            width: auto;
        }

        &__content-container {
            display: flex;
            flex-flow: wrap;
            text-align: center;

            h1 {
                text-align: inherit;
            }

            .c-assessment-intro__content {
                text-align: inherit;
            }
        }

        &__image {
            img {
                max-width: 60%;
                height: auto;
                margin: 30px auto auto auto;
            }
        }
    }
}