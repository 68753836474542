.c-app-content-type-toggle {
    @apply bg-black-gray;
    width: 100%;
    display: flex;
    border-radius: 30px;

    &__app-content-pain,
    &__app-content-behavioral {
        @apply border border-solid border-black-gray-20 text-black-gray bg-white;
        border-radius: 30px;
        width: 50%;
        padding: 10px;

        &.active {
            @apply bg-black-gray text-white;
        }
    }
}