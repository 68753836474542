.p-assessments-home-screen-page {

    &__download-pdf-container {
        @apply bg-blue-light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 1rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        p {
            text-align: center;
        }

        button {
            margin: auto;
            margin-top: 1rem;
        }
    }

    &__header {
        @apply font-light text-26;
        text-align: center;
        margin-top: 4rem;
    }

    &__header-message {
        @apply text-14 font-light;
        text-align: center;
        margin-bottom: 4rem;
    }

    &__cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__card-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    &__footer {
        @apply text-14 font-light;
        font-style: italic;
        margin: 2.5rem 4rem 2.5rem 4rem;
    }

    &__back {
        @apply w-full my-16 mx-20;

    }

    &__steps-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0 5rem 4rem 5rem;
        row-gap: 2rem;

        h4 {
            @apply text-20 font-light;
            line-height: 32px;
            padding-bottom: 1rem;
        }
    }

    &__step {
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: center;
        column-gap: 6rem;
        border-radius: 20px;
        padding-right: 1.5rem;

        .step-text-container {
            display: flex;
            flex-flow: wrap;
            gap: 1.5rem;
            padding: 1rem 0;

            h4 {
                display: flex;
                gap: 1rem;
            }
        }

        p {
            @apply text-16 font-light;
            line-height: 26px;
        }
    }

    &__card-number {
        @apply font-medium text-26;
        width: 32px;
        height: 32px;
        text-align: center;
        border-radius: 40px;

        &.red {
            @apply bg-red-medium;
        }

        &.blue {
            @apply bg-blue-medium;
        }

        &.green {
            background-color: #97E2CE;
        }

        &.white {
            @apply bg-white;
        }

        &.show-on-mobile {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
        }
    }

    &__card-number-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__step-image {
        width: 294px;
        height: 294px;
        max-width: 294px;
    }

    &__report-example-image {
        max-height: 336px;
        object-fit: contain;
    }

    &__step-prof-consultation-wrapper {
        @apply bg-red-medium;
        display: flex;
        justify-content: center;
        border-radius: 20px;
    }

    &__step-prof-consultation {
        @apply bg-red-medium;
        max-width: 294px;
        min-width: 294px;
        min-height: 294px;
        max-height: 294px;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        text-align: center;
        align-items: center;
        align-content: center;
        gap: 1rem;
        border-radius: 20px;
        padding: 2rem;
    }

    &__modal-info-container {
        .app-content-container {
            border: none;
            border-radius: unset;
        }
    }

    hr {
        @apply text-black-gray-20;

    }

    .incomplete-footer {
        margin-top: 165px;
    }
}

.user-menu {
    @apply bg-black-gray-5;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    padding-right: 3rem;
}

@media only screen and (max-width: 767.98px) {
    .p-assessments-home-screen-page {
        width: 100vw;

        &__footer {
            text-align: center;
        }

        &__steps-container {
            margin: 0 0 4rem 0;
            row-gap: 3rem;

            h4 {
                padding-bottom: 0;
            }
        }

        &__step {
            flex-wrap: wrap;
            text-align: center;
            gap: 1rem;
            padding-bottom: 0.5rem;
            padding-right: 0;

            .step-text-container {
                justify-content: center;
                gap: 0.5rem;

                h4 {
                    justify-content: center;
                }
            }
        }

        &__step-prof-consultation {
            padding-top: 2.5rem;
        }

        &__back {
            margin-right: 1.5rem;
            margin-left: 1.5rem;
        }
    }

    .user-menu {
        @apply bg-white;
        padding-right: 0;
    }
}

@media only screen and (max-width: 479.98px) {
    .p-assessments-home-screen-page {
        &__step-prof-consultation-wrapper {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .p-assessments-home-screen-page {
        &__step {
            column-gap: 3rem;
        }
    }
}