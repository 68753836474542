h1 {
    @apply text-black text-3xl text-left;
    line-height: 1;
    margin-bottom: 1rem;
}

h3 {
    @apply font-light text-26;
    text-align: left;
    line-height: 2.5rem;
    margin-bottom: 0.75rem;
}