.c-portal-progress-bar {
    &__box {
        @apply flex flex-col;
    }

    &__header-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 1rem;
    }

    &__header,
    &__link {
        @apply text-13 font-medium;
        margin-bottom: 5px;
        text-align: left;
    }

    &__link {
        text-decoration-line: underline;
        cursor: pointer;
    }

    &__container {
        border-radius: 10px;
        width: 100%;
        background-color: #E8E8EA;
        height: 10px;
        position: relative;
    }

    &__progress {
        border-radius: 10px;
        height: 100%;
        position: absolute;
    }


}