form {
    label {
        @apply font-bold text-16;
        margin-bottom: 0.75rem;
        display: block;
        line-height: 1.5rem;
    }

    input {
        @apply text-16;
        display: block;
        border-radius: 1.5rem;
        border-width: 1px;
        border-color: rgba(18, 22, 34, 0.20);
        padding: 0.625rem 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 0.75rem;
    }

    p {
        &.read-only-select {
            @apply text-16;
            display: block;
            border-radius: 1.5rem;
            border-width: 1px;
            padding: 0.625rem 1.25rem;
            line-height: 1.5rem;
            margin-bottom: 0.75rem;
            width: 250px;
        }
    }

    .non-enterprise-field {
        @apply border-l-8 border-solid border-l-black-gray-5 text-14 font-light;
        padding: 0px 32px;

        label {
            @apply text-12;
            line-height: 22px;
            margin-bottom: 4px;
        }
    }
}