.p-create-account-confirmation {

    margin: 0 20px 0 20px;
    justify-content: space-evenly;

    img {
        @apply mx-auto;
        max-width: 358px;
        height: 126px;
        margin-top: 6rem;
        margin-bottom: 30px;
    }

    &__header {
        @apply font-light text-26;
        text-align: center;
        margin-bottom: 25px;
    }

    &__informative {
        @apply text-14 font-light;
        text-align: center;
    }

    &__button {
        display: flex;
        justify-content: center;
    }
}