.p-create-account-expiration {

    width: 500px;
    margin: 0 20px 0 20px;

    img {
        @apply mx-auto;
        max-width: 358px;
        height: 126px;
        margin-top: 6rem;
        margin-bottom: 30px;
    }

    &__header {
        @apply text-24 font-book;
        text-align: center;
        margin-bottom: 25px;
    }

    &__informative {
        @apply text-14 font-light;
        text-align: center;
    }
}