.c-custom-input-option {
    @apply bg-black-gray-5;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    position: relative;
    overflow: hidden;
    width: 380px;
    height: 85px;
    margin: auto;
    margin-bottom: 1.25rem;

    &__image {
        display: inline-block;
        height: 90px;
        width: 100px;
        flex: none;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        +.c-custom-input-option__content {
            margin: 0 25px;
        }
    }

    &__content {
        margin: 20px;
    }

    .ion-text-wrap {
        @apply text-16 font-light;
        line-height: 22px;
        pointer-events: auto;

        div {
            @apply text-12 font-light;
            line-height: 16px;
            color: rgba(var(--ion-color-main-rgb), 0.6);
        }
    }

    &.-selected,
    &:hover {
        @apply bg-black-gray text-white;

        .c-custom-input-option__image {
            &:after {
                content: "";
                height: 100%;
                width: 100px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(var(--ion-color-main-rgb), 0.5);
            }
        }

        ion-label {
            @apply text-white;
            color: var(--ion-color-main-contrast);

            div {
                @apply text-white;
                color: var(--ion-color-main-contrast);
            }
        }
    }
}

// .lifestyle-option {
//     height: 65px !important;
// }

@media only screen and (max-width: 767.98px) {
    .c-custom-input-option {
        width: 100%;
        height: auto;
        margin-bottom: 0;
    }
}