.c-organization-modal {
    @apply fixed z-10 inset-0 overflow-y-auto;

    $light-grey: rgba(18, 22, 34, 0.05);
    $dark-grey: rgba(208, 208, 211, 1);

    &__control {
        border-radius: 10px;
        border: 1px solid $light-grey;
    }

    &__field {
        display: inline-block;
        width: 100%;
        margin-bottom: 32px;
    }

    &__actions {
        @apply mt-6 text-right;

        &__send {
            @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-light text-base font-medium text-white hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light sm:col-start-2 sm:text-sm;
        }

        &__cancel {
            @apply mt-3 mr-6 inline-flex px-4 py-2 bg-white text-base font-medium text-black-gray underline;
        }

        &__delete {
            @apply font-bold mt-3 inline-flex pl-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
        }
    }

    &__content {
        @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
    }

    &__header {
        @apply inline-block w-full mb-10;

        &__close {
            @apply mx-auto h-12 w-12 items-center justify-center text-center float-right cursor-pointer;

            svg {
                @apply h-6 w-6 text-black-gray ml-auto;
            }
        }

        &__title {
            @apply mt-3 text-center sm:mt-5;

            h3 {
                @apply text-4xl font-light leading-6 text-left;
            }
        }
    }

    &__overlay {
        @apply fixed inset-0 bg-gray bg-opacity-75 transition-opacity;
    }

    &__wrapper {
        @apply inline-block align-bottom bg-white rounded-3xl p-14 text-left shadow-xl transform transition-all sm:align-middle sm:w-3/4;

        &.-delete {
            @apply sm:w-1/4;
        }

        label {
            @apply text-base font-bold block text-12 font-medium;
            height: 22px;
            margin-bottom: 4px;
        }

        hr {
            @apply border-gray my-10;
            width: calc(100% + 3rem);
            margin-left: -1.5rem;
        }

        &__select {
            border-radius: 10px;
        }
    }

    h4 {
        @apply text-black-gray font-normal text-lg mb-2;
    }

    h6 {
        @apply text-black-gray mt-10 mb-4 font-bold text-base;
    }
}