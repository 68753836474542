.c-progress-bar {
    width: 100%;
    max-width: 100%;
    height: 4px;
    background-color: var(--ion-color-main);
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    position: relative;
    padding-left: 3%;
    padding-right: 3%;

    &__step {
        flex: 1 0 auto;
        height: 4px;
        background: #FFFFFF40;
        border: 2px solid #FFFFFF40;


        &.-complete {
            @apply bg-blue-dark border-2 border-solid border-blue-dark;
        }

        &.-complete-lifestyle {
            background-color: #49e1c9;
        }
    }
}

.c-progress-bar__header {
    @apply bg-black-gray text-white;
    border-radius: 40px 40px 0 0;
    text-align: center;


    h4 {
        @apply text-16 font-book;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-progress-bar {
        width: 100%;

        &__header {
            border-radius: 0;
        }
    }
}