.c-user-profile-history {
    table {
        tr {

            th:first-child,
            td:first-child {
                padding-left: 1.5rem;
            }

            th:last-child,
            td:last-child {
                padding-right: 1.5rem;
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-user-profile-history {
        table {
            tr {

                th:first-child,
                td:first-child {
                    padding-left: 0.5rem;
                }

                th:last-child,
                td:last-child {
                    padding-right: 0.5rem;
                }
            }
        }
    }
}