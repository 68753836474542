.c-user-profile-wellness-program {
    .c-card {
        width: 100%;
    }

    &__section-header {
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 1rem 1.5rem;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        flex-flow: wrap;
        gap: 2rem;
        justify-content: space-between;
        align-items: center;
    }

    &__score-section-card {
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 1.5rem;
        width: fit-content;
        // min-width: 432px;
        max-width: fit-content;
        display: flex;
        flex-direction: column;

        &.article-exercise-section {
            min-width: 450px;
            max-width: 100%;
            width: 48%;
        }

        &.width-410 {
            min-width: 410px;
        }
    }

    &__donut-chart {
        width: 6rem;
        margin: 0.5rem 0;

        .c-donut-chart {
            width: 100%;
        }
    }

    &__wellness-program-icon {
        div {
            max-width: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            padding: 0.3rem;
            border-radius: 9999px;
            border: 2px solid #FFF;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__icon-calendar {
        max-width: 14.4px;
        max-height: 16px;
        min-height: 16px;
        min-width: 14.4px;
        height: 16px;
        width: 14.4px;

        svg {
            max-width: 100%;
            max-height: 100%;
            min-height: 100%;
            min-width: 100%;
            height: 100%;
            width: 100%;
        }
    }

    // --------- RESPONSIVENESS START ---------
    &__score-section-card,
    .c-gray-footer-card,
    .c-focus-areas-card {
        flex-grow: 1;
        max-width: 100%;
        width: auto;
    }

    .c-focus-areas-card__focus-area {
        max-width: 100% !important;
        width: 100%;
    }

    // --------- RESPONSIVENESS END ---------
}