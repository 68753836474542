.c-timer {
    text-align: center;

    &__meta {
        @apply text-black-gray text-12 font-bold;
        line-height: 22px;
        text-transform: uppercase;
        display: block;
    }

    &__time {
        font-size: 50px;
        text-align: center;
        width: 20%;
        padding: 10px 0;
        background: lightgray;
        margin: auto;
        border-radius: 10px;
    }


    ion-item {
        @apply border-2 border-solid border-transparent;
        border-radius: 10px;
        --background: rgba(var(--ion-color-main-rgb), 0.05);
        width: 230px;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    ion-button {
        display: inline-block;
        text-transform: none;
        --padding-start: 3rem;
        --padding-end: 3rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-timer {
        &__time {
            width: 100%;
        }
    }
}