.c-assessment-title {
    @apply bg-black-gray text-white;
    padding: 4px 1.25rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    flex: none;
    height: 48px;

    &__title {
        @apply text-12 font-bold;
        flex: 1 0 auto;
        padding-left: 1.3rem;
        padding-right: 1rem;
        text-transform: uppercase;
    }

    &__sub {
        @apply text-10 font-bold;
        background: #FFFFFF40;
        border-radius: 5px;
        padding: 0.25rem 0.875rem;
        margin-right: 1.1rem;
        flex: 0 0 none;
        text-transform: uppercase;
        letter-spacing: 0.08rem;
    }
}