.c-donut-chart {
    width: 300px;
    position: relative;
    text-align: center;

    &__info {
        @apply text-20;
        color: white;
        background: black;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        text-transform: lowercase;
        text-align: center;
        position: absolute;
        right: 0;
        bottom: 16px;
        padding-top: 2px;
    }

    // &__percent {
    //     fill: rgba(18, 22, 34, 0.85);
    // }

    &__overall {
        fill: none;
    }

    &__outline {
        &__inner {
            @apply fill-white;
            -webkit-filter: url(#inner-shadow);
            filter: url(#inner-shadow);
        }

        &__outer {
            @apply fill-black-gray-5;
            -webkit-filter: url(#outer-shadow);
            filter: url(#outer-shadow);
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        padding: 0 25px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;

        &__score {
            @apply font-bold text-56;
            line-height: 4rem;

            svg {
                width: 20px;
                height: auto;
                vertical-align: top;
                padding: 4px 0;
            }

            &.-negative {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        &__title {
            @apply text-10 font-bold;
            line-height: 14px;
            text-transform: uppercase;
        }

        &__average {
            @apply text-12 font-bold;
            line-height: 14px;
            opacity: 0.5;
            margin-top: 8px;
        }

        &__badge {
            @apply text-10 font-medium;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #E7E9EF;
            padding: 0.5rem 0.75rem;
            border-radius: 0.25rem;
            color: #1A1A1A;
            line-height: 14px;
            text-transform: uppercase;
            margin-top: 1rem;
        }

        &__sub-text {
            font-size: 8px;
            font-weight: 300;
            line-height: 10px;
            text-align: center;
            margin-top: 0.75rem;
            white-space: pre-line;
        }

        h6 {
            margin: 0;
        }
    }

    &__modal {
        &.c-modal {
            h4 {
                @apply text-16 font-medium;
                margin-bottom: 20px;
            }

            p {
                @apply text-14 font-light;
                margin-bottom: 30px;
            }

            ion-button {
                --padding-start: 30px;
                --padding-end: 30px;
                width: 80%;
            }
        }
    }

    &.-no-data {
        .c-donut-chart {
            &__inner {
                opacity: 0.2;
                height: calc(100% - 20px);
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-donut-chart {
        width: 256px;
    }
}