.c-subscription-link-checkout {
    h1 {
        font-size: 26px;
        font-weight: 300;
        line-height: 34px;
        text-align: center;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
    }

    &__bottom-text {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        text-align: center;
        width: 80%;
    }

    &__congrats-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
    }
}