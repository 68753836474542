.date-picker-container {
    .error-style {
        color: red;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__close-icon::after {
        background-color: lightblue;
        height: 24px;
        width: 24px;
        line-height: 1em;
        text-align: center;
        font-size: 100%;
        content: "x";
        display: block;
    }

    .c-button {
        border-color: lighten(rgb(18 22 34), 5%);
        border-width: 1px;
    }
}
