.c-gray-footer-card {
    border: 1px solid rgba(18, 22, 34, 0.05);
    border-radius: 10px;
    width: 263px;
    min-width: 263px;
    max-width: 263px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0 0 0;
}