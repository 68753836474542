.c-user-menu {
    @apply ml-4 flex items-center md:ml-6;

    &__button {
        @apply max-w-xs flex items-center text-sm rounded-full pr-4;

        svg {
            @apply h-8 w-8 text-black-gray;
        }

        img {
            @apply w-12 rounded-full;
        }
    }

    &__user {
        @apply flex flex-row text-sm font-bold;

        svg {
            @apply mx-2 h-5 w-5;
        }
    }

    &__language {
        @apply flex flex-row text-sm font-light capitalize;

        svg {
            @apply mx-2 h-5 w-5;
        }
    }

    &__menu {
        @apply origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black-gray ring-opacity-5 focus:outline-none;

        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        margin-right: 10rem;

        .show-border {
            @apply border-b-black-gray-5;

            border-bottom-width: 1px;

            &:last-of-type {
                border: none;
            }
        }
    }

    &__item {
        @apply block px-4 py-2 text-sm text-black-gray font-light;
        width: 100%;
        text-align: left;
        text-transform: capitalize;

        &:hover {
            @apply bg-blue-light;
        }
    }

    &__toggle_item {

        @apply flex flex-row py-2 text-sm text-black-gray;
        align-items: center;
        border-top: 1px solid #12162233;
        width: 100%;
        height: 72px;
        text-align: left;

        &:hover {
            @apply bg-blue-light;
        }
    }

    &__toggle {
        @apply flex;
        justify-content: right;

        &__background {
            @apply block rounded-full;
            background-color: #43B5F8;
            width: 54px;
            height: 24px;
        }

        &__dot {
            @apply dot absolute bg-white w-4 h-4 rounded-full transition;
            left: 0.25rem;
            top: 0.25rem;
        }

        &__dot__checked {
            @apply dot absolute bg-white w-4 h-4 rounded-full transition;
            left: 0.25rem;
            top: 0.25rem;
            transform: translateX(185%);
        }

        &__text {
            @apply ml-3 font-bold text-xs;
        }

        &__wrapper {
            width: 50px;

            &.-unchecked {
                .c-dashboard__toggle__background {
                    background-color: rgb(209, 213, 219);
                }
            }
        }

        label {
            @apply flex items-center cursor-pointer;
        }

        input:checked~.dot {
            left: 0.35rem;
            top: 0.2rem;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-user-menu {
        margin-left: 0;

        &.white-on-mobile {
            svg {
                @apply text-white;
            }
        }
    }
}