select {
    @apply text-16;
    width: 100%;
    padding: 0.625rem 1.25rem;
    line-height: 1.5rem;
    font-family: inherit;
    margin-bottom: .75rem;
    border: 1px solid rgba(18, 22, 34, 0.20);
    border-radius: 10px;

    --webkit-appearance: none;
    appearance: none;

    background-image: url(svg-encode($chevron-down-black));
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
}