.c-deep-links-list {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-deep-links-list {
        &__header {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            text-align: center;
            row-gap: 1rem;
        }

        &__actions {
            width: 100%;

            button {
                width: 100%;
            }
        }

        .table-row-container {
            height: auto;
            word-break: break-word;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            padding: 1rem;

            td {
                width: 70%;
                padding-left: 0 !important;
                padding-right: 0 !important;

                svg {
                    margin: 0;
                }
            }

            .c-tag {
                margin: 0;
            }

            .email {
                @apply text-12;
            }
        }
    }

    .qr-code-container {
        .qr-code {
            svg {
                width: 80%;
                height: 80%;
            }
        }
    }
}