.c-tag-list {
    &__actions {
        @apply float-right;
    }

    &__button {
        @apply bg-blue-light hover:bg-blue-light text-white font-bold py-2 px-4 rounded;
    }

    &__dialog {
        @apply fixed z-10 inset-0 overflow-y-auto;

        &__actions {
            @apply mt-6;

            &__send {
                @apply float-right inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-light text-base font-medium text-white hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light sm:col-start-2 sm:text-sm;
            }

            &__cancel {
                @apply mt-3 inline-flex pr-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
            }

            &__delete {
                @apply float-right font-bold mt-3 inline-flex pl-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
            }
        }

        &__content {
            @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
        }

        &__header {
            @apply inline-block w-full mb-2;

            &__close {
                @apply mx-auto h-12 w-12 items-center justify-center float-right;

                svg {
                    @apply h-6 w-6 text-gray;
                }
            }

            &__title {
                @apply mt-3 text-center sm:mt-5;

                h3 {
                    @apply text-xl font-bold leading-6 font-medium text-gray text-left;
                }
            }
        }

        &__overlay {
            @apply fixed inset-0 bg-gray bg-opacity-75 transition-opacity;
        }

        &__wrapper {
            @apply inline-block align-bottom bg-white rounded-lg px-6 py-8 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-1/2;

            &.-delete {
                @apply sm:w-1/4;
            }
        }
    }

    &__email-invites {
        @apply mt-6;

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }
    }

    &__header {
        @apply w-full mb-4;
    }

    &__list {
        @apply overflow-x-auto w-full;

        p {
            @apply my-6 w-3/4 font-light;
        }

        &__table {
            @apply table-auto border-collapse w-full;
        }

        &__border {
            @apply border-solid border-2 border-gray py-1;
            border-top: none;
            border-left: none;
            border-right: none;
        }

        &__item {
            @apply flex p-3 hover:bg-blue-light;
            border-radius: 0.7rem;

            &:hover {

                .c-button {
                    @apply opacity-100;
                }
            }

            .c-button {
                @apply opacity-0 mr-6;
            }

            &__action {
                &__icon {
                    @apply inline-block align-top cursor-pointer p-2;

                    svg {
                        @apply text-gray;
                        width: 26px;
                    }
                }
            }

            &__actions {
                display: flex;
                flex-direction: row;
            }

            &__data-container {
                display: flex;
                flex-flow: wrap;
                flex: 1 1;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 5rem;
            }

            &__title {
                @apply flex-1 font-medium;
            }
        }
    }

    &__search {
        @apply border border-gray rounded-3xl text-base leading-6 py-3 px-5 mb-4 pb-0;

        button {
            @apply hidden;
        }

        input {
            @apply w-full border-none py-0 px-0;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }

    &__user-emails {
        @apply mt-6;

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }
    }

    &__user-list {
        @apply mt-6;

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }

        ul {
            @apply border border-gray rounded overflow-auto;
            max-height: 28vh;
        }

        &__action {
            @apply text-center text-sm text-gray;

            button {
                @apply underline;

                &:disabled {
                    @apply text-gray;
                }
            }
        }

        &__header {
            @apply flex;
        }

        &__item {
            @apply px-4 py-2 cursor-pointer;

            &:hover {
                @apply bg-gray;
            }

            >div {
                display: inline-block;
            }

            &.-unregistered {
                @apply italic text-gray;
            }

            &__email {
                width: 30%;
            }

            &__name {
                width: 65%;

                .c-tag {
                    @apply ml-2;
                }
            }
        }
    }

    h1 {
        @apply text-gray text-4xl font-bold;
    }

    .ais-SortBy {
        @apply hidden;
    }
}

hr+.c-tag-list {
    @apply mt-6;
}

@media (hover: none) {
    .c-tag-list {
        &__list {
            &__item {
                &__actions {
                    .c-button {
                        @apply border border-solid border-black-gray-5;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-tag-list {
        &__actions {
            width: 100%;
            margin-bottom: 1rem;

            button {
                width: 100%;
            }
        }

        &__list {
            &__item {
                flex-direction: column;
                flex-wrap: wrap;
                height: 150px;

                &__data-container {
                    height: 100%;
                    width: 80%;
                    align-content: center;
                    word-break: break-word;
                    padding-left: 0.5rem;
                    margin-right: 0;
                    flex: none;
                }

                &__title {
                    flex: none;
                    width: 100%;
                }

                &__data {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }

                &__actions {
                    display: flex;
                    flex-flow: wrap;
                    align-content: center;
                    justify-content: right;
                    row-gap: 1rem;
                    max-width: 30%;
                    height: 100%;
                }
            }
        }
    }
}