.c-rich-text-editor {
    label {
        @apply text-black-gray text-12 font-bold;
        line-height: 22px;
    }

    &__header-text,
    &__video-url,
    &__button-text,
    &__button-link {
        input {
            display: flex;
            height: 50px;
            padding: 10px 20px;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            border-radius: 25px;
            border: 1px solid rgba(18, 22, 34, 0.20);
            width: 100%;
        }
    }

    &__fields {
        flex-flow: wrap;
        margin-top: 1.5rem;
    }

    &__header-text {
        width: 100%;
    }

    &__video-fields-container,
    &__button-inputs-container {
        width: 100%;
        display: flex;
        gap: 0.75rem;
    }

    &__select-video-source,
    &__button-text {
        width: 30%;
    }

    &__video-url,
    &__button-link {
        flex-grow: 1;
    }

    &__main-text-container {
        max-width: 100%;
    }

    &__display-contact-container {
        margin: 1.5rem 0;
    }

    &__main-body-description {
        @apply text-black-gray bg-black-gray-5 text-14 font-book;
        display: flex;
        width: 100%;
        padding: 24px 40px;
        line-height: 22px;
        border-radius: 20px 20px 0px 0px;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-rich-text-editor {

        &__video-fields-container,
        &__button-inputs-container {
            flex-flow: wrap;
        }

        &__select-video-source,
        &__button-text,
        &__video-url,
        &__button-link {
            width: 100%;
        }

        &__actions {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }
}