.c-inline-radio-input-option {
    position: relative;
    margin-left: 3px;

    input[type="radio"] {
        display: none;
    }

    .__radio-none {
        display: inline-block;
        width: 80px;
        padding: 10px;
        border: solid 2px #f3f3f4;
        transition: all 0.3s;
        text-align: center;
        border-radius: 35px;
        background-color: #f3f3f4;
    }

    .__radio {
        display: inline-block;
        width: 50px;
        padding: 10px;
        border: solid 2px #f3f3f4;
        transition: all 0.3s;
        text-align: center;
        border-radius: 50%;
        background-color: #f3f3f4;
    }

    input[type="radio"]:checked+label {
        @apply border-2 border-solid border-black-gray bg-black-gray text-white;
    }


}