.carousel .control-dots .dot {
    @apply bg-black-gray-20;
    width: 12px;
    height: 12px;
    opacity: 1;
    box-shadow: none;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    @apply bg-black-gray;
    opacity: 1;
    box-shadow: none;
}

.carousel.carousel-slider .control-arrow {
    background: rgba(0, 0, 0, 0.15);
}