.c-organization-edit {
    &__edit {
        @apply mb-6 grid grid-cols-2 gap-2;

        &.-full {
            @apply grid-cols-1;
        }

        &__field,
        input {
            width: 100%;
        }
    }

    &__no-lower-margin {
        margin-bottom: 0;
    }

    &__note {
        padding-left: 1.5em;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.25em;
        font-size: 0.75em;
    }

    &__form-error {
        padding-left: 1.5em;
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
        font-size: 0.75em;
    }

    &__field-error {
        border-color: rgb(255, 0, 0, 0.6);
        outline-color: rgb(255, 0, 0, 0.6);
    }

    &__date-picker {
        height: 48px;
        // background: rgb(18, 22, 34);
        // color: white;
        border-radius: 9999px;
        border-style: solid;
        border-width: 1px;
        padding: 0px 50px;
        font-family: 'pragmatica-extended', 'sans-serif';
    }

    &__actions {
        @apply mt-6 text-right;
        margin-top: 2rem;
        display: flex;
        justify-content: right;

        &__cancel {
            @apply mr-6 inline-flex px-4 py-2 bg-white text-base font-medium text-black-gray underline;
        }
    }

    // label {
    //     margin-top: 1em;
    // }
}