.c-login-modal {
    @apply relative z-50;

    &__backdrop {
        @apply fixed inset-0 bg-black/30;
        aria-hidden: true;
    }

    &__overlay {
        @apply fixed inset-0 flex items-center justify-center p-4;
    }

    &__panel {
        @apply bg-white;
        width: 980px;
        height: 146px;
        border-radius: 30px;
    }

    &__header {
        background: black;
        border-radius: 30px 30px 0 0;
        height: 146px;
        display: flex;
        justify-content: center;
    }

    &__header-image {
        max-height: 85px;
        margin-top: 28px;
    }

    &__button-area {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 42px;

        &.-no-lower-message {
            margin-bottom: 65px;
        }

        &.-lower-message {
            margin-bottom: 60px;
        }
    }

    &__button {
        @apply bg-black-gray text-white;
        height: 50px;
        text-align: center;
        border-radius: 30px;
        width: 230px;

        &.-second-button {
            background-color: hsla(225, 31%, 10%, 0.051);
            color: black;
            border: 1px solid black;
            display: inline-grid;
            align-items: center;
        }

        &.-cancel-button {
            background: white;
            color: black;
            text-decoration: underline;
            border: 0px;
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            margin-left: 80px;
            margin-right: 80px;
        }
    }

    &__or {
        display: flex;
        vertical-align: middle;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__extra-message {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        a {
            @apply text-blue-dark;
            text-decoration: underline;
        }
    }

}