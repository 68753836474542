.action-button {
    @apply bg-black-gray text-white rounded-full;
    justify-content: center;
    // height: 50px;
    padding: 0 35px 0 35px;
}

@media only screen and (max-width: 767.98px) {
    .__skip-button {
        padding-right: 0 !important;
    }
}