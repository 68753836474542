.checkbox-type-container {
    display: flex;
    flex-direction: row;

    // .error-style {
    //     padding-left: 1.5em;
    //     color: red;
    //     opacity: 0.6;
    //     display: block;
    //     margin-bottom: 0.5em;
    //     font-size: 0.8em;
    // }

    // .field-error {
    //     border-color: rgb(255, 0, 0, 0.6);
    //     outline-color: rgb(255, 0, 0, 0.6);
    // }

    .label {
        padding-top: 1px;
        margin-left: 8px;
    }

    input {
        height: 1.375rem;
        width: 1.375rem;
        margin: 0 0 3px 0;

        // &:read-only {
        //     border: none;
        //     outline: none;
        // }
    }
}