.c-application-header {
    @apply relative flex-shrink-0 flex bg-blue-light;

    z-index: 19;

    &__back {
        @apply mr-6 pr-2 py-2 my-auto;
    }

    &__container {
        @apply flex-1 px-20 py-5 flex justify-between;
    }

    &__organization {
        @apply flex-1 flex;

        &__name {
            @apply flex items-center;

            &__intro {
                @apply uppercase text-10;
                line-height: 1.5rem;
            }

            .c-button {
                @apply font-medium text-12;
                // background-color: white;
                // color: rgba(18, 22, 34, 1);
                margin-left: 4.188rem;
                min-height: 2.125rem;
            }
        }

        &__switch {}

        h1 {
            @apply relative font-bold flex flex-col text-20;
            line-height: 1.5rem;
        }
    }

    &__organization-list {
        @apply mt-6;

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }

        ul {
            @apply border border-gray rounded overflow-auto;
            max-height: 28vh;
        }

        &__action {
            @apply text-center text-sm text-gray;

            button {
                @apply underline;

                &:disabled {
                    @apply text-gray;
                }
            }
        }

        &__item {
            @apply px-4 py-2 cursor-pointer;

            &.-selected {
                @apply bg-gray;
            }

            &:hover {
                @apply bg-blue-light;
            }

            >div {
                display: inline-block;
            }

            &.-deactivated {
                @apply italic text-gray;
            }

            &__status {
                width: 30%;
            }

            &__name {
                width: 65%;
            }

            &__40-col {
                width: 38%;
                overflow: hidden;
                margin: 0 5px;
            }

            &__30-col {
                width: 28%;
                overflow: hidden;
                margin: 0 5px;
            }
        }
    }

    &__search {
        @apply inline-block border border-gray relative mb-2 pr-4 rounded;

        input {
            @apply bg-transparent leading-snug appearance-none text-sm text-gray py-1 px-4 rounded-sm;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-application-header {
        @apply bg-black-gray text-white sticky top-0;

        &__container {
            @apply py-4 pr-0;

            flex: none;
            // justify-content: unset;
            width: 100%;
            padding-left: 5.25rem;
            min-height: 6rem;
        }

        &__organization {
            flex: none;
            width: 80%;
            justify-content: center;

            h1 {
                @apply text-16;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @apply text-white;
        }
    }
}