.c-popup-screen {
    &__card {
        background: var(--ion-color-main);
        color: var(--ion-color-main-contrast);
        border-radius: 20px;
        top: 25%;
        transform: translateY(-15%);
        z-index: 99;
        text-align: center;
        padding: 0;
        padding-bottom: 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        margin: 0 auto;
        width: 335px;

        & ion-card-subtitle {
            color: rgba(255, 255, 255, 0.8);
            text-transform: uppercase;
            padding-top: 30px;
        }

        & ion-image {
            width: 100%;
            z-index: auto;
        }

        & ion-card-content {
            @apply text-12 font-light;
            line-height: 20px;
            padding: 10px 20px 20px;
        }

        & ion-button {
            margin: 5px auto 10px;
            --border-radius: 30px;
            text-transform: none;
            height: 50px;
            width: 200px;
        }
    }

    &__container {
        @apply bg-black-gray;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 34.185%;
        border-radius: 20px;
    }

    &__overlay {
        background-color: rgba(var(--ion-color-main-contrast-rgb), 0.8);
        position: absolute;
        left: 0;
        right: 0;
        top: 48px;
        bottom: -60px;
        z-index: 5;
    }
}