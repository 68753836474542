.p-link-account-creation {
    text-align: center;

    h4 {
        @apply text-13 font-bold;
        font-style: normal;
        line-height: 40px;
    }

    p {
        @apply text-14 font-light;
        font-style: normal;
        line-height: 22px;
        margin-bottom: 2rem;
        margin-top: 1.5rem;
    }

    &__field {
        .c-input__input {
            margin: auto;
            width: 90%;
            margin-bottom: 1.25rem;
        }
    }

    &.-error {
        padding-left: 1.5em;
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

    &.-showerror {
        @apply border border-solid border-red-dark;
    }
}