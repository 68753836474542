.c-assessment-bar-chart {
    position: relative;
    width: 100%;

    &__bar {
        height: 20px;
        width: 100%;
        background: white;
        border: 2px solid rgba(var(--ion-color-main-rgb), 0.2);
        border-radius: 10px;
        padding-top: 2px;
        padding-left: 2px;
    }

    &__bar-fill {
        height: 16px;
        background: black;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &__marker {
        height: 30px;
        width: 4px;
        background: black;
        position: absolute;
        top: -5px;
        border-radius: 4px;

        &.-average {
            @apply bg-gray-light;
            width: 2px;
            height: 20px;
            top: 0;
        }

        &.-old {
            width: 2px;
            background: black;
        }
    }
}