.c-subscription-dialog-box {
  &__container {
    position: relative;
    width: 100%;
    height: 60% !important;
    overflow: hidden;
  }

  &__header {
    display: flex;
    justify-content: center;
  }

  &__container p,
  &__container ul {
    @apply text-14 font-light;
    margin-bottom: 1rem;
  }

  &__container p {
    text-align: center;
  }

  &__container ul {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &__photo {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 991.98px) {
  .c-subscription-dialog-box {

    &__header {
      font-size: x-large !important;
    }

    &__container p {
      padding: 0;
    }

    &__container ul {
      padding: 1rem;
      margin: 0;
    }
  }
}