.number-type-container {

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .error-style {
        padding-left: 1.5em;
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

    .field-error {
        border-color: rgb(255, 0, 0, 0.6);
        outline-color: rgb(255, 0, 0, 0.6);
    }

    label {
        margin-bottom: 0.25rem;
    }

    input {
        width: 100%;

        &:read-only {
            border: none;
            outline: none;
        }
    }
}