.p-group-edit {

  &__email-errors {
    @apply mt-3 bg-red-light;

    border-radius: 10px;
    padding: 13px;
    padding-left: 26px;
    padding-right: 26px;
    width: fit-content;
    max-width: inherit;

    &__item {
      @apply text-red-dark;
    }

    margin-bottom: 12px;
  }

  &__disclaimer {
    @apply mt-6 font-light;
    width: 100%;
    margin-bottom: 2.5rem;
  }


  &__fieldgroup {
    margin-bottom: 40px;

    label {
      @apply font-medium text-16;
      font-style: normal;
      line-height: 24px;
    }

    .c-user-list__control {
      @apply rounded-3xl;
      border-color: black;
      padding: .5rem;

      &:hover {
        border-color: black;
      }
    }
  }

  &__fieldgroup-error {

    label {
      @apply font-medium text-20;
      font-style: normal;
      line-height: 24px;
    }

    input {
      border-color: #E7514F !important;

      &:hover {
        border-color: #E7514F !important;
      }
    }

    .c-email-list__control {
      border-color: #E7514F !important;

      &:hover {
        border-color: #E7514F !important;
      }
    }
  }

  .c-email-list__value-container {
    @apply rounded-3xl;
    border-color: #E7514F !important;
    padding: .4rem 1.25rem !important;
    line-height: 1.5rem !important;

    &:hover {
      border-color: #E7514F !important;
    }
  }

  &__table-container {
    overflow: auto;
  }

  &__actions {
    display: flex;
    margin-top: 1.5rem;
  }
}