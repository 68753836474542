.p-create-account {
    width: 358px;
    /*margin: 0 20px 0 20px;*/
    top: 500px;

    &.-showerror {
        @apply border border-solid border-red-dark;
    }

    &.-error {
        padding-left: 1.5em;
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

    h4 {
        @apply text-13 font-bold;
        line-height: 40px;
        display: flex;
        justify-content: center;
        margin-top: 7%;
        margin-bottom: 20px;
    }

    img {
        @apply mx-auto;
        max-width: 358px;
        height: 126px;
        margin-top: 6rem;
        margin-bottom: 39px;
    }

    &__checkbox {
        height: 1.875rem;
        width: 1.875rem;
    }

    &__sign-up-checkbox {
        height: 1.5rem;
        width: 1.5rem;
        margin: 0 0 3px 0;
    }

    &__field {
        .c-input__input {
            width: 100%;
            margin-bottom: 1.25rem;
        }
    }

    &__terms {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        align-items: center;
        justify-content: left;
        margin-top: 1.25rem;
        // margin-bottom: 4rem;
        margin-bottom: 10px;
        width: 100%;

        div {
            @apply text-14;
            width: 100%;

            .button {
                @apply text-14;
                padding: 10px;
                padding-left: 0px;
                background-color: white;
                color: black;
                text-decoration: underline;

                --background: white;
                --box-shadow: none;
                --padding-start: 0;
                --padding-end: 0;
                --border-radius: 0;
                height: auto;
                margin: 0;
                vertical-align: middle;
                text-transform: none;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
    }
}

.login-button {
    @apply bg-black-gray text-white rounded-full;
    justify-content: center;
    height: 50px;
    width: 187px;
    margin-top: 32px;
}

.login-form {
    max-width: 358px;
}