.c-input-option {
    position: relative;

    input[type="radio"] {
        display: none;
    }

    .__radio {
        display: inline-block;
        width: 50px;
        color: #ccc;
        transition: all 0.3s;
        text-align: center;
        border-radius: 10px;
    }

    input[type="radio"]:checked+label {
        @apply text-black-gray;
    }

    &__input {
        @apply bg-black-gray-5;
        border: 2px solid black;
        border-radius: 10px;
        width: 100%;
        text-align: center;
    }

    // ion-button {
    //     text-transform: none;
    // }

    &.-height {
        padding-left: calc(var(--ion-safe-area-left, 0) + 16px);
        padding-right: calc(var(--ion-safe-area-right, 0) + 16px);
    }

    &.-weight {
        .c-input-option__col {
            width: 270px;
            flex-direction: row !important;

            ion-item {
                margin-left: 50px;
            }
        }
    }

    &.-inches {
        .c-input-option__col {
            width: 210px;

            ion-item {
                margin-left: 40px;
            }

            span {
                flex: none;
                width: 50px;
            }
        }
    }

    ion-item {
        border: 2px solid black;
        border-radius: 10px;
        --background: rgba(var(--ion-color-main-rgb), 0.05);
    }

    ion-item.item-has-focus {
        border: 2px solid black;
    }

    ion-input {
        font-size: 50px;
        line-height: 22px;
        text-align: center;
    }

    ion-label {
        @apply text-16;
        margin-top: -20px;
        margin-right: -40px;
        float: right;
        overflow-x: visible;
    }

    &__pop-button {
        position: absolute;
        top: 68px;
        right: 20px;
        height: 30px;
        --padding-start: 12px;
        --padding-end: 12px;
    }

    &__container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__height-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &__time {
        margin: 0 2px;
        font-size: 50px;
    }

    &__col {
        display: flex;
        flex-direction: column;
    }

    &__weight-col {
        margin-left: 3rem;
    }

    &__height-col {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__decimal-col {
        margin: auto;
        width: 20%;
    }

    &__weight-input {
        font-size: 50px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    &__decimal-input {
        font-size: 50px;
        display: flex;
        // padding-left: 2.5rem;
        flex-direction: row;
        justify-content: center;
    }

    &__sleep-wake-container {
        display: flex;
        margin: auto;
        width: 35%;
    }

    &__hour-input {
        font-size: 50px;
    }

    &__minute-input {
        font-size: 50px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    &__inches-input {
        width: 18%;
        font-size: 50px;
        margin-left: 3.5rem;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    &__height-input-container {
        @apply text-16;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    }

    &__inches-input-container {
        @apply text-16;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1rem;
    }

    &__height-input {
        width: 13%;
        font-size: 50px;
        text-align: right;
        margin-left: 3.4rem;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    &__span {
        margin-left: 0.5rem;
        width: 50px;
        padding-top: 3.7rem;
    }

    &__height-span {
        margin-left: 0.5rem;
        width: 20px;
        padding-top: 3.7rem;
    }


    &__decimal-span {
        @apply text-16;
        padding-top: 3.7rem;
        margin-left: 0.5rem;
    }

    &__minute-span {
        @apply text-16;
        padding-top: 3.7rem;
        margin-left: 0.5rem;
    }

    &__unit-types {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        justify-content: center;
    }

    &__time-types {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        justify-content: center;
    }

    // &__col {
    //     width: 10rem;
    //     display: flex;

    //     &.-condensed {
    //         width: 29%;

    //         ion-item {
    //             --padding-start: 0;
    //             --padding-end: 0;
    //             width: 100%;
    //         }
    //     }

    //     ion-item {
    //         width: 80%;
    //     }

    //     span {
    //         align-self: flex-end;
    //         padding: 0 10px;
    //         text-transform: uppercase;
    //     }
    // }

    &__time-container {
        height: 89px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        ion-button {
            width: 50px;
            color: rgba(var(--ion-color-main-rgb), 0.2);
        }

        .-active {
            @apply text-black-gray;
        }
    }

    &__weight-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: wrap;

        ion-button {
            height: 2.25rem;
            width: 50px;
            color: rgba(var(--ion-color-main-rgb), 0.2);
        }

        .-active {
            @apply text-black-gray;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-input-option {

        &__height-input,
        &__inches-input {
            width: 38%;
        }

        &.-weight,
        &.-inches {
            .c-input-option__col {
                width: 60%;
            }
        }

        &__sleep-wake-container {
            width: 80%;
        }

        &__decimal-col {
            width: 40%;
        }
    }
}