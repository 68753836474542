.c-faq-accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__faq {
        padding: 1rem 1.5rem;
        background: #fff;
        border-radius: 10px;
        border: 1px solid rgba(18, 22, 34, 0.05);

        &.open {
            .faq-question {
                margin-bottom: 1rem;
            }

            .faq-question::after {
                content: url("../../icons/outline/icon_arrow-up.svg");
            }

            .faq-answer {
                max-height: 100%;
                opacity: 1;
                margin-bottom: 1rem;
            }
        }

        .faq-question {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            padding-right: 80px;
            transition: all 0.4s ease;
            line-height: 22px;

            &::after {
                content: url("../../icons/outline/icon_arrow-down.svg");
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
                transition: all 0.2s ease;
            }
        }

        .faq-answer {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.2s ease;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
        }
    }
}