.c-user-profile-header {
    @apply mt-6;
    position: relative;
    padding-bottom: 24px;

    &::after {
        @apply bg-black-gray-5;

        content: "";
        position: absolute;
        left: -5rem;
        top: -3rem;
        right: 0;
        width: calc(100% + 10rem);
        height: calc(100% + 3rem);
        z-index: -1;
    }

    &__col-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        text-align: left;
        gap: 1rem;
        width: 100%;
    }

    &__fieldgroup {

        @screen md {
            margin-bottom: 24px;
        }

        label {
            @apply font-medium text-xs;
            font-style: normal;
        }

        p {
            @apply text-xs font-light;
            font-style: normal;
            justify-content: left;
        }
    }

    &__fieldgroup-error {
        margin-bottom: 24px;

        label {
            @apply font-medium text-20;
            font-style: normal;
            line-height: 24px;
        }

        &__control {
            border-color: #E7514F !important;

            &:hover {
                border-color: #E7514F !important;
            }
        }
    }

    &__license-error {
        @apply mt-3 text-red-dark bg-red-light;

        border-radius: 10px;
        padding: 13px;
        padding-left: 26px;
        padding-right: 26px;
        width: fit-content;

    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        h1 span {
            @apply font-bold;
        }

    }

    &__edit {
        @apply mb-6 grid grid-cols-2 gap-6;

        &.-full {
            @apply grid-cols-1;
        }

        label {
            @apply text-black-gray text-16 font-medium;
            line-height: 22px;
        }

        .c-user-profile-header__control {
            @apply rounded-3xl;
        }

        .c-user-profile-header__value-container {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }

        .c-user-profile-header__indicator-separator {
            @apply hidden;
        }

        .c-user-profile-header__input-container {
            @apply m-0;
        }

        input[type="text"] {
            @apply border border-gray rounded-3xl text-base leading-6 py-2.5 px-5 w-1/2;
            width: 100%;
        }
    }

    &__photo {
        max-width: 150px;
    }

    img {
        border-radius: 50%;
        margin-bottom: 24px;
    }

    &__note {
        opacity: 0.6;
        display: block;
        margin-bottom: 0.25em;
        font-size: 0.75em;
    }

    &__form-error {
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
        font-size: 0.75em;
    }

    &__error-message {
        @apply bg-red-dark text-white;
        padding: 6px 8px;
        border-radius: 4px;
        margin: 1rem 0;
    }

    &__radio-user-status {
        display: flex !important;
        gap: 10px;
        border-radius: 10px;
        padding: 12px 20px;

        &.selected {
            @apply bg-black-gray-5;
        }

        input {
            @apply accent-black-gray;
            width: 22px;
            height: 22px;
        }
    }

    &__status-badge {
        @apply text-12 font-bold;
        text-transform: uppercase;
        padding: 6px 8px;
        border-radius: 4px;

        &.active {
            @apply bg-green-dark;
        }

        &.deactivated {
            @apply bg-red-dark text-white;
        }

        &.inactive {
            background-color: #FFF3E4;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-user-profile-header {
        &__col-container {
            display: flex;
            flex-flow: wrap;
            gap: 1.5rem;
            justify-content: center;
            text-align: center;
            width: 100%;
        }

        &__fieldgroup {
            p {
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-user-profile-header {
        &::after {
            width: calc(100% + 2rem);
            left: -1rem;
        }
    }
}