@import "~video-react/styles/scss/video-react";
@import "react-quill";
@import "react-quill/dist/quill.snow.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "react-responsive-carousel-custom";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-datepicker";