.c-risk-chart {
    width: 100%;
    display: flex;
    background-color: white;
    border: 6px solid white;
    border-radius: 10px;
    position: relative;

    &__user {
        @apply text-12 font-bold;
        position: absolute;
        bottom: 100%;
        line-height: 8px;

        svg {
            margin-left: auto;
            margin-right: auto;
            width: 20px;
        }
    }

    &__segment {
        @apply text-12 font-bold;
        width: 25%;
        background: red;
        border-right: 3px solid white;
        border-left: 3px solid white;
        border-radius: 10px;
        text-align: center;
        padding: 10px 0;
        text-transform: uppercase;

        &.-high {
            @apply bg-red-light;
        }

        &.-moderate {
            background: #FFF3E4;
        }

        &.-slight {
            @apply bg-blue-light;
        }

        &.-low {
            @apply bg-green-light;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-risk-chart {
        &__segment {
            @apply text-10 font-bold;
        }
    }
}