.c-exercise-modal {
    padding: 1rem 2.5rem 2.5rem 2.5rem;

    hr {
        @apply border-black-gray-5;
        margin: 1.5rem 0;
        height: 1px;
    }

    &__focus-icon {
        div {
            max-width: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            padding: 0.3rem;
            border-radius: 9999px;
            border: 2px solid #FFF;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}