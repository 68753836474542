.c-risk-chart-vertical {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 6px solid white;
    border-radius: 10px;
    position: relative;

    &__bar {
        @apply bg-black-gray;

        position: absolute;
        bottom: 0;
        width: 30%;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;

        &:after {
            content: "";
            display: block;
            border-bottom: 2px solid #121622;
            height: 0px;
            width: 175%;
            left: -37.5%;
            position: relative;
        }
    }

    &__segment {
        width: 100%;
        padding: 10px 0;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        border-top: 3px solid white;
        white-space: pre-line;

        &:first-of-type {
            border-radius: 10px 10px 0 0;
            border-top-width: 0;
        }

        &:last-of-type {
            border-radius: 0 0 10px 10px;
        }

        .risk-chart-sub-label {
            @apply text-black-gray-50;
        }
    }

    &__highlighted {
        @apply bg-black-gray;

        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        // align-items: center;
        // width: 100%;

        .risk-chart-label {
            @apply text-white;
        }

        .risk-chart-sub-label {
            @apply text-white-50;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-risk-chart {
        &__segment {
            font-size: 9px;
        }
    }
}