.c-sidebar {
    @apply h-full overflow-y-auto;

    &__nav-container {
        @apply flex bg-black-gray flex-shrink-0;

        min-height: 100%;
    }

    &__inner {
        @apply flex flex-col;
        width: 100px;
    }

    &__col {
        @apply flex flex-col flex-grow h-full overflow-hidden pt-9 px-2;
    }

    &__mobile-button-open,
    &__mobile-button-close {
        @apply px-5 py-8 text-white bg-black-gray;

        svg {
            @apply h-8 w-8;
        }
    }

    &__mobile-dialog {
        @apply fixed inset-0 flex z-40 md:hidden;
    }

    &__dialog-overlay {
        @apply fixed inset-0 bg-gray bg-opacity-75;
    }

    &__mobile-menu {
        @apply relative flex-1 flex flex-col w-full pt-5 pb-4 bg-blue-light;

        max-width: 6.25rem;
    }

    &__logo {
        @apply flex-shrink-0 flex items-center px-5;
    }

    &__nav-list {
        @apply mt-7 flex-1 h-0 overflow-hidden;
    }

    &__nav-list-item {
        @apply flex-1 space-y-0.5;

        a {
            @apply text-gray hover:bg-white flex items-center px-1 py-2 text-base font-medium rounded-lg;
            height: 80px;
            width: 80px;

            &>div {
                width: 100%;
            }

            &:hover {
                svg {
                    @apply text-black-gray;
                }

                span {
                    @apply block;
                }
            }

            &.-active {
                @apply bg-white;

                svg {
                    @apply text-black-gray;
                }

                span {
                    @apply block;
                }
            }

            &.superadmin:hover {
                +div.organization-sub-menu {
                    display: flex;
                }
            }

            svg {
                @apply block m-auto text-gray-light mb-1 m-auto;
            }

            span {
                @apply hidden text-black-gray text-10 leading-4 w-full text-center uppercase;
            }
        }

        div.organization-sub-menu {
            display: none;
            position: absolute;
            z-index: 20;
            margin: -94px 0 0 65px !important;

            &:hover {
                display: flex;
            }

            div.hover-buffer {
                @apply bg-transparent;
                width: 27px;
            }

            div.item-container {
                display: flex;
                gap: 16px;
                flex-wrap: wrap;
                align-items: center;
                text-align: center;
                width: 250px;
                padding: 16px;
                border-radius: 10px;
                background-color: #F3F3F4;

                a.item {
                    @apply w-full py-2 bg-white hover:bg-blue-light text-black-gray border-white font-bold text-12;
                    display: flex;
                    gap: 16px;
                    width: 100%;
                    height: auto;
                    padding: 16px;
                    border-radius: 10px;
                    line-height: 1rem;
                    text-transform: uppercase;

                    svg {
                        margin: 0;
                    }
                }
            }
        }
    }

    &__spacer {
        @apply flex-shrink-0 w-14;
    }
}

@media only screen and (min-width: 767.98px) {
    .c-sidebar {
        &__nav-container {
            display: flex !important;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-sidebar {
        position: absolute;
        z-index: 20;

        &__nav-container {
            display: none;
        }

        &__col {
            padding-top: 0.5rem;
        }

        &__nav-list {
            margin: 0;
        }

        &__logo {
            display: none;
        }

        &__overlay {
            @apply hidden fixed h-full w-full inset-0 bg-gray bg-opacity-75 transition-opacity;
            z-index: 5;
        }
    }
}