.c-bar-chart {
    &__bar {
        &__background {
            fill: #d1d5db;
        }

        &__label {
            @apply text-gray font-bold text-12;

        }

        &__value {
            @apply fill-black-gray;
        }
    }
}