.c-user-profile-assessments {
    .tbody {
        @apply text-black-gray text-sm font-light;

        line-height: 22px;
    }

    table {
        tr {

            th:first-child,
            td:first-child {
                padding-left: 1.5rem;
            }

            th:last-child,
            td:last-child {
                padding-right: 1.5rem;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        &__main-row {
            display: flex;
            gap: 1.25rem;
        }
    }

    &__video-wrapper {
        width: 356px;
        max-width: 100%;
        height: 234px;

        iframe {
            max-width: 100%;
            width: 100%;
            height: 100%;
            max-height: 100%;

            &.embedded-video {
                margin: 0 auto;
            }
        }
    }

    &__score-section-card {
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        max-width: 100%;

        &.chart-wrapper {
            overflow: auto;
        }
    }

    &__icon-container-20 {
        max-width: 20px;
        min-width: 20px;

        svg {
            max-width: 100%;
            max-height: 100%;
            min-height: 100%;
            min-width: 100%;
            height: 100%;
            width: 100%;
        }
    }

    .checkmark-icon {
        circle {
            fill: #00DFBC;
        }

        path {
            @apply fill-white;
        }
    }

    .icon {
        svg {
            max-width: 100%;
            max-height: 100%;
            min-height: 100%;
            min-width: 100%;
            height: 100%;
            width: 100%;
        }

        &__20px {
            display: block;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
        }
    }

    &__icon-container {
        div {
            max-width: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            padding: 0.3rem;
            border-radius: 9999px;
            border: 2px solid #FFF;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__movement-group-score-card {
        display: flex;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border-radius: 10px;
        min-width: 250px;
        width: 100%;

        &__main-text {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
        }

        &__sub-text {
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 12px;
            text-transform: uppercase;
        }
    }

    &__header {
        &__main-text {
            @apply text-xs font-light mb-6;

            strong {
                font-weight: bold;
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-user-profile-assessments {
        table {
            tr {

                th:first-child,
                td:first-child {
                    padding-left: 0.5rem;
                }

                th:last-child,
                td:last-child {
                    padding-right: 0.5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1199.98px) {
    .c-user-profile-assessments {
        &__header {
            &__main-row {
                flex-wrap: wrap;

                .width-half {
                    width: 48%;
                }
            }
        }
    }
}