.c-add-ons {
    &__header {
        @apply flex justify-between mb-5;
    }

    &__modal-wrapper {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &__modal-title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__modal-content {
        line-height: 26px;
        text-align: center;
    }

    &__modal-actions {
        margin-top: 30px;
        text-align: center;
    }

    &__removal-info-bold {
        @apply text-16 font-medium;
        font-style: normal;
        line-height: 26px;
    }

    &__removal-info-regular {
        @apply text-16 font-light;
        font-style: normal;
        line-height: 26px
    }

    &__removal-container {
        text-align: center;

    }

    &__removal-info-container {
        margin: 2rem 0;
    }

    // h1 {
    //     @apply font-book;
    //     font-size: 15px;
    //     margin-bottom: 0.5rem;
    // }

    h3 {
        @apply mt-3 mb-3;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    &__add-input {
        @apply bg-white text-14;
        border-radius: 25px;
        border: 1px solid rgba(18, 22, 34, 0.20);
        height: 40px;
        text-align: center;
        max-width: 280px;
    }

    &__warning_label {
        display: flex;
        padding: 0.5rem;
        border-radius: 10px 10px 0px 0px;
        align-items: center;
        justify-content: center;
    }

    &__column {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-wrap: nowrap;

        button {
            max-width: 280px;
        }
    }

    &__icon-column {
        width: 80px;
        height: 80px;
        margin-bottom: auto;
    }

    &__description-column {
        display: flex;
        flex-direction: column;
        min-width: 40%;
        max-width: 450px;
    }

    &__product-name {
        @apply text-16 font-bold;
    }

    &__product-description {
        @apply text-14 font-light;
    }

    &__price-and-button-container {
        flex-grow: 1;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 2.5rem;
    }

    &__price-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        div {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
        }
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &__add-on-list {
        display: flex;
        flex-direction: column;
    }

    &__add-on-list-row {
        @apply bg-black-gray-5;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        padding: 2rem 2.5rem;
        margin-bottom: 1.5rem;
        gap: 2rem;
    }

    &__information-container {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        column-gap: 2.5rem;
        row-gap: 1rem;
    }

    &__price-text {
        @apply font-bold text-24;
    }

    &__payment-cycle-text {
        @apply text-black-gray-50 text-14 font-light;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-add-ons {
        &__add-on-list-row {
            flex-direction: column;
        }

        &__icon-column {
            align-self: flex-start;
        }

        &__price-and-button-container {
            gap: 1rem;

            div {
                width: 100%;
            }
        }

        &__price-column {
            div {
                justify-content: center;
            }
        }

        &__add-input {
            width: 100%;
        }

        &__column {
            button {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1399.98px) {
    .c-add-ons {
        &__description-column {
            width: 100%;
            max-width: 100%;
        }

        &__price-and-button-container {
            justify-content: flex-start;
        }
    }
}