.c-price-card {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(18, 22, 34, 0.05);
    border-radius: 10px;
    padding: 1.5rem;

    &.highlight {
        border: 2px solid rgba(18, 22, 34, 1);
    }

    &__top-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;
    }

    &__badge {
        @apply bg-green-dark;

        padding: 0.375rem 0.5rem;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    &__price {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }

    &__payment-cycle {
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;
    }

    &__sub-text {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
    }

    &__round-checkbox {
        position: relative;
        width: 20px;
        height: 20px;

        input[type="checkbox"] {
            visibility: hidden;

            &:checked+label {
                @apply bg-black-gray;

                border-color: #121622;

                &:after {
                    opacity: 1;
                }
            }
        }

        label {
            background-color: #fff;
            border: 1px solid #D0D0D3;
            border-radius: 50%;
            cursor: pointer;
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            width: 20px;

            &:after {
                border: 2px solid #fff;
                border-top: none;
                border-right: none;
                content: "";
                height: 6px;
                left: 3px;
                opacity: 0;
                position: absolute;
                top: 4.5px;
                transform: rotate(-45deg);
                width: 12px;
            }
        }
    }
}