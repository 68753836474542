.c-user-profile-summary {
    &__icon-container-20 {
        max-width: 20px;
        min-width: 20px;

        svg {
            max-width: 100%;
        }

        .checkmark-icon {
            circle {
                fill: #00DFBC;
            }

            path {
                @apply fill-white;
            }
        }
    }

    &__section-container {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        flex-grow: 1;
    }

    &__score-section-card {
        border: 1px solid rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 1.5rem;
        width: 263px;
        min-width: 263px;
        max-width: 326px;
        display: flex;
        flex-direction: column;
    }

    &__onboarding-section-card {
        @apply bg-black-gray-5 border-0;
        border-radius: 10px;
        padding: 1.5rem;
        width: 329px;
        min-width: 329px;
        max-width: 329px;
        display: flex;
        flex-direction: column;
    }

    &__donut-chart {
        width: 210px;

        .c-donut-chart {
            width: 100%;
        }
    }

    // --------- RESPONSIVENESS START ---------
    &__score-section-card,
    .c-gray-footer-card,
    .c-focus-areas-card {
        flex-grow: 1;
        max-width: 100%;
        width: auto;
    }

    .c-focus-areas-card__focus-area {
        max-width: 100%;
        width: 100%;
    }

    // --------- RESPONSIVENESS END ---------
}