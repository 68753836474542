.c-account-management {
  height: inherit;
  max-width: 100%;
  width: 100%;
  font-size: 13px;

  &__wrapper {
    height: 100%;
  }

  &__actions {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  &__error-message {
    color: #D32F2F;
  }

  &__error_input {
    border-color: #D32F2F !important;
  }

  &__container {
    margin-top: 2rem;
    background-color: #F3F3F4;
    height: fit-content;
    width: 40%;
    border-radius: 10px
  }

  &__icon {
    width: 22px;
    height: 22px;
    margin-right: 1em;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem
  }

  &__password_container {
    margin-top: 0.5em;
    justify-content: space-between;
    display: flex;
  }

  &_item {
    display: flex;
    margin-bottom: 0.75em;
  }

  &__content {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
  }

  &__password-form {
    &_input {
      font-size: 13px !important;
      line-height: 1.25rem !important;
      width: 100%;
      margin-bottom: 0px !important;
    }

    &_label {
      margin-bottom: 0 !important;
    }
  }

  &__form {
    @apply mt-6;
    position: relative;
    padding-bottom: 24px;

    &__edit {
      @apply mb-6 grid gap-6;
      flex-direction: column;

      &.-full {
        @apply grid-cols-1;
      }

      label {
        @apply text-black-gray;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
      }

      .c-account-management__control {
        @apply rounded-3xl;
      }

      .c-account-management__value-container {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }

      .c-account-management-date-input-option {
        background-color: white;
      }

      .c-account-management__indicator-separator {
        @apply hidden;
      }

      .c-account-management__input-container {
        @apply m-0;
        min-height: 0 !important
      }

      input[type="text"] {
        @apply border border-gray rounded-3xl text-base leading-6 py-2.5 px-5 w-1/2;
        font-size: 13px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .c-account-management {
    &__content {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &__container {
      width: 100%;
    }
  }
}