.c-report-schedule {
    @apply pb-20;

    h2 {
        @apply text-gray text-3xl font-bold mt-20 text-center;
    }

    hr {
        @apply border-t border-solid border-gray pb-4;
    }

    &__actions {
        @apply mt-6 text-left;

        &__send {
            @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-light text-base font-medium text-white hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light sm:col-start-2 sm:text-sm;
        }
    }

    &__header {
        line-height: normal;
        padding-bottom: 10px;
    }

    &__settings {
        @apply mb-6 md:w-1/2 lg:w-1/3;

        input[type="checkbox"] {
            @apply ml-4;
        }

        >* {
            @apply mb-4;
        }
    }

    .c-report-schedule__control {
        @apply rounded-3xl;
    }

    .c-report-schedule__value-container {
        padding: .5rem 1.25rem !important;
        line-height: 1.5rem !important;
    }

    .c-report-schedule__indicator-separator {
        @apply hidden;
    }

    .c-report-schedule__input-container {
        @apply m-0;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-report-schedule {
        &__actions {
            .c-button {
                width: 100%
            }
        }
    }
}