.c-user-profile {
    &__content {

        .-space {
            margin-top: 80px;
        }

        &.-background {
            position: relative;
            padding: 50px 0;

            &:after {
                @apply bg-black-gray-5;
                content: '';
                position: absolute;
                left: -5rem;
                top: 0;
                right: 0;
                width: calc(100% + 10rem);
                height: 100%;
                z-index: -1;
            }
        }

        &.-profile {
            @apply grid-cols-4;
        }

        h1 {
            @apply text-3xl font-bold;
            margin-top: 70px;
        }

        h2 {
            @apply text-3xl font-bold;
        }

        h3 {
            @apply text-lg font-bold uppercase;
        }

        &__data {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            row-gap: 2rem;
            padding-bottom: 40px;
        }
    }

    &__icon {
        height: 38px;
        width: 38px;
        background: white;
        border-radius: 50%;
        text-align: center;
        padding: 2px 0;
        border: 2px solid white;
        padding-top: 4px;
        margin-right: 10px;

        &.-movement-health {
            @apply bg-blue-medium;
        }

        &.-nutrition-awareness {
            @apply bg-green-medium;
        }

        &.-behavioral-health {
            @apply bg-green-medium;
        }

        &.-body-composition {
            @apply bg-orange;
        }

        &.-breathing-quality {
            @apply bg-blue-medium;
        }

        &.-physical-activity {
            @apply bg-red-medium;
        }

        &.-sleep-wellness {
            @apply bg-purple-medium;
        }

        &.-injury-history {
            @apply bg-red-medium;
        }

        .icon {
            height: 26px;
            width: 26px;
            margin: 0 auto;
        }
    }

    &__card {
        background: white;
        padding: 20px;
        border-radius: 20px;
        width: 48%;

        &__title {
            display: flex;
        }

        &__content {
            margin-left: 48px;
        }

        &-list {
            display: flex;
            justify-content: space-between;
        }

        div h4 {
            @apply text-16;
            line-height: 40px;
            margin-bottom: 0px;
        }

        +.c-user-profile__card {
            margin-left: 20px;
        }
    }

    &__cta {
        text-align: center;
        padding: 20px 0;

        @screen md {
            padding: 54px 0;
        }

        h6 span {
            @apply text-gray-light text-sm uppercase font-bold;
        }

        div {
            @apply text-black font-light text-26;
            font-style: normal;
            line-height: 2rem;
            text-align: center;
            letter-spacing: 0.05em;
            margin-bottom: 20px;
        }
    }

    &__score-summary {
        text-align: left;

        &__chart {
            width: 60%;
            text-align: left;

            .c-line-chart__wrapper {
                width: 100%;
            }
        }
    }
}

.c-authenticated-layout__container {
    .c-user-profile {
        h4 {
            @apply font-bold;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-user-profile {
        &-header {
            &__actions {
                display: flex;
                flex-wrap: wrap-reverse;
                justify-content: center;
                text-align: center;
                word-break: break-word;
                row-gap: 1rem;

                .c-button {
                    width: 100%;
                    margin: 0;
                }
            }

            &__col {
                display: flex;
                flex-flow: wrap;
                justify-content: center;
                row-gap: 1rem;
            }

            &__fieldgroup {
                width: 100%;
            }
        }

        &__content {
            &__data {
                justify-content: center;
            }
        }

        &__card-list {
            flex-flow: wrap;
            justify-content: center;
            gap: 2rem;

            .c-user-profile__card {
                width: 100%;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-user-profile {
        &__content {
            &.-background {
                &:after {
                    width: calc(100% + 7rem);
                }
            }
        }

        &-summary {
            &__container {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .c-user-profile {
        &-summary {
            &__container {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }
}

@media only screen and (max-width: 1199.98px) {
    .c-user-profile {
        &__score-summary {
            &__chart {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    .c-user-profile {
        &__tab-content {
            margin-right: auto;
            margin-left: auto;
            max-width: 2000px;
        }
    }
}