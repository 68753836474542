.c-assessment-complete {
    @apply text-16 font-light;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: var(--ion-color-main);
    line-height: 24px;
    text-align: center;
    padding: 0 var(--ion-margin, 2rem);
    height: 100%;

    h1 {
        @apply font-light text-26;
        line-height: 60px;
        margin: 0;
        width: 100%;
        z-index: 2;
        text-align: center;
    }

    &__content {
        flex: none;
        height: 40%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .c-congratulations-actions {
            width: 100%;
        }

    }



    &__image {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        img {
            width: 75%;
        }
    }

    &__error {
        color: #990000;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-assessment-complete {
        flex-wrap: wrap;
    }
}