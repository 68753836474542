.css-13cymwt-control {
    border-radius: 1.5rem !important;
    border-color: black !important;
    margin-bottom: 0.75rem !important;
    line-height: 2rem;
}

.css-13cymwt-control:hover {
    border-radius: 1.5rem !important;
    border-color: black !important;
    margin-bottom: 0.75rem !important;
    line-height: 2rem;
}

.css-t3ipsp-control {
    border-radius: 1.5rem !important;
    border-color: black !important;
    margin-bottom: 0.75rem !important;
    line-height: 2rem;
}

.css-t3ipsp-control:hover {
    border-radius: 1.5rem !important;
    border-color: black !important;
    margin-bottom: 0.75rem !important;
    line-height: 2rem;
}

// .css-1fdsijx-ValueContainer {
//     padding: 0.5rem 1.25rem !important;
//     line-height: 1.5rem !important;
//     // max-height: 2.875rem;
//     min-height: 2.875rem;
// }

.css-1u9des2-indicatorSeparator {
    background-color: inherit !important;
}

.css-edit-org-status {
    width: 222.67px
}