.error-style {
    color: red;
}

select {
    border-radius: 1.5rem;
}

option {
    line-height: 2rem;
    font-size: large;
}
