.c-change-plan {
  height: inherit;
  max-width: 100%;
  width: 100%;

  &__transparent {
    opacity: 0;
  }

  &__modal-wrapper {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &__modal-title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__modal-content {
    line-height: 26px;
    text-align: center;
  }

  &__modal-actions {
    margin-top: 30px;
    text-align: center;
  }

  &__wrapper {
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &__create-organization {
    padding: 0 1rem;
  }

  &__header {
    @apply font-light text-26;
    color: var(--ion-color-main);
    width: 100%;
    text-align: center;
    margin-top: 1rem;

    &.card {
      height: 6rem;
      display: flex;
      margin-top: 0;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

    }
  }

  &__container {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &_card {
      @apply text-black-gray text-14 font-book;
      display: flex;
      width: 27%;
      max-width: 311px;
      padding: 24px 24px;
      line-height: 22px;
      border-radius: 20px 20px 20px 20px;

      flex-direction: column;
      justify-content: space-around;
    }
  }


  &__card-content-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 1050px;
    max-width: 100vw;
    height: auto;
  }

  &__title {
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
    height: 2rem;
  }

  &__line {
    @apply bg-black-gray-20;
    height: 1px;
    border: none;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    margin-left: 0 !important;
    width: auto !important;
  }

  &__bottom_line {
    @apply bg-black-gray-20;
    height: 1px;
    border: none;
    margin-top: 2.5rem !important;
    margin-bottom: 5rem !important;
    margin-left: 0 !important;
  }

  &__row {
    width: 327px;
    display: flex;
    justify-content: center;
  }

  &__list_wrapper,
  &__list_container,
  &__list_title {
    display: flex;
    flex-flow: wrap;
  }

  &__list_container {
    flex-direction: column;
    margin-top: 0.5rem;
  }

  &__list_wrapper {
    justify-content: space-around;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    margin-top: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: inherit;
    margin-left: auto;
    margin-right: auto
  }

  &__list_title {
    @apply font-medium text-20;
    margin-bottom: 1rem;

    h4 {
      text-align: left !important;
      width: auto !important;
    }
  }

  &__wp-title {
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 26px !important;
  }

  &__ul {
    @apply text-16 font-light;
    font-style: normal;
    line-height: 18px;
  }

  &__plans-table {
    table-layout: fixed;

    tbody {
      tr {
        @apply border-b border-solid border-b-black-gray-20;
      }
    }
  }

  &__account-created {
    display: flex;
    flex-flow: wrap;
  }

  &__account-created-content {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__account-created-content-text {
    width: 70%;
    text-align: center;
  }
}

@media only screen and (max-width: 991.98px) {
  .c-change-plan {
    &__container_card {
      width: 70% !important;
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .c-change-plan {
    &__plans-info-desktop {
      display: none;
    }

    &__plans-info-mobile {
      display: block;
      max-width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    &__container_card {
      width: 35%;
      margin: 0 auto 3rem;
    }

    &__list_container {
      width: 90%;
    }

    &__account-created {
      gap: 2rem;
    }

    &__account-created-content {
      width: 100%;
      height: auto;
    }

    &__account-created-content-text {
      width: 90%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .c-change-plan {
    &__plans-info-desktop {
      display: block;
    }

    &__plans-info-mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: 1399.98px) {
  .c-change-plan {
    &__card-content-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
      min-width: 0; // It resets default min-width of a grid's child that made it overflow the parent
      height: auto;
    }
  }
}