.c-focus-areas-card {
    border: 1px solid rgba(18, 22, 34, 0.05);
    border-radius: 10px;
    padding: 1.5rem;
    width: 263px;
    min-width: 263px;
    max-width: 263px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &__focus-area {
        background-color: rgba(18, 22, 34, 0.05);
        border-radius: 10px;
        padding: 0.5rem;
        max-width: 215px;
        min-width: 215px;
        width: 215px;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 12px;

        div {
            max-width: 2.5rem;
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-height: 2.5rem;
            padding: 0.4rem;
            border-radius: 9999px;
            border: 2px solid #FFF;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}