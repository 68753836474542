.c-enterprise-contact {
  max-width: 900px;
  margin: 0 auto;

  &__container {
    @apply flex justify-between items-center gap-8 mb-5 text-sm;
  }

  &_list_item {
    @apply flex items-center mt-3;
  }

  &__form-container {
    @apply bg-black-gray-5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding: 3.5rem 5rem;
    border-radius: 10px;

    p {
      @apply text-20 font-light;
      line-height: 28px;
      text-align: center;
    }
  }

  &__form {
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 767.98px) {
  .c-enterprise-contact {
    &__container {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__form-container {
      padding: 1rem;
    }
  }
}