.c-loader {
    @apply flex justify-center items-center absolute opacity-0 transition-all pointer-events-none h-full w-full z-10;

    top: 0;
    left: 0;

    // &:not(.-collapse) {
    //     @apply -my-6 -mx-4 sm:-mx-6 md:-mx-8;
    // }

    &.-active {
        @apply opacity-100;
    }

    &__overlay {
        @apply fixed bg-gray opacity-70 h-full;
        width: calc(100vw - 120px);
    }

    &__content {
        @apply fixed animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray;
        top: calc(50% - 64px);
        left: calc(50% - 64px);
    }

    
}

@media only screen and (max-width: 767.98px) {
    .c-loader {
        &__overlay {
            @apply fixed bg-gray opacity-70 h-full w-full;
        }
    
    }
}