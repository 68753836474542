.c-card {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
    background-color: #f3f3f4;
    border-radius: 10px;
    box-shadow: none;
    padding: 0;
    margin: 0 10px 20px;
    overflow: hidden;
    position: relative;
    width: 380px;

    &.-done {
        @apply bg-gray-light;
    }

    &.full-image {
        background-position: center;
        background-size: cover;
        align-items: center;

        // 1/3 larger than a normal card
        min-height: 133px;

        .c-card__content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
        }

        .c-card__content__title {
            top: 33px;
            color: var(--ion-color-main-contrast);
            font-size: 133%;
            padding: 0.5rem;
            vertical-align: middle;
        }
    }

    &.-intro {
        @apply bg-blue-light text-black;

        .c-card__content {
            height: unset;
            padding: 28px 33px;

            &__title {
                @apply text-14;
                line-height: 22px;
            }
        }

        .c-card__close {
            background-color: white;
            border-radius: 13px;
            height: 26px;
            width: 26px;
            vertical-align: middle;
            position: absolute;
            right: 12px;
            top: 12px;

            ion-icon {
                @apply text-26;
            }
        }
    }

    &.-error {
        border: 2px solid #f99;

        .c-card__content {
            height: unset;
        }

        div,
        li {
            @apply font-light;
        }

        div.c-card__content__title,
        p,
        ul {
            margin-top: 0;
            margin-bottom: 16px;
        }
    }

    &__image {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100px;
        vertical-align: top;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &.-none {
            border-radius: 999px;

            &.-blue {
                @apply bg-blue-light;
            }

            &.-grey {
                background-color: var(--ion-color-main);
            }

            &.-green {
                background-color: #49e1c9;
            }
        }
    }

    &__content {
        display: inline-block;
        flex: 1;
        padding: 10px 10px 10px 15px;
        height: 100px;
        vertical-align: top;

        &__complete {
            @apply font-bold text-10 bg-black-gray text-white;
            // color: var(--ion-color-main-contrast);
            display: inline-block;
            position: absolute;
            bottom: 10px;
            right: 10px;
            // background-color: var(--ion-color-main);
            border-radius: 4px;
            padding: 4px 8px;
            text-transform: uppercase;
        }

        &__subtitle {
            @apply text-12 font-light;
            color: var(--ion-color-main);
            opacity: 0.5;
            line-height: 16px;
        }

        &__title {
            @apply text-16 font-light;
            color: var(--ion-color-main);
            line-height: 20px;
            padding-top: 10px;
            height: 100%;
            overflow: hidden;

            &.-has-subtitle {
                padding-top: 4px;
            }
        }

        &__duration {
            @apply text-10;
            color: var(--ion-color-main);
            line-height: 16px;
            opacity: 0.3;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            height: 100%;
        }

        &__loading {
            position: absolute;
            right: 15px;
            bottom: 0;
        }
    }

    button {
        background: white;
        border-radius: 20px;
        border: 1px solid #c4c4c4;
        padding: 5px 10px;
    }
}

ion-card {
    --background: #f3f3f4;
    margin: 20px 10px;
    margin-inline-start: 10px;
    margin-inline-end: 10px;

    &:first-child {
        margin-top: 0;
    }

    &.-done {
        @apply bg-black-gray;
    }
}