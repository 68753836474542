.toggle-button-container {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
    gap: 0.8rem;

    .label-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .toggle-button-label-small {
        font-size: 14px !important;
        line-height: 18px !important;
        margin: 0 !important;
        font-weight: 500 !important;

        &.-light {
            @apply font-light;
            color: rgba(18, 22, 34, 0.5) !important;
            font-weight: 300 !important;
        }
    }

    .toggle-button-label {
        padding-right: 40px;
        margin: 0 !important;
    }

    .switch {
        position: relative;
        display: inline-block;
        margin: 0 !important;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(209, 213, 219);
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked+.slider {
        @apply bg-black-gray;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #121622;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}