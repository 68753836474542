.c-assessment-help-modal {
    @apply relative z-50;

    &__image {
        border-radius: 20px 20px 0 0;
    }

    h4 {
        @apply text-12 font-bold;
        color: darkgray;
        margin-top: 8%;
        margin-bottom: 1%;
        line-height: 30px;
    }

    p {
        @apply text-12 font-light;
        color: white;
        line-height: 20px;
        margin-left: 8%;
        margin-right: 8%;
    }

    &__content {
        @apply bg-black-gray;
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 0 0 20px 20px;
    }

    &__button {
        @apply rounded-full bg-white text-black-gray;
        justify-content: center;
        height: 50px;
        padding: 0 35px 0 35px;
        margin: auto;
        margin-top: 9%;
        margin-bottom: 7%;
    }

    &__container {
        width: 50%;
    }

    &__modal {
        display: flex;
        flex-direction: column;
    }

    &__backdrop {
        @apply fixed inset-0 bg-black/30;
        aria-hidden: true;
    }

    &__overlay {
        @apply fixed inset-0 flex items-center justify-center p-4;
        width: 33%;
        margin: auto;
    }

    &__panel {
        @apply bg-white;
        width: 980px;
        height: 146px;
        border-radius: 30px;
    }

    &__header {
        background: black;
        border-radius: 30px 30px 0 0;
        height: 146px;
        display: flex;
        justify-content: center;
    }

    &__header-image {
        max-height: 85px;
        margin-top: 28px;
    }

    &__button-area {
        display: flex;
        justify-content: center;
        padding-top: 42px;

        &.-no-lower-message {
            margin-bottom: 65px;
        }

        &.-lower-message {
            margin-bottom: 60px;
        }
    }

    &__or {
        display: flex;
        vertical-align: middle;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__extra-message {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        a {
            @apply text-blue-dark;
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-assessment-help-modal {
        &__overlay {
            width: auto;
        }
    }
}