.p-landing {
    h1 {
        @apply font-light text-26;
        line-height: 49px;
        text-align: center;
    }

    h4 {
        @apply text-20 font-light;
        line-height: 30px;
    }

    p {
        @apply text-16 font-light;
        line-height: 26px;
    }

    strong {
        @apply font-medium;
    }

    header {
        @apply bg-black-gray;

        width: 100%;
        height: fit-content;
        display: flex;
        flex-flow: wrap;
        align-content: flex-start;
        justify-content: center;
        border-radius: 40px 40px 0 0;
        gap: 0;
    }

    main {
        text-align: center;
        padding: 4rem 6rem;
        max-width: 100%;
        width: 100%;
    }

    footer {
        @apply bg-blue-light;
        text-align: center;
        padding: 4rem 6rem 0 6rem;
        max-width: 100%;
        width: 100%;
    }

    &__logo {
        height: 88px;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
            width: 201.74px;
            height: 40px;
        }
    }

    &__header {
        width: 100%;
        max-height: 1474.5px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__welcome-text-container {
        display: flex;
        flex-flow: wrap;
        text-align: center;
        justify-content: center;
        gap: 1.5rem;
        margin-bottom: 4rem;

        p {
            width: 100%;
        }
    }

    &__paragraph-header {
        @apply text-black-gray-50 text-14 font-bold;
        line-height: 26px;
        opacity: 70%;
        text-transform: uppercase;
    }

    &__cards-container {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        row-gap: 1.5rem;
    }

    &__card {
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: center;
        width: 100%;
        column-gap: 6rem;
        border-radius: 20px;
    }


    &__card-image {
        max-width: 294px;
        max-height: 294px;
        width: 294px;
        height: 294px;
    }

    &__card-text {
        display: flex;
        flex-flow: wrap;
        gap: 1rem;
    }

    &__error {
        display: flex;
        flex-direction: column;
        padding: 3rem;
    }

    &__error-content-container {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-grow: 1;
    }

    &__error-image-container,
    &__error-message-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50%;
        padding: 1rem;

        img {
            width: 250px;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .p-landing {
        width: 100vw;

        header {
            border-radius: 0;
        }

        main {
            padding: 4rem 1rem;
        }

        footer {
            padding: 4rem 1rem 0 1rem;
        }

        &__cards-container {
            row-gap: 3rem;
        }

        &__card {
            flex-wrap: wrap;
            text-align: center;
            gap: 1rem;
            padding-bottom: 0.5rem;
            padding-right: 0;

            &.right {
                flex-wrap: wrap-reverse;
            }
        }

        &__card-text {
            justify-content: center;
            gap: 0.5rem;

            h4 {
                justify-content: center;
            }
        }

        &__error-image-container,
        &__error-message-container {
            width: 100%;
        }
    }
}