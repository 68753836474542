.c-link-info {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__qr-container {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
        align-items: center;
    }

    &__text-container {
        @apply flex flex-col m-3;
    }

    &__url-container {
        display: flex;
        align-items: center;
        flex-flow: wrap;
        gap: 1rem 2.5rem;

        p {
            max-width: 100%;
            width: 50%;
            word-break: break-all;
            flex-grow: 1;
        }
    }

    &__title {
        @apply font-medium text-20;
        line-height: 30px;
        margin-bottom: 2rem;
    }

    &__info {
        @apply text-13;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    &__c-button {
        @apply bg-black-gray text-white font-bold rounded-full text-sm;
        min-height: 2.5rem;
        height: fit-content;
        padding: 0.5rem 1rem;
        min-width: 180px;

        &.-outline {
            @apply bg-transparent text-black-gray border-2 border-black-gray;
            display: flex;
            justify-content: space-evenly;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-link-info {
        &__container {
            flex-wrap: wrap;
        }
    }
}