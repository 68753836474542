.c-integrations {
    &__card {
        @apply border border-black-gray-5;

        width: 280px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
        border-radius: 10px;
    }
}