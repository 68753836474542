.c-authenticated-layout {
    @apply h-screen flex overflow-hidden relative;

    &__window {
        @apply flex flex-col w-0 flex-1 overflow-hidden;
    }

    &__content {
        @apply flex-1 relative overflow-y-auto focus:outline-none;
    }

    &__container {
        @apply w-full pt-6 pb-6 px-20;

        h2 {
            @apply text-left;
            margin-bottom: 1rem;
        }

        h6 {
            @apply text-2xl font-bold;
        }

        h4 {
            @apply font-light text-xl mb-6;
        }

        hr {
            @apply mt-10 mb-20 border-gray-light;
            width: calc(100% + 10rem);
            margin-left: -5rem;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-authenticated-layout {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-authenticated-layout {
        &__container {
            padding-left: 1rem;
            padding-right: 1rem;

            hr {
                width: calc(100% + 6rem);
            }
        }
    }
}