.c-lead-link-view {
    &__stat_container {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    &__stat_row {
        display: flex;
        justify-content: center;
        flex-flow: wrap;
        gap: 1.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &__stat {
        display: flex;
        background-color: #F3F3F4;
        width: 200px;
        height: 128px;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;

    }

    &__main_stat {
        @apply text-32 font-bold;
        line-height: 40px;
        margin-top: 1rem;
    }

    &__percentage {
        @apply text-13 font-light;
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        text-align: center;
        background-color: #E8E8EA;
        bottom: 0;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 0px 0px 10px 10px;
    }

    &__qr-container {
        display: flex;
        flex-direction: column;
    }

    &__text-container {
        @apply flex flex-col m-3
    }

    &__title {
        @apply font-medium text-20;
        line-height: 30px;
        margin-bottom: 2rem;
    }

    &__info {
        @apply text-13;
        display: flex;
        margin-bottom: 2rem;
    }

    &__c-button {
        @apply bg-black-gray text-white font-bold rounded-full text-sm;
        min-height: 2.5rem;
        height: fit-content;
        padding: 0.5rem 1rem;
        min-width: 180px;

        &.-outline {
            @apply bg-transparent text-black-gray border-2 border-black-gray;
            display: flex;
            justify-content: space-evenly;
        }
    }
}