.c-multiple-choice {

    &__checkbox-label {
        display: inline-block;
        width: 65%;
        padding: 10px;
        border: solid 2px #ccc;
        transition: all 0.3s;
        text-align: center;
        border-radius: 30px;
        margin-bottom: 6px;
    }

    input[type="checkbox"]:checked+label {
        @apply border-2 border-solid border-black-gray bg-black-gray text-white;
    }

    ion-label.ion-text-wrap {
        @apply text-12 font-medium;
        width: 240px;
        height: auto;
        color: var(--ion-color-main);
        line-height: 22px;
        margin: 5px auto;
        position: absolute;
        top: 45%;
        left: 48%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        text-align: center;
        z-index: 2;
    }

    ion-item {
        --background-activated-opacity: 0;
    }

    ion-checkbox {
        width: 290px;
        min-height: 60px;
        max-height: max-content;
        padding: 2px;
        --background: rgba(var(--ion-color-main-rgb), 0.05);
        --border-style: none;
        --border-radius: 30px;
        margin: 5px auto;
        z-index: 1;
    }

    ion-checkbox.checkbox-checked::part(container) {
        --border-color-checked: none;
        --checkmark-color: none;
        --background-checked: var(--ion-color-main);
        transform: none;
    }
}