// light = font-light
// book = font-normal
// medium = font-medium
// bold = font-bold
h1 {
    @apply font-light text-26;
    line-height: 34px;
}

h2 {
    @apply text-20 font-medium;
    line-height: 28px;
}

h3 {
    @apply font-book text-16;
    line-height: 24px;
}

.body {
    @apply font-light text-14;
    line-height: 22px;
}

.body-bold {
    @apply font-medium text-14;
    line-height: 22px;
}

.small-text {
    @apply text-12 font-light;
    line-height: 18px;
}

.small-text-italic {
    @apply font-light text-12;
    line-height: 18px;
    font-style: italic;
}

.small-text-bold {
    @apply font-medium text-12;
    line-height: 18px;
}

.input-label {
    @apply font-medium text-13;
    line-height: 20px;
}

.input-text {
    @apply text-13 font-light;
    line-height: 20px;
}

.label-small {
    @apply text-10 font-bold;
    line-height: 12px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.label-large {
    @apply text-12 font-bold;
    line-height: 16px;
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.data-large {
    @apply text-56 font-bold;
    line-height: 64px;
}

.data-medium {
    @apply text-32 font-bold;
    line-height: 40px;
}

.data-small {
    @apply text-24 font-bold;
    line-height: 32px;
}