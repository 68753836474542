.c-tree {
  &__container {
    @apply pb-6 pt-2;
  }

  &__dragging {
    opacity: 0.3;
  }

  &__drop {
    background-color: #e8f0fe;
  }

  &__drag {
    &__container {
      @apply text-white text-14;
      align-items: "center";
      background-color: #1967d2;
      border-radius: 4px;
      box-shadow: 0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08);
      display: inline-grid;
      gap: 8px;
      grid-template-columns: auto auto;
      padding: 4px 8px;
      pointer-events: none;
    }

    &__icon,
    &__label {
      align-items: center;
      display: flex;
    }
  }

  &__node {
    &__container {
      @apply w-full py-4 bg-black-gray-5 hover:bg-blue-light text-black-gray border-white;
      grid-template-columns: auto auto 1fr auto;
      border-radius: 14px;
      margin: 8px 0;

      &.-collapse {
        @apply mb-0 border-none;
      }
    }

    &__expand-container {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      margin-left: 1rem;
      height: 100%;
    }

    &__expand {
      @apply text-center cursor-pointer flex h-6 w-6 justify-center items-center bg-white rounded-full;
      transition: transform linear 0.1s;
      transform: rotate(0deg);

      &__icon {
        svg {
          @apply w-5 h-5 text-blue-dark;
        }
      }

      &.-isOpen {
        transform: rotate(90deg);
      }
    }

    &__grid-item {
      @apply flex pr-3 items-center;

      &:hover {

        .c-button {
          @apply opacity-100;
        }
      }

      .c-button {
        @apply opacity-0 mr-6;
      }

      &__actions {
        @apply flex items-center justify-end;
      }


      &__action {
        &__icon {
          @apply inline-block align-top cursor-pointer p-2;

          svg {
            @apply text-gray;
            width: 26px;
          }
        }
      }

      &__data-container {
        display: flex;
        flex-flow: wrap;
        flex: 1 1;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 1.5rem;
        padding-right: 0.75rem;
        margin-right: 5rem;
      }

      &__data {
        align-self: center;
      }

      &__title {
        @apply flex-1 font-medium;
        align-self: center;
      }

      &__drag-handle {
        @apply flex text-gray text-lg relative;
        cursor: pointer;

        &:after {
          content: '';
          height: calc(100% + 8px);
          display: block;
          background: white;
          width: 2px;
          position: absolute;
          right: 0;
          top: -4px;
        }

        svg {
          @apply w-6;

          &:last-of-type {
            margin-left: -18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .c-tree {
    &__node {
      &__expand-container {
        margin-left: 0.5rem;
      }

      &__grid-item {
        flex-direction: column;
        flex-wrap: wrap;
        height: 150px;

        &__data-container {
          height: 100%;
          width: 51%;
          align-content: center;
          word-break: break-word;
          padding-left: 0.5rem;
          margin-right: 0;
          flex: none;
        }

        &__title {
          flex: none;
          width: 100%;
        }

        &__data {
          width: 100%;
        }

        &__drag-handle {
          width: 2rem;
          height: 100%;
        }

        &__actions {
          display: flex;
          flex-flow: wrap;
          align-content: center;
          justify-content: right;
          row-gap: 1rem;
          max-width: 30%;
          height: 100%;

          .c-button {
            margin: 0;
          }
        }
      }
    }
  }
}