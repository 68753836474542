.table-row-container {
    .buttons-container {
        @apply flex items-center justify-end;
        visibility: hidden;
        text-align: right;
    }
}

.table-row-container:hover {
    cursor: pointer;

    .buttons-container {
        @apply flex items-center justify-end;
        visibility: visible;
    }
}

.buttons-container {
    .c-button {
        margin: 5px;
    }
}

.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-container:hover {
    cursor: pointer;
    text-decoration: underline;
}

.qr-actions {
    display: flex;
    flex-direction: row;
    width: 20rem;
    justify-content: space-evenly;
    margin-top: 1rem;
    margin-bottom: 0;

    button {
        color: blue;
        text-decoration: underline;
    }
}

.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    text-align: center;
    justify-content: center;

    .qr-code {
        display: flex;
        justify-content: center;
    }
}

#qr-code>* {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}