.p-link-create-account {
    width: 100%;
    /*margin: 0 20px 0 20px;*/
    top: 500px;

    &.-showerror {
        @apply border border-solid border-red-dark;
    }

    &.-error {
        padding-left: 1.5em;
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
        font-size: 0.8em;
    }

    &.-coupon-error {
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.75rem;
        font-size: 0.8em;
    }

    h4 {
        @apply text-16 font-medium;
        font-style: normal;
        line-height: 24px;
        display: flex;
        justify-content: center;
        margin-top: 7%;
        margin-bottom: 20px;
    }

    img {
        @apply mx-auto;
        max-width: 358px;
        margin-top: 6rem;
        margin-bottom: 39px;
    }

    &__container {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
    }

    &__header-container {
        p {
            @apply text-16 font-light;
            font-style: normal;
            line-height: 24px;
            text-align: center;
        }

        h3 {
            @apply font-light text-26;
            font-style: normal;
            line-height: 26px;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    &__image {
        width: 45%;
    }

    &__info {
        width: 45%;
        margin-top: 3rem;

    }

    &__checkbox {
        height: 1.875rem;
        width: 1.875rem;
    }

    &__sign-up-checkbox {
        height: 1.5rem;
        width: 1.5rem;
        margin: 0 0 3px 0;
    }

    &__field {
        .c-input__input {
            margin: auto;
            width: 90%;
            margin-bottom: 1.25rem;
        }
    }

    &__sign-up-content {
        width: 50%;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
    }

    &__password_container {
        margin-top: 0.5em;
        justify-content: space-between;
        display: flex;
    }

    &_item {
        display: flex;
        margin-bottom: 0.75em;
    }

    &__terms {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;
        align-items: center;
        justify-content: left;
        margin-top: 1.25rem;
        // margin-bottom: 4rem;
        margin-bottom: 10px;
        width: 100%;

        div {
            @apply text-14;
            width: 100%;

            .button {
                @apply text-14;
                padding: 10px;
                padding-left: 0px;
                background-color: white;
                color: black;
                text-decoration: underline;

                --background: white;
                --box-shadow: none;
                --padding-start: 0;
                --padding-end: 0;
                --border-radius: 0;
                height: auto;
                margin: 0;
                vertical-align: middle;
                text-transform: none;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
    }
}

.login-button {
    @apply bg-black-gray text-white rounded-full;
    justify-content: center;
    height: 50px;
    width: 187px;
    margin-top: 32px;
}

.login-form {
    max-width: 358px;
}

@media only screen and (max-width: 767.98px) {
    .p-link-create-account {
        &__sign-up-content {
            width: 100%;
            padding: 0 1rem;
        }

        &__image {
            width: 100%;
        }

        &__info {
            width: 100%;
            padding: 1rem;
        }
    }
}