.c-button {
    @apply bg-black-gray text-white font-medium text-sm;
    border-radius: 30px;
    min-height: 36px;
    max-height: fit-content;
    padding: 4px 24px;
    min-width: fit-content;



    svg {
        @apply inline-block align-middle;
        height: 22px;
        width: 22px;
    }

    &.-table-action {
        min-height: 24px !important;
        max-height: 24px;
    }

    &.-secondary {
        @apply bg-transparent text-black-gray border-2 border-solid border-black-gray;
    }

    &.-white {
        @apply bg-white text-black-gray;
    }

    &.-outline-light {
        @apply bg-transparent text-white border-2 border-white;
    }

    &.-outline {
        @apply bg-transparent text-black-gray border-2 border-black-gray;
    }

    &.-blue {
        @apply bg-blue-medium text-black-gray;
    }

    &:disabled {
        @apply bg-gray text-black-gray-50;
    }

    &.-disabled-icon {
        @apply fill-black-gray-50;
    }
}

.-link {
    @apply inline-flex bg-transparent text-sm font-medium text-black-gray underline;
}

.-back {
    padding: 0px 7px;
}

.-back-text {
    padding: 0px 14px 0px 7px;
}