.c-badge {
    @apply bg-black-gray text-white;

    display: flex;
    height: 24px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: fit-content;
    padding: 6px 8px;
    border-radius: 4px;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
}