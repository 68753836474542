.loading-spinner {
    @apply flex justify-center items-center absolute opacity-0 transition-all pointer-events-none h-full w-full z-10;
    flex-direction: column;
    top: 0;
    left: 0;
    @apply opacity-100;
    
    &__content {
        @apply animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray;
        top: calc(50% - 64px);
        left: calc(50% - 64px);
    }

    .loading-title {
        margin: 15px 15px 15px 15px;
        color: gray;
    }
}