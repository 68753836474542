.c-fullscreen-video {
    display: flex;
    flex-direction: row;
    height: auto;
    flex-wrap: wrap;

    .video-react {
        .video-react-video {
            object-fit: cover;
            left: 1px !important;
        }

        .video-react-big-play-button {
            visibility: hidden;
        }
    }

    &__video-instruction {
        margin-top: 5px;
        padding-left: calc(var(--ion-safe-area-left, 0) + 16px);
        padding-right: calc(var(--ion-safe-area-right, 0) + 16px);
        width: 40%;
        margin-left: 10%;
        margin-top: 2.5%;

        & ul {
            list-style: disc !important;
        }

        & li {
            @apply text-16 font-light;
            padding: 5px;
            line-height: 24px;
            color: var(--ion-color-main);
            letter-spacing: 0.5px;
            justify-content: left;
            margin-right: 12px;
        }
    }

    .video-react.video-react-fluid,
    .video-react.video-react-16-9,
    .video-react.video-react-4-3 {
        padding-top: 41.5% !important;
        width: 43% !important;
        max-width: 100% !important;
        height: 0 !important;
        margin-left: 2rem;
        margin-top: 2rem;
    }
}


@media only screen and (max-width: 767.98px) {
    .c-fullscreen-video {
        &__video-instruction {
            width: 100%;

            & li {
                @apply text-12;
                line-height: 16px;
            }
        }

        .video-react.video-react-fluid,
        .video-react.video-react-16-9,
        .video-react.video-react-4-3 {
            width: 100% !important;
            margin: 0 !important;
            padding-top: 100% !important;
        }
    }
}