.c-sort-icons {
    @apply h-full inline-block align-bottom pl-1.5 pr-3 cursor-pointer;

    &__icon {
        height: 14px;
        width: 14px;
        stroke-width: 3.3px;
        color: #12162266;

        &.-active {
            @apply text-black-gray;
        }
    }
}