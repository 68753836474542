.c-day-input-option {
    position: relative;
    margin-right: 5px;
    margin-left: 8px;

    .__radio {
        display: inline-block;
        width: 50px;
        padding: 10px;
        border: solid 2px #ccc;
        transition: all 0.3s;
        text-align: center;
        border-radius: 50%;
    }

    input[type="radio"]:checked+label {
        @apply border-2 border-solid border-black-gray bg-black-gray text-white;
    }

    input[type="radio"] {
        display: none;
    }

    ion-label {
        @apply font-bold;
        font-size: 15px;
        z-index: 2;
        color: var(--ion-color-main);
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 55px;
    }

    ion-radio {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        z-index: 1;
        margin-bottom: 8px;
        --color: var(--ion-color-main-contrast);
        --color-checked: rgba(var(--ion-color-main-rgb), 0.05);
        --border-color: rgba(var(--ion-color-main-rgb), 0.05);
        --border-width: 1px;
        background-color: rgba(var(--ion-color-main-rgb), 0.05);
        --border-style: none;
    }

    ion-radio.radio-checked::part(mark) {
        background-color: var(--ion-color-main);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        transform: none;
    }

    .radio-checked+ion-label {
        color: rgba(var(--ion-color-main-contrast-rgb), 0.8);
        border: none;
    }
}