.c-save-payment-form {
    &__modal-title-container {
        padding-bottom: 2rem;

        h3 {
            line-height: 2.5rem !important;
        }
    }

    &__actions {
        @apply mt-3 text-right;
        min-height: 3rem;

        &__cancel {
            @apply inline-flex px-4 py-2 bg-white text-base font-medium text-black-gray underline;
        }

        &__submit {
            margin: 0 !important
        }

    }
}