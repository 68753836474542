.app-content-container {
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #C4C4C4;
    padding: 1.5rem;
    width: 100%;

    iframe {
        --video-width: 600px;
        width: var(--video-width);
        height: calc(var(--video-width)/2);
        max-width: 100%;
    }

    .embedded-video {
        margin: 0 auto;
    }

    .app-content-header {
        text-align: center;
        margin: 1.5rem 0;
    }

    .app-content-button {
        @apply bg-black-gray text-sm font-medium text-white;
        display: flex;
        align-items: center;
        margin: 1.5rem auto 0 auto;
        min-height: 36px;
        max-height: 36px;
        min-width: auto;
        padding: 0px 24px;
        border-radius: 30px;
        text-decoration: none !important;
    }

    a {
        color: #06c;
        text-decoration: underline;
    }
}