.pending-users-content {
    padding-top: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
}

.c-report {
    // The font is pragmatica-extends which is set as the default in the tailwind.config.js file.
    @apply font-sans text-black-gray w-full h-full px-10 sm:px-6 md:px-8;

    &.-assessment {
        .c-report__focus__item__name {
            @apply inline-block font-bold;
        }

        .c-report__focus__wrapper {
            @apply mt-4;
        }

        .c-report__history__item.not-available {
            .c-report__history__item__score {
                @apply text-24;
            }
        }
    }

    &.-compliance {
        hr {
            @apply border-black-gray-5;
            height: 1px;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        .c-donut-chart {
            margin-left: 12.5%;

            &__percent {
                @apply fill-blue-dark;
            }

            &__overall {
                @apply fill-black-gray-5;
            }

            &__inner__score {
                @apply text-32;
            }
        }

        .email {
            @apply font-light text-12;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .sub-header {
            p {
                @apply text-xs;
            }
        }
    }

    &.-detail {
        .c-report__header__wrapper {
            @apply grid-cols-3;
        }

        .sub-header {
            p {
                @apply text-xs;
            }
        }
    }

    &.-score {
        .c-report__header__wrapper {
            @apply grid-cols-3;
        }

        .sub-header {
            p {
                @apply text-xs;
            }
        }
    }

    &.-movement {
        .c-report__header__wrapper {
            @apply grid-cols-3;
        }

        .sub-header {
            p {
                @apply text-xs;
            }
        }
    }

    &.-summary {
        padding-bottom: 0;

        hr {
            @apply border-black-gray-5;
            height: 1px;
        }

        .c-report__focus__item__count {
            @apply inline-block font-bold;
            width: 2rem;
        }

        .c-report__focus__item__name {
            @apply inline-block;
        }

        .c-report__focus__wrapper {
            @apply mt-2;
        }

        .c-report__focus.c-report__section {
            @apply flex justify-between mt-6;

        }

        .sub-header {
            p {
                @apply text-xs;
            }
        }
    }

    h1 {
        @apply text-lg font-bold inline-block;
    }

    h2 {
        @apply text-xl font-light;
    }

    hr {
        @apply mt-4 border border-solid border-black-gray;

        margin-bottom: -0.5rem;
    }

    .c-bar-chart {
        @apply mt-3;

        &__bar {
            &__axis {
                text {
                    @apply text-black-gray text-xs font-bold;
                }
            }

            &__background {
                fill: #DEDEDE;
            }

            &__label {
                @apply text-black-gray text-xs font-bold;
            }

            &__value {
                @apply fill-black;
            }
        }
    }

    .c-donut-chart {
        @apply w-3/4;

        &__score {
            @apply font-bold text-3xl;

            &:after {
                @apply font-bold text-3xl;

                content: '%';
            }
        }
    }

    &__logo {
        width: 130px;
    }

    &__activity {
        &__wrapper {
            @apply grid grid-cols-3 gap-3;
        }
    }

    &__current {
        &__item {

            &__group {
                @apply text-xs uppercase;

                +.c-report__current__item__group {
                    @apply mt-2;
                }

                &.--pain {
                    span {
                        @apply text-red-dark;
                    }
                }
            }

            &__scale {
                @apply w-full h-3 bg-gray rounded-lg overflow-hidden mt-1;

                >* {
                    @apply h-full bg-black-gray;
                }
            }

            &.average {
                @apply p-4 rounded-lg h-full bg-blue-light;

                p:first-child {
                    @apply font-bold;
                }
            }

            span {
                @apply font-bold;
            }

            p {
                @apply text-xs;
            }

            &.indicator {
                @apply h-2.5 m-0 mb-1 border-r-2 border-solid border-r-blue-dark bg-transparent;

            }

            &.indicator-text {
                @apply text-right text-blue-dark;

                font-size: 0.6rem;
            }
        }

        &__inner {
            @apply col-span-2 grid grid-cols-2 gap-x-6 gap-y-4 items-center;
        }

        &__wrapper {
            @apply grid grid-cols-3 gap-6 items-center my-4;

            &.start {
                @apply items-start;
            }
        }

        .c-donut-chart {
            @apply mt-0 w-full;

            &__inner {
                padding-left: 10%;
                padding-right: 10%;
            }

            &__percent {
                fill: black;
            }

            &__overall {
                fill: rgb(229, 231, 235);
            }

            &__title {
                @apply font-light text-sm;
            }

            &__score {
                &:after {
                    content: '';
                }
            }
        }
    }

    &__header {
        &__item {
            @apply text-xs my-1;
        }

        &__type {
            @apply text-sm float-right mt-2;
        }

        &__wrapper {
            @apply grid grid-cols-2 gap-3 my-2;
        }

        &__wellness {
            @apply text-white bg-black-gray;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            padding: 1rem 2.5rem;
        }
    }

    &__focus {
        &__item {
            &__icon-container {
                @apply w-12 h-12 p-2 rounded-full;
                min-width: 3rem;
                max-width: 3rem;
                min-height: 3rem;
                max-height: 3rem;

                overflow: hidden;

                &.-movement-health {
                    @apply bg-blue-medium;
                }

                &.-nutrition-awareness {
                    @apply bg-green-medium;
                }

                &.-behavioral-health {
                    @apply bg-green-medium;
                }

                &.-body-composition {
                    @apply bg-orange;
                }

                &.-breathing-quality {
                    @apply bg-blue-medium;
                }

                &.-physical-activity {
                    @apply bg-red-medium;
                }

                &.-sleep-wellness {
                    @apply bg-purple-medium;
                }

                &.-injury-history {
                    @apply bg-red-medium;
                }
            }

            &__description {
                @apply inline-block font-light text-sm;
            }

            &__wrapper {
                @apply grid grid-cols-2 gap-x-6;

                grid-template-columns: auto auto;
            }

            &__name {
                @apply text-xs font-light;
            }
        }

        &__wrapper {
            @apply grid grid-cols-2 gap-x-8 gap-y-1;
        }
    }

    &__history {
        &__item {
            @apply flex-auto text-center px-1 py-2 rounded-lg;
            border: 2px solid rgba(0, 0, 0, 0.05);
            max-width: 3.9rem;
            min-width: 3.9rem;

            &:first-child {
                @apply border-2 border-solid border-transparent;
                background-color: rgba(0, 0, 0, 0.05);
            }

            // &.neutral {
            //     background-color: #FFF4E4;
            // }

            // &.positive {
            //     background-color: #DDEDEA;
            // }

            // &.negative {
            //     background-color: #FFE9E5;
            // }

            &.current {
                @apply rounded-lg;

                background-color: rgba(18, 22, 34, 0.08);
            }

            &.not-available {
                .c-report__history__item__score {
                    @apply text-gray;
                }
            }

            &__date {
                font-size: 9px;
            }

            &__score {
                @apply font-bold;
            }
        }

        &__wrapper {
            @apply flex my-4 gap-x-3;
        }
    }

    &__risk {
        &__item {
            @apply p-3 rounded-xl;

            &.health {
                @apply bg-red-medium;
            }

            &.wellness {
                @apply bg-yellow-medium;
            }

            &.fitness {
                @apply bg-blue-medium;
            }

            &.performance {
                @apply bg-green-medium;
            }

            &__count {
                @apply text-3xl font-extrabold mb-1;
            }

            &__name {
                p {
                    @apply text-sm font-bold;
                }
            }

            &__description {
                p {
                    @apply text-10 font-light;

                    line-height: 1rem;
                }
            }
        }

        &__wrapper {
            @apply grid grid-cols-4 gap-3 mt-4;
        }
    }

    &__score-summary {
        &__name {
            @apply px-4 py-2 text-xs text-center;
        }
    }

    &__section {
        @apply mt-4;
    }

    &__statuses {
        &__item {
            @apply p-4 text-center border-2 border-solid rounded-lg;
            border-color: rgba(0, 0, 0, 0.05);

            &__count {
                @apply font-bold text-4xl;
            }

            &__name {
                @apply font-medium mt-2 text-10;
                letter-spacing: 0.35px;
            }

            &:first-child {
                background-color: #F7F9FC;
                border: none;
            }
        }

        &__wrapper {
            @apply grid grid-cols-4 gap-3 mt-4;
        }

    }

    &__table {
        @apply w-full text-xs;

        thead {
            @apply font-bold;
        }

        tbody {
            tr {
                @apply border-t border-solid border-t-black-gray-5;
            }
        }

        tr {
            height: 32px;
        }

        .-highlighted {
            @apply bg-blue-light;
        }

        .-warning {
            @apply bg-red-light;
        }

        .center {
            text-align: center;
        }
    }

    &__user-list {
        p {
            @apply text-xs;
        }

        &__wrapper {
            @apply grid grid-cols-3 gap-y-4 gap-x-3 mt-4;
        }
    }

    &.-detail {
        .c-report__table {
            thead {
                tr {
                    td {
                        &:last-child {
                            width: 25%;
                        }
                    }
                }
            }
        }
    }

    &.-score {
        .c-report__table {
            thead {
                tr {
                    font-size: 0.5rem;
                }
            }

            tbody {
                tr {
                    td {
                        @apply font-light;

                        &:first-child {
                            @apply font-normal;
                        }

                        &:last-child {
                            @apply font-bold;
                        }
                    }
                }
            }

            tr {
                td {
                    &:not(:first-child) {
                        text-align: center;
                        width: 65px;
                    }
                }
            }
        }
    }

    &.-movement {
        .c-report__table {
            thead {
                tr {
                    font-size: 0.5rem;
                }
            }

            tbody {
                tr {
                    td {
                        @apply font-light;

                        &:first-child {
                            @apply font-normal;
                        }

                        &:last-child {
                            @apply font-bold;
                        }
                    }
                }
            }

            tr {
                td {
                    &:not(:first-child) {
                        text-align: center;
                        width: 70px;
                    }

                    &:last-child {
                        text-align: center;
                        width: 85px;
                    }
                }
            }
        }
    }

    &.-assessment {

        hr {
            @apply mt-4;
        }

        h2 {
            @apply font-light text-16;

        }

        .c-report__header+hr {
            @apply hidden;
        }

        p {
            @apply font-light my-2;

            font-size: 0.6rem;
            line-height: 15px;
        }

        .c-report {

            &__header {
                &__item {
                    @apply font-light;

                    font-size: 0.6rem;
                }
            }

            &__current__item__group {
                @apply font-light;

                font-size: 0.6rem;

                &__pain {
                    @apply font-bold;
                    @apply text-red-dark;
                    padding-left: 10px;
                }
            }

            &__focus__wrapper {
                row-gap: 1rem;
                page-break-after: always;
            }

            &__focus__item__name {
                @apply text-xs font-medium;
            }

            &__focus__item__description {
                @apply font-light;

                font-size: 0.6rem;
                line-height: 15px;
            }
        }
    }

    &.-wellness {
        h1 {
            @apply font-light text-20;
            line-height: 2.25rem;
        }

        h2 {
            @apply font-light text-16;
            line-height: 1.75rem;
        }

        p {
            @apply font-light;
            font-size: 0.7rem;
            line-height: 14px;
        }

        hr {
            @apply border border-solid border-black-gray-5;
            margin-top: 34px;
            margin-bottom: 24px;
        }

        .text-05 {
            font-size: 0.55rem;
            line-height: 10px;
        }

        .text-06 {
            font-size: 0.6rem;
            line-height: 12px;
        }

        .text-07 {
            font-size: 0.7rem;
            line-height: 14px;
        }

        .text-08 {
            font-size: 0.8rem;
            line-height: 16px;
        }

        .red-text {
            @apply text-red-dark;
        }

        .green-text {
            color: #00C495;
        }

        .background-gray-blue {
            background-color: rgba(247, 249, 252, 1);
        }

        .background-red {
            @apply bg-red-medium;
        }

        .background-orange {
            @apply bg-orange;
        }

        .background-green {
            @apply bg-green-medium;
        }

        .background-blue {
            @apply bg-blue-light;
        }

        .background-light-blue {
            @apply bg-blue-medium;
        }

        .border-gray-blue {
            border-color: rgba(247, 249, 252, 1);
        }

        .pain-badge {
            @apply text-red-dark bg-red-light font-medium;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 0.25rem;
            font-size: 0.5rem;
            text-transform: uppercase;
            line-height: normal;
            padding: 0.25rem 0.5rem;
            width: fit-content;
        }

        .discomfort-badge {
            @apply text-black-gray bg-yellow-medium font-medium;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 0.25rem;
            font-size: 0.5rem;
            text-transform: uppercase;
            line-height: normal;
            padding: 0.25rem 0.5rem;
            width: fit-content;
        }

        .chart-badge {
            @apply font-bold;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 0.25rem;
            font-size: 0.6rem;
            text-transform: uppercase;
            line-height: normal;
            padding: 0.25rem 0.5rem;
            width: fit-content;
            min-width: 3.5rem;
            gap: 3px;

            &.green {
                @apply bg-green-medium;
            }

            &.red {
                @apply bg-red-medium;
            }

            &.gray {
                @apply bg-gray-light;
            }
        }

        .wellness-score-donut-chart-container {
            display: flex;
            flex-direction: column;
            align-self: center;
            align-items: center;
            width: 190px;

            .c-donut-chart {
                width: 100%;
            }

            .c-donut-chart__inner__score {
                @apply text-32;
                line-height: normal;
            }

            .c-donut-chart__inner__title {
                font-size: 0.6rem;
                line-height: normal;
            }

            .c-donut-chart__inner__badge {
                font-size: 0.5rem;
                line-height: normal;
                padding: 0.25rem 0.5rem;
                margin-top: 0.5rem;
            }
        }

        table.assessment-breakdown {
            tr {
                @apply border-b-2 border-b-black-gray border-opacity-5
            }
        }

        .icon {
            svg {
                max-width: 100%;
                max-height: 100%;
            }

            &__40px {
                display: block;
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px;
            }

            &__36px {
                display: block;
                width: 36px;
                height: 36px;
                min-width: 36px;
                min-height: 36px;
                max-width: 36px;
                max-height: 36px;
            }

            &__32px {
                display: block;
                width: 32px;
                height: 32px;
                min-width: 32px;
                min-height: 32px;
                max-width: 32px;
                max-height: 32px;
            }

            &__28px {
                display: block;
                width: 28px;
                height: 28px;
                min-width: 28px;
                min-height: 28px;
                max-width: 28px;
                max-height: 28px;
            }

            &__24px {
                display: block;
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                max-width: 24px;
                max-height: 24px;
            }

            &__16px {
                display: block;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                max-width: 16px;
                max-height: 16px;
            }

            &__12px {
                display: block;
                width: 12px;
                height: 12px;
                min-width: 12px;
                min-height: 12px;
                max-width: 12px;
                max-height: 12px;
            }

            &__8px {
                display: block;
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
                max-width: 8px;
                max-height: 8px;
            }

            &__6px {
                display: block;
                width: 6px;
                height: 6px;
                min-width: 6px;
                min-height: 6px;
                max-width: 6px;
                max-height: 6px;
            }
        }

        .checkmark-icon {
            circle {
                fill: #00C495;
            }

            path {
                @apply fill-white;
            }
        }

        .exclamation-icon {
            path {
                @apply fill-red-dark;
            }
        }

        .yellow-exclamation-icon {
            path {
                @apply fill-yellow-medium;
            }
        }

        .qr-code-container {
            @apply bg-white;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            min-height: 120px;
            max-width: 120px;
            max-height: 120px;
            gap: 0.5rem;
            padding: 0.5rem;
            border-radius: 1rem;
        }

        .qr-code-wrapper {
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
        }

        .mobile-stores {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }

        .app-store-button {
            height: 35px;
        }

        .google-play-button {
            height: 35px;
        }
    }

    &__assessment-score-card {
        @apply bg-white;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 0.5rem;
        border-radius: 0.5rem;

        .green {
            color: #00C495;
        }

        .red {
            @apply text-red-dark;
        }
    }

    &__exercise-card {
        @apply bg-white;
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        align-items: center;
        padding: 0.75rem;
        border-radius: 10px;
        width: 48.5%;
    }

    &__card-number {
        @apply text-12 font-bold;
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 40px;

        &.red {
            @apply bg-red-dark text-white;
        }

        &.blue {
            @apply bg-blue-medium text-black-gray;
        }
    }

    &__review {
        @apply grid grid-cols-2 gap-x-6;

        grid-template-columns: auto auto;

        &__icon {
            @apply text-xs font-medium uppercase;

            &.text {
                @apply rounded-lg h-auto text-center p-2 w-full;

                background-color: rgba(18, 22, 34, 0.08);
                font-size: 0.6rem;
            }
        }

        &__description {
            @apply pl-2 font-light;

            font-size: 0.6rem;
        }
    }
}