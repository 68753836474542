.c-modal {
    @apply fixed z-30 inset-0 overflow-y-auto;

    &__actions {
        @apply mt-6 text-right;

        &__send {
            @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-light text-base font-medium text-white hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light sm:col-start-2 sm:text-sm;
        }

        &__cancel {
            @apply mt-3 mr-6 inline-flex px-4 py-2 bg-white text-base font-medium text-black-gray underline;
        }

        &__delete {
            @apply font-bold mt-3 inline-flex pl-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
        }
    }

    &__content {
        @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
    }

    &__header {
        @apply flex w-full;

        flex-flow: wrap;
        justify-content: space-between;

        &__container-close {
            display: flex;
            justify-content: right;
            width: 100%
        }

        &__close {
            @apply items-center justify-center text-center cursor-pointer;

            svg {
                @apply h-6 w-6 text-black-gray ml-auto;
            }
        }

        &__title {
            @apply mt-3 mb-3 text-center sm:mt-5;

            h3 {
                @apply text-4xl font-light text-center;
            }
        }
    }

    &__search {
        @apply mb-4 mt-6;

        button {
            @apply hidden;
        }

        input {
            @apply w-full;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }

    &__overlay {
        @apply fixed inset-0 bg-black-gray bg-opacity-50 transition-opacity;
    }

    &__header {
        padding: 2.5rem 2.5rem 0rem 2.5rem;
    }

    &__children {
        padding: 0rem 2.5rem 2.5rem 2.5rem;
    }

    &__modal-actions {
        @apply bg-black-gray-5;
        padding: 1rem 2.5rem 1rem 2.5rem;
    }

    &__center-modal-actions {
        padding: 0rem 2.5rem 2.5rem 2.5rem;
    }

    &__default-children {
        padding: 0rem 2.5rem 1rem 2.5rem;
    }

    &__default-center-children {
        display: flex;
        padding: 0rem 2.5rem 1rem 2.5rem;
        justify-content: center;
        text-align: center;
    }

    &__wrapper {
        @apply inline-block w-full align-bottom bg-white rounded-3xl text-left shadow-xl transform transition-all sm:align-middle sm:w-3/4 md:w-4/6 xl:w-3/5;

        .header-center {
            justify-content: center;
        }

        &.-delete {
            @apply sm:w-1/4;
        }

        label {
            @apply text-base font-bold mb-3 block;
        }

        hr {
            @apply border-gray my-10;
            width: calc(100% + 3rem);
            margin-left: -1.5rem;
        }

        textarea,
        input {
            @apply block border border-gray rounded-3xl text-base leading-6 py-2.5 px-5;
        }

        .c-email-list__control {
            @apply rounded-3xl;
        }

        .c-email-list__value-container {
            padding: 0.4rem 1.25rem;
        }

        .c-email-list__indicator-separator {
            @apply hidden;
        }
    }

    h4 {
        @apply text-black-gray font-normal text-lg mb-2;
    }

    h6 {
        @apply text-black-gray mt-10 mb-4 font-bold text-base;
    }
}

.c-qr-modal {

    @apply fixed z-10 inset-0 overflow-y-auto;

    &__actions {
        @apply mt-6 text-right;

        &__send {
            @apply inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-light text-base font-medium text-white hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light sm:col-start-2 sm:text-sm;
        }

        &__cancel {
            @apply mt-3 mr-6 inline-flex px-4 py-2 bg-white text-base font-medium text-black-gray underline;
        }

        &__delete {
            @apply font-bold mt-3 inline-flex pl-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
        }
    }

    &__content {
        @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
    }

    &__header {
        @apply inline-block w-full;
        height: 1.5rem;
        margin-top: 1rem;

        &__close {
            @apply mx-auto h-12 w-12 items-center justify-center text-center float-right cursor-pointer;

            svg {
                @apply h-6 w-6 text-black-gray ml-auto;
            }
        }

        &__title {
            @apply mt-3 mb-3 text-center sm:mt-5;

            h3 {
                @apply text-4xl font-light leading-6 text-left;
            }
        }
    }

    &__search {
        @apply mb-4 mt-6;

        button {
            @apply hidden;
        }

        input {
            @apply w-full;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }

    &__overlay {
        @apply fixed inset-0 bg-gray bg-opacity-75 transition-opacity;
    }

    &__header {
        padding-left: 3rem;
        padding-right: 1rem;
    }

    &__children {
        padding-left: 3rem;
        padding-right: 1rem;
    }

    &__modal-actions {
        @apply bg-black-gray-5;
        padding: 1rem 2.5rem 1rem 2.5rem;
    }

    &__center-modal-actions {
        padding: 0rem 2.5rem 2.5rem 2.5rem;
    }

    &__wrapper {
        @apply inline-block align-bottom bg-white rounded-3xl text-left shadow-xl transform transition-all sm:align-middle;
        width: 650px;
        height: 295px;

        .header-center {
            justify-content: center;
        }

        &.-delete {
            @apply sm:w-1/4;
        }

        label {
            @apply text-base font-bold mb-3 block;
        }

        hr {
            @apply border-gray my-10;
            width: calc(100% + 3rem);
            margin-left: -1.5rem;
        }

        textarea,
        input {
            @apply block border border-gray rounded-3xl text-base leading-6 py-2.5 px-5;
        }

        input {
            @apply w-1/2;
        }

        .c-email-list__control {
            @apply rounded-3xl;
        }

        .c-email-list__value-container {
            padding: 0.4rem 1.25rem;
        }

        .c-email-list__indicator-separator {
            @apply hidden;
        }
    }

    h6 {
        @apply text-black-gray mt-10 mb-4 font-bold text-base;
    }

}

@media only screen and (max-width: 767.98px) {
    .c-modal {
        &__header {
            padding: 1rem 1rem 0rem 1rem;
        }

        &__children {
            padding: 0rem 1rem 1rem 1rem;
        }

        &__modal-actions {
            @apply bg-black-gray-5;
            padding: 1rem 1rem 1rem 1rem;
        }

        &__center-modal-actions {
            padding: 0rem 1rem 1rem 1rem;
        }

        &__wrapper {

            .header-center {
                justify-content: center;
            }

            input {
                width: 100%;
            }
        }
    }
}