.p-reset-password {

    @apply grid grid-cols-1 auto-rows-min;
    width: 100%;
    margin: 0 20px 0 20px;
    justify-content: center;

    &__email-error,
    &__password-error {
        @apply bg-red-light;
        margin: auto;
        width: 80%;
        border-radius: 10px;
        padding: 24px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        h4 {
            @apply text-red-dark text-14 font-bold;
            line-height: 20px;
        }

        p {
            @apply text-14 font-light;
            line-height: 22px;
        }
    }

    &__container {
        width: 100%;
        padding-top: 50px;
        @apply flex justify-center;
    }

    img {
        @apply mx-auto;
        max-width: 358px;
        height: 126px;
        margin-top: 8rem;
        margin-bottom: 23px;
    }

    &__title {
        @apply font-medium;
        text-align: center;
        size: 14px;
        margin-bottom: 17px;
        line-height: 24px;
    }

    &__subtitle {
        @apply font-light;
        text-align: center;
        size: 14px;
        margin-bottom: 23px;
        line-height: 24px;
    }

    &__field-container {
        width: 360px;
        margin: auto;
    }

    &__disabled {
        opacity: 50%;
    }
}

.login-button {
    @apply bg-black-gray text-white rounded-full;
    justify-content: center;
    height: 50px;
    width: 187px;
    margin-top: 18px;
}

.forgot-password {
    @apply text-14;
    display: flex;
    margin-top: 25px;
    line-height: 22px;
    text-decoration-line: underline;
    justify-content: right;
    cursor: pointer;
}

.cancel-forgot-password {
    @apply text-14;
    display: flex;
    margin-top: 25px;
    line-height: 22px;
    text-decoration-line: underline;
    justify-content: center;
    cursor: pointer;
}

.login-form {
    max-width: 440px;
}