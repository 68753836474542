.c-base-card-layout {

    &.-showerror {
        @apply border border-solid border-red-dark;
    }

    &.-error {
        @apply text-12;
        color: red;
        opacity: 0.6;
        display: block;
        margin-bottom: 0.5em;
    }

    &__modal-container {
        display: flex;
        flex-direction: row;
    }

    &__modal-qr-code {
        width: 40%
    }

    &__modal-info-container {
        display: flex;
        flex-direction: column;
        width: 55%;
    }

    &__qr-code {
        width: 200px;
        height: 200px;
    }

    &__modal-h4 {
        @apply text-16 font-bold;
        line-height: 30px;
        margin-bottom: 0.5rem;
    }

    &__modal-p {
        @apply text-14 font-light;
        line-height: 24px;
    }

    &__modal-h5 {
        @apply text-14 font-bold;
        line-height: 24px;
        margin: auto;
        margin-left: 1rem;
        margin-right: 4rem;
    }

    &__modal-bottom-info {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
    }

    &__modal-symmio-icon {
        width: 40px;
        height: 40px;
    }

    &__modal-android-store-image {
        width: 134px;
        height: 40px;
    }

    &__modal-ios-store-image {
        width: 120px;
        height: 40px;
    }

    &.page-container {
        @apply bg-black-gray-5;
        width: 100%;
        height: fit-content;
        min-height: 100vh;
        padding-top: 2rem;
    }

    &__card-content-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 40px;
        background-color: white;
        width: 980px;
        max-width: 100vw;
        min-height: 700px;
        height: auto;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    &__promo-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-left: 5rem;
        margin-right: 5rem;
    }

    &__promo-h3-header {
        @apply font-light text-26;
        line-height: 44px;
        text-align: center;
        padding-right: 5rem;
        padding-left: 5rem;
    }

    &__promo-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }

    &__password-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        margin-top: 1.5rem;
    }

    &__promo-h4-header {
        @apply text-20 font-light;
        line-height: 32px;
    }

    &__promo-info-section {
        display: flex;
        flex-direction: row;

        p {
            @apply text-16 font-light;
            line-height: 26px;
        }

        .margin-left {
            margin: auto;
            margin-left: 6rem;
            text-align: left;
        }

        .margin-right {
            margin: auto;
            margin-right: 6rem;
            text-align: left;
        }
    }

    &__promo-image {
        width: 294px;
        height: 294px;
        margin: auto;
    }

    &__promo-reviews-container {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        gap: 1rem;
    }

    &__promo-review-stack {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__promo-review {
        @apply bg-black-gray-5;
        border-radius: 10px;
        padding: 2rem;
        text-align: left;
        width: 100%;

        h4 {
            @apply text-14 font-bold;
            line-height: 22px;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        p {
            @apply text-14 font-light;
            line-height: 22px;
        }
    }

    &__promo-intro {
        @apply text-16 font-light;
        line-height: 26px;
        width: 80%;
        margin: auto;
        text-align: center;
        padding-right: 5rem;
        padding-left: 5rem;

        strong {
            @apply font-bold;
        }
    }

    .app-store-button {
        max-width: 120px;
        max-height: 40px;
        min-width: 120px;
        min-height: 40px;
    }

    .google-play-button {
        max-width: 134px;
        max-height: 40px;
        min-width: 134px;
        min-height: 40px;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        justify-self: left;
        column-gap: 1.5rem;
        margin-bottom: 1rem;

        img {
            width: 28%;
        }
    }

    &__create-password-container {
        justify-content: center;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: center;

        .field {
            width: 100%;
            justify-content: center;
            display: flex;
        }

        &-header {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 50%;
            text-align: center;
        }

        h3 {
            @apply text-16 font-bold;
            line-height: 30px;
            text-align: center;
        }

        p {
            @apply text-14 font-light;
            line-height: 24px;
            text-align: center;
        }
    }

    &__symmio-home-button-container {
        display: flex;
        justify-content: right;
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 1.5rem;
        border-top: 1px solid rgba(18, 22, 34, 0.20);

        button {
            margin-top: 1.5rem;
            margin-right: 1.5rem;
        }
    }

    &__mobile-app-info {
        margin: 4rem 0;
    }

    &__mobile-stores-container {
        @apply bg-black-gray-5;

        display: flex;
        padding: 40px 0px;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        border-radius: 20px;
        margin-top: 2.5rem;

        .info-container {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            gap: 2.5rem;
        }

        .stores-bottom-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;

            .stores-symmio-icon {
                width: 56px;
                height: 56px;
            }

            .stores-h5 {
                @apply text-black-gray text-16 font-bold;
                text-align: center;
                font-style: normal;
                line-height: 26px;
            }
        }

        .symmio-icon-container {
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-base-card-layout {
        &__card-content-container {
            margin-top: 0;
            border-radius: 0;
        }

        &__promo-h3-header {
            padding: 0;
        }

        &__promo-intro {
            padding: 0;
        }

        &__mobile-stores-container {
            margin-right: 0;
            margin-left: 0;
        }

        &__symmio-home-button-container {
            button {
                margin-right: 1rem;
            }
        }

        &__promo-container {
            margin: 0;
        }

        &__promo-reviews-container {
            gap: 1rem;
            flex-wrap: wrap;
        }

        &__promo-review-stack {
            width: 100%;
        }

        &__promo-review {
            text-align: center;
        }

        &__promo-info-section {
            flex-wrap: wrap;
            gap: 1rem;

            &.right {
                flex-wrap: wrap-reverse;
            }
        }

        // >div:first-child {
        //     max-width: 100vw !important;
        // }

        &.page-container {
            @apply bg-white;
            padding: 0;
            min-height: fit-content;
        }

        &__create-password-container {
            &-header {
                width: 100%;
            }

            button {
                width: 70%;
            }
        }

        &__mobile-stores-container {
            .stores-bottom-info {
                .stores-h5 {
                    width: 100%;
                }
            }
        }
    }

    .promo-text {
        margin: 0 0 3rem 0 !important;
        text-align: center !important;
    }

    .scroll-container {
        flex-wrap: wrap;
    }

    .c-screen-intro {
        &.scroll-container {
            padding: 1rem;
        }
    }
}