.c-billing {
    .plan-card-header {
        @apply text-26 font-light;
        margin-bottom: 1rem;
    }

    &__header {
        @apply flex justify-between mb-5;
    }

    &__custom-payment-message {
        text-align: left;
    }

    &__payment-method-actions {
        display: flex;
        flex-flow: wrap;
        align-items: baseline;
        justify-content: flex-end;
        margin-top: 0.75rem;
        text-align: right;

    }

    &__modal-wrapper {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &__modal-title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__modal-content {
        line-height: 26px;
        text-align: center;
    }

    &__modal-actions {
        margin-top: 30px;
        text-align: center;
    }

    h1 {
        margin-bottom: 0.5rem;
    }

    h3 {
        @apply mt-3 mb-3;
    }


    &__warning_header {
        @apply flex w-full;

        h5 {
            @apply font-bold mt-3;
            text-align: left !important;
        }

        &__icon {
            @apply w-5 h-5 mt-3 mx-3;
        }

        &__close {
            @apply items-center justify-center text-center cursor-pointer;
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            margin-left: auto;

            svg {
                @apply h-6 w-6 text-black-gray ml-auto;
                color: gray;
            }
        }
    }

    &__warning_label {
        display: flex;
        padding: 0.5rem;

        border-radius: 10px 10px 0px 0px;
        align-items: center;
        justify-content: center;
    }

    &__warning_content {
        @apply flex flex-col max-w-full w-full px-11;

        h5 {
            @apply font-light mt-3 pr-3;
            text-align: left !important;
        }

        &_button {
            @apply mt-3 mb-4;
        }
    }

    &__status {
        display: flex;
        flex-wrap: wrap;

        &__container {
            @apply flex flex-row flex-wrap w-full content-around justify-between gap-y-8;
        }

        .c-billing__status__item__wrapper {

            h6 {
                @apply mt-2 text-xs uppercase font-normal;
            }
        }

        &__header {
            min-height: 5.5rem;

            div:first-child {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                //align-items: center;
            }
        }

        &__main {
            height: 6.5rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        &__item {
            @apply font-bold;
            // font-size: 60px;
            line-height: 60px;

            &__wrapper {
                @apply text-center bg-black-gray-5 rounded-2xl;
                width: 48%;
                padding: 2rem;
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__icon-column {
        padding-left: 30 !important;
        padding: 1.5rem !important;
        width: 10%;
    }

    &__description-column {
        h1 {
            font-size: 14px !important;
            margin-top: 0.5rem;
            white-space: initial;
        }
    }

    &__price-column {
        padding-right: 30 !important;
        width: 20%;
    }

    &__action-column {
        width: 20%;
    }

    &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &__actions {
        @apply border-t border-solid border-t-black-gray-20;
        padding-top: 24px;
        display: flex;
        flex-flow: wrap;
        align-items: baseline;
        justify-content: space-between;
        margin-top: 0.75rem;
        text-align: right;

        &__cancel {
            @apply inline-flex px-4 py-2 bg-white text-sm font-medium text-black-gray underline;
        }
    }

    &__invoice-information-header {
        font-size: 20px !important;
        font-style: normal;
        font-weight: 300 !important;
        line-height: normal;
    }

    &__invoice-container {
        @apply bg-black-gray-5;
        padding: 1rem 2.5rem 1rem 2.5rem;
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__invoice {
        @apply border-b border-solid border-black-gray-20;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        padding: 1rem 0;

        &:last-child {
            border-bottom: none;
        }
    }

    &__invoice-information {
        display: flex;
        flex-flow: wrap;
        flex-grow: 1;
        justify-content: space-between;
        gap: 0.5rem;

        &-name {
            @apply text-14 font-bold;
            font-style: normal;
            line-height: 20px;
            min-width: 35%;
        }

        &-date,
        &-price {
            @apply text-14 font-light;
            font-style: normal;
            line-height: 20px;
        }
    }

    &__invoice-button-container {
        min-width: 30%;
        text-align: right;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-billing {
        &__warning_content {
            h5 {
                padding: 0;
            }

            &_button {
                width: 100%;
                text-align: center;
            }
        }

        &__status {
            h1 {
                width: auto;
                text-align: start !important;
            }

            &__header {
                div:first-child {
                    flex-direction: column !important;
                    align-items: flex-start;
                }
            }
        }

        &__actions {
            button {
                text-align: center;
            }
        }

        &__invoice {
            flex-direction: column;
            gap: 1rem;

            div {
                width: 100%;
            }
        }

        &__invoice-button-container {
            text-align: center;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-billing {
        &__invoice-information {
            div {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1199.98px) {
    .c-billing {
        &__status {
            &__item {
                &__wrapper {
                    width: 100%;
                }
            }
        }
    }
}