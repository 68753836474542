.c-screen-slide {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__actions-no-continue {
        width: 100%;
        align-self: flex-end;
        // position: absolute;
        // bottom: 6px;
    }

    &__actions {
        width: 100%;
        align-self: flex-end;
        // position: absolute;
        // bottom: 6px;
    }

    // &.-slider {
    //     display: unset;
    // }

    &__question {
        display: flex;
        flex-direction: column;
        // height: calc(100% - 57px); //57px is the height of the first half of the top slider
        height: 100%;

        &__content {
            height: 100%;
            display: flex;
            flex-flow: wrap;
        }
    }
}

// .c-screen-slide__lifestyle {
//     height: 72.7% !important;
// }

// .lifestyle-custom-options {
//     height: 80% !important;
// }

// .lifestyle-custom-options-physical-activities {
//     height: 77% !important;
// }