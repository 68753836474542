.c-tabs-container {
  @apply text-sm font-light;

  margin-top: 1.5rem;
  overflow: auto;

  &.round-tabs {
    @apply border-black-gray-20 border border-solid rounded;

    border-radius: 30px;

    .c-tabs {
      justify-content: space-between;
    }

    .c-tab-label.active {
      @apply bg-black-gray rounded-full font-medium;

      color: white;
    }
  }
}

.c-highlight-container {
  position: relative;
}

.c-subline {
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: #F3F3F4;
  width: 100%;
}

.c-tabs {
  display: flex;
  position: relative;
}

.c-tab-label {
  @apply text-black-gray-50;

  text-align: left;
  padding: 8px 20px;
  cursor: pointer;
  transition: color .3s ease-in-out;
  // margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .icon {
      svg {
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        min-width: 100%;
        height: 100%;
        width: 100%;
      }

      &__20px {
        display: block;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
}

.c-tab-label.active {
  color: black;
}

.c-highlight-line {
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: black;
  transition: all .3s ease-in-out;
}

.c-tab-label.active+.c-highlight-line {
  width: auto;
  left: 0;
  right: 0;
  margin: 0 0.5rem;
}