.c-email-list {
    &__input-container {
        grid-template-columns: max-content !important;
    }
    
    &__menu {
        @apply text-sm;
        z-index: 20;
    }
}
