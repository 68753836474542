.p-login {

    width: 358px;
    margin: 0 20px 0 20px;

    img {
        @apply mx-auto;
        max-width: 358px;
        height: 126px;
        margin-top: 10rem;
        margin-bottom: 39px;
    }
}

.login-button {
    @apply bg-black-gray text-white rounded-full;
    justify-content: center;
    height: 50px;
    width: 187px;
    margin-top: 32px;
}

.forgot-password {
    @apply text-14;
    display: flex;
    margin-top: 25px;
    line-height: 22px;
    text-decoration-line: underline;
    justify-content: right;
    cursor: pointer;
}

.login-form {
    max-width: 358px;
}