.tooltip {
    position: relative;
    text-decoration: underline;

    &:hover .tooltiptext {
        visibility: visible;
    }

    .tooltiptext {
        @apply text-white bg-black-gray;
        visibility: hidden;
        text-align: center;
        position: absolute;
        z-index: 1;
        min-width: 243px;
        padding: 1rem;
        border-radius: 10px;

        // Show tooltip on the top
        bottom: 100%;
        margin-left: -30px; // Use half of the width (120/2 = 60), to center the tooltip
    }
}