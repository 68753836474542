.c-qr-code-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    background-color: rgba(18, 22, 34, 0.05);
    padding: 2rem;
    border-radius: 20px;

    &__text {
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
        width: 66.6%;
    }

    &__symmio-logo {
        max-width: 56px;
        max-height: 56px;
        min-width: 56px;
        min-height: 56px;
    }

    &__symmio-logo-text {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
    }

    &__qr-code {
        max-width: 200px;
        max-height: 200px;
        min-width: 200px;
        min-height: 200px;
    }

    &__app-store {
        max-width: 120px;
        max-height: 40px;
        min-width: 120px;
        min-height: 40px;
    }

    &__google-play {
        max-width: 134px;
        max-height: 40px;
        min-width: 134px;
        min-height: 40px;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-qr-code-card {
        &__symmio-logo-text {
            width: 100%;
        }

        &__text {
            width: auto;
        }
    }
}