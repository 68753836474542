.c-slider-input-option {
    width: 100%;

    ion-label {
        @apply font-bold;
        font-size: 12px !important;
    }

    .MuiSlider-mark {
        display: none !important;
    }

    ion-range {
        --bar-height: 10px;
        --bar-border-radius: 5px;
        --bar-background: #c4c4c4;
        --bar-background-active: #121622;
        --knob-handle-size: 1.625rem;
        --knob-size: 1.625rem;
        --knob-border-radius: 50%;
        --knob-box-shadow: 0 0 0px 1px #121622;
        padding-bottom: 1rem;

        .range-knob {
            border: 1px solid red;
        }
    }

    &__input {
        width: 140px;
        text-align: center;
        border: 2px solid black;
        border-radius: 10px;

    }

    &__integer-options {
        font-size: 50px;
        width: 140px;
        height: 90px;
        top: 15px;
        margin: 0 auto;
        border-radius: 10px;
        overflow-x: visible;
        background: rgba(var(--ion-color-main-rgb), 0.05);

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        ion-input {
            text-align: center;
            font-size: 50px;
            line-height: 22px;
        }

        &.item-has-focus {
            border: 2px solid black;
        }
    }

    &__integer-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;

        span {
            @apply text-black text-24;
            position: absolute;
            right: calc(50% - 7rem);
            bottom: 5px;
        }
    }

    &__slider-wrapper {
        position: relative;
        width: 30%;
        margin: auto;
    }

    &__range-wrapper {
        position: absolute;
        bottom: 0;
        left: 0.75rem;
        right: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        >* {
            flex: auto;
        }

        ion-label+ion-label {
            text-align: right;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-slider-input-option {
        &__slider-wrapper {
            width: 60%;
        }
    }
}