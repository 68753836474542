.c-info-prompt {
    &__container {
        @apply border border-solid border-red-dark;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        margin: auto;
        height: 50px;
        width: fit-content;
        border-radius: 30px;
        text-align: center;
        padding: 0 19px 0 19px;
        overflow-x: visible;
    }

    &__question-mark {
        @apply text-14 font-medium;
        position: relative;
        bottom: 2px;
        border: 1px solid black;
        border-radius: 9999px;
        padding: 0 5px 0 5px;
        margin-right: 14px;
    }

    &__message {
        @apply text-14 font-medium;
        position: relative;
        bottom: 2px;
    }

    &__close-button {
        // display: inline-block;
        // line-height: 50px;
        position: relative;
        bottom: -3px;
        font-size: 1.75em;
        margin-left: 30px;
    }
}