.p-lead-capture-results {
    h1 {
        @apply font-light text-26;
        line-height: 49px;
        text-align: center;
    }

    h4 {
        @apply text-20 font-light;
        line-height: 30px;
    }

    p {
        @apply text-16 font-light;
        line-height: 26px;
    }

    strong {
        @apply font-medium;
    }

    header {
        text-align: center;
        width: 100%;
        padding: 5rem 6rem;
    }

    main {
        text-align: center;
        max-width: 100%;
        width: 100%;
    }

    footer {
        @apply bg-gray-light;
        text-align: center;
        padding: 4rem 6rem;
        max-width: 100%;
        width: 100%;
        border-radius: 0 0 40px 40px;
    }

    &__logo {
        height: 88px;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
            width: 201.74px;
            height: 40px;
        }
    }

    &__header {
        width: 100%;
        max-height: 1474.5px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__big-paragraph {
        @apply font-light text-26;
        line-height: 38px;
    }

    &__welcome-text-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        p {
            width: 100%;
        }
    }

    &__paragraph-header {
        @apply text-black-gray-50 text-14 font-bold;
        line-height: 26px;
        opacity: 70%;
        text-transform: uppercase;
    }

    &__video-container {
        background-color: rgba(16, 21, 27, 1);
        padding: 4rem 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        iframe {
            --video-width: 582px;
            width: var(--video-width);
            height: calc(var(--video-width)/2);
            max-width: 100%;

            &.embedded-video {
                margin: 0 auto;
            }
        }
    }

    &__video-wrapper {
        padding: 2rem 0;
    }

    &__cards-container {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        row-gap: 1.5rem;
        padding: 4rem 6rem;
    }

    &__card {
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: center;
        width: 100%;
        column-gap: 4rem;
        border-radius: 20px;
    }


    &__card-image {
        max-width: 380px;
        max-height: 294px;
        width: 380px;
        height: 294px;
    }

    &__card-text {
        display: flex;
        flex-flow: wrap;
        gap: 1rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .p-lead-capture-results {
        header {
            padding: 4rem 1rem;
        }

        footer {
            padding: 4rem 1rem;
        }

        &__video-container {
            padding: 4rem 1rem;
        }

        &__cards-container {
            row-gap: 3rem;
            padding: 4rem 1rem;
        }

        &__card {
            flex-wrap: wrap;
            text-align: center;
            gap: 1rem;
            padding-bottom: 0.5rem;
            padding-right: 0;

            &.right {
                flex-wrap: wrap-reverse;
            }
        }

        &__card-text {
            justify-content: center;
            gap: 0.5rem;

            h4 {
                justify-content: center;
            }
        }
    }
}