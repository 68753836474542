.c-dashboard {

    h2 {
        @apply mt-20 text-center;
    }

    &__header {
        @apply flex justify-between mb-10;
    }

    &__status {
        @apply mt-6 clear-both;

        &__container {
            @apply flex content-around justify-between;
        }

        .c-dashboard__status__item__wrapper {

            h6 {
                @apply mt-2 text-12 uppercase font-bold;
            }
        }

        &__item {
            @apply font-bold text-56;
            line-height: 60px;

            &__wrapper {
                @apply justify-center text-center bg-black-gray-5 rounded-3xl;
                width: 30%;
                min-height: 10rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        &__group {
            @apply w-full md:w-80 float-right mt-1;

            .c-dashboard__control {
                @apply rounded-3xl;
            }

            .c-dashboard__value-container {
                padding: .5rem 1.25rem !important;
                line-height: 1.5rem !important;
            }

            .c-dashboard__indicator-separator {
                @apply hidden;
            }

            .c-dashboard__input-container {
                @apply m-0;
            }
        }
    }

    &__statistics {
        @apply mt-6 relative pt-16 pb-20;

        &:after {
            @apply bg-black-gray-5;
            content: '';
            position: absolute;
            left: -5rem;
            top: 0;
            right: 0;
            width: calc(100% + 10rem);
            height: 100%;
            z-index: -1;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            row-gap: 1rem;
            flex-flow: wrap;
        }
    }

    &__toggle-container {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        row-gap: 1rem;
    }

    &__score {
        @apply relative pb-20;

        &:after {
            @apply bg-black-gray-5;
            content: '';
            position: absolute;
            left: -5rem;
            top: 0;
            right: 0;
            width: calc(100% + 10rem);
            height: 100%;
            z-index: -1;
        }

        ;
    }

    &__score-line {
        @apply col-span-3;
    }

    &__score-summary {
        @apply col-span-3;
    }

    &__injury {
        @apply pt-16;

        &__container {
            @apply grid gap-4 grid-cols-2 grid-rows-2 md:grid-cols-4 md:grid-rows-1;
        }

        .c-dashboard__injury__item__wrapper {

            h6 {
                @apply mt-2 text-xs uppercase;
            }
        }

        &__item {
            @apply font-bold text-56;
            line-height: 60px;

            &__wrapper {
                @apply justify-center text-center py-20 rounded-3xl;

                &:nth-of-type(1) {
                    @apply bg-red-light;
                }

                &:nth-of-type(2) {
                    background: #FFF3E4;
                }

                &:nth-of-type(3) {
                    @apply bg-blue-light;
                }

                &:nth-of-type(4) {
                    @apply bg-green-light;
                }
            }
        }
    }

    .c-dashboard__injury__item__wrapper {

        h6 {
            @apply text-xs uppercase;
        }
    }

    &__focuses {
        @apply mt-6 pb-6;

        &__item {
            @apply leading-8 py-1;

            span {
                @apply align-bottom text-2xl font-bold pr-8;
            }
        }

        &__wrapper {
            @apply grid grid-cols-1 md:grid-cols-3 gap-2;
        }
    }

    &__toggle {
        @apply flex;

        justify-content: right;

        &__background {
            @apply block bg-black-gray w-14 rounded-full;
            height: 1.875rem;
        }

        &__dot {
            @apply dot absolute bg-white w-6 h-6 rounded-full transition;
            left: 0.2rem;
            top: 0.2rem;
        }

        &__text {
            @apply ml-3 font-bold text-xs;
        }

        &__wrapper {
            @apply relative;

            &.-unchecked {
                .c-dashboard__toggle__background {
                    background-color: rgb(209, 213, 219);
                }
            }
        }

        label {
            @apply flex items-center cursor-pointer;
        }

        input:checked~.dot {
            transform: translateX(100%);
            left: 0.35rem;
            top: 0.2rem;
        }
    }

    h3 {
        @apply mb-10;
    }

    hr.line {
        @apply mt-20 mb-20;
    }

    &__welcome-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 2.5rem;

        h3 {
            text-align: center;
        }
    }

    &__welcome-image-container {
        max-width: 620px;
        max-height: 333px;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1.5rem;

        &__cancel {
            @apply inline-flex px-4 py-2 bg-white text-sm font-medium text-black-gray underline;
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-dashboard {
        &__header {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            text-align: center;
            row-gap: 1rem;
        }

        &__toggle-container {
            justify-content: center;
        }

        &__score-summary {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-dashboard {
        &__toggle {
            justify-content: center;
        }

        &__focuses {
            &__item {
                span {
                    padding-right: 0.5rem;
                }
            }
        }

        &__status {
            &__item {
                @apply text-32;
            }

            .c-dashboard__status__item__wrapper {
                h6 {
                    @apply text-12 font-bold;
                }
            }
        }

        &__statistics {
            &:after {
                width: calc(100% + 7rem);
            }
        }

        &__score {
            &:after {
                width: calc(100% + 7rem);
            }
        }
    }
}

@media only screen and (min-width: 1199.98px) {
    .c-dashboard {
        &__score-summary {
            grid-column: span 1 / span 1;
        }

        &__score-line {
            grid-column: span 2 / span 2;
        }
    }
}