.c-article-modal {
    padding: 1rem 2.5rem 2.5rem 2.5rem;

    hr {
        @apply border-black-gray-5;
        margin: 1.5rem 0;
    }

    &__header-image-wrapper {
        width: 112px;
        min-width: 112px;
        max-width: 100%;
        border-radius: 10px;

        img {
            border-radius: 10px;
        }
    }

    &__focus-icon {
        div {
            max-width: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            padding: 0.3rem;
            border-radius: 9999px;
            border: 2px solid #FFF;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__content-wrapper {
        p:first-of-type {
            img:first-of-type {
                display: none;
            }
        }

        h1 {
            font-weight: 300;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
        }

        h3 {
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
        }

        h4 {
            font-weight: bold;
            font-size: 12px;
            line-height: 24px;
        }

        h5 {
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
        }

        h6 {
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
        }

        img {
            border-radius: 15px;
        }

        blockquote {
            font-weight: 300;
            font-size: 22px;
            line-height: 32px;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 40px;
            margin-inline-end: 40px;
        }

        ul {
            list-style: disc;
            padding-left: 20px;
        }

        ol {
            list-style: decimal;
            padding-left: 20px;
        }

        p {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }
    }
}