.manage-users-modal {
    h2 {
        @apply text-20 font-light;
        line-height: 25px;
    }

    &__user-list {
        overflow: auto;

        table {
            border-collapse: separate;
            border-spacing: 0 0.5rem;

            input {
                @apply accent-black-gray;
                width: 22px;
                height: 22px;
            }

            thead {
                th {
                    padding: 0 0.5rem;

                    label {
                        all: unset;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.5rem;
                    }
                }
            }

            tbody {
                @apply text-sm;

                tr {
                    @apply hover:bg-blue-light bg-black-gray-5;


                    &.deactivated {
                        @apply italic bg-gray-dark text-white;
                    }

                    td {
                        padding: 1rem 0.5rem;

                        &:first-child {
                            padding-left: 1rem;
                            border-radius: 10px 0 0 10px;
                        }

                        &:last-child {
                            padding-right: 1rem;
                            border-radius: 0 10px 10px 0;
                        }
                    }
                }
            }
        }
    }
}