.c-movement-tips {
    @apply text-12;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;

    .swiper-container-horizontal>.swiper-pagination-bullets {
        &.swiper-pagination {
            bottom: 0;
            padding-bottom: 8%;
        }
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-black-gray;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-slide {
        opacity: 0;

        &.swiper-slide-active {
            // height: 60vh !important;
            opacity: 1;
        }
    }

    .swiper-button-prev {
        @apply text-black-gray;
    }

    .swiper-button-next {
        @apply text-black-gray;
    }

    &__header {
        @apply font-medium text-20;
        padding-top: 15%;
        text-align: center;
        line-height: 40px;
    }

    &__slide {
        padding-left: calc(var(--ion-safe-area-left, 0) + 25px);
        padding-right: calc(var(--ion-safe-area-right, 0) + 25px);
        height: 100%;
        text-align: center;
    }

    &__slide-text {
        @apply font-light text-26;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10%;
        line-height: 44px;
        color: var(--ion-color-main);
        width: 90%;
    }
}

@media only screen and (max-width: 767.98px) {
    .c-movement-tips {
        &__slide-text {
            width: 66%;
        }
    }
}