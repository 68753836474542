.c-user-list {

    &__client-account {
        h4 {
            @apply text-20 font-light;
            font-style: normal;
            line-height: normal;
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    /*Toast open/load animation*/
    .alert-toast {
        -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /*Toast close animation*/
    .alert-toast input:checked~* {
        -webkit-animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &__info-card {
        @apply bg-black-gray-5;
        border-radius: 10px;
        height: 50px;
        padding: 0.5rem;
        text-align: center;
    }

    &__actions {
        @apply float-right;
        margin-top: auto;
        margin-bottom: auto;
    }

    &__button {
        @apply bg-blue-light hover:bg-blue-light text-white font-bold py-2 px-4 rounded;
    }

    &__dialog {
        @apply fixed z-10 inset-0 overflow-y-auto;

        &__actions {
            @apply mt-6;

            &__send {
                @apply float-right inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-blue-light text-base font-medium text-white hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light sm:col-start-2 sm:text-sm;
            }

            &__cancel {
                @apply mt-3 inline-flex pr-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
            }

            &__delete {
                @apply float-right font-bold mt-3 inline-flex pl-4 py-2 bg-white text-base font-medium text-gray sm:mt-0 sm:col-start-1 sm:text-sm underline;
            }
        }

        &__content {
            @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
        }

        &__header {
            @apply inline-block w-full mb-2;

            &__close {
                @apply mx-auto h-12 w-12 items-center justify-center float-right;

                svg {
                    @apply h-6 w-6 text-gray;
                }
            }

            &__title {
                @apply mt-3 text-center sm:mt-5;

                h3 {
                    @apply text-xl font-bold leading-6 font-medium text-gray text-left;
                }
            }
        }

        &__overlay {
            @apply fixed inset-0 bg-gray bg-opacity-75 transition-opacity;
        }

        &__wrapper {
            @apply inline-block align-bottom bg-white rounded-lg px-6 py-8 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-1/2;

            &.-delete {
                @apply sm:w-1/4;
            }
        }
    }

    &__email-errors {
        @apply mt-3 bg-red-light;

        border-radius: 10px;
        padding: 13px;
        padding-left: 26px;
        padding-right: 26px;
        width: fit-content;

        &__item {
            @apply text-red-dark;
        }
    }

    &__email-invites {
        @apply mt-6;

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }
    }

    &__input-wrapper {
        display: flex;
        flex-direction: row;
    }

    &__fieldgroup-row {
        display: flex;

    }

    &__fieldgroup-row-group {
        margin-right: 2%;
        width: 49%;
    }

    &__fieldgroup-row-tag {
        width: 49%;
    }

    &__fieldgroup {
        margin-bottom: 24px;

        label {
            @apply text-14 font-bold;
            font-style: normal;
            line-height: 22px;
        }

        .c-user-list__control {
            @apply rounded-3xl;
            border-color: black;
            // padding: 0.5rem;

            &:hover {
                border-color: black;
            }
        }
    }

    &__fieldgroup-error {
        margin-bottom: 24px;

        label {
            @apply font-medium text-20;
            font-style: normal;
            line-height: 24px;
        }

        .c-email-list__control {
            border-color: #E7514F !important;

            &:hover {
                border-color: #E7514F !important;
            }
        }
    }

    .c-email-list__value-container {
        @apply rounded-3xl;
        border-color: #E7514F !important;
        padding: .4rem 1.25rem !important;
        line-height: 1.5rem !important;

        &:hover {
            border-color: #E7514F !important;
        }
    }


    &__header {
        @apply w-full mb-4;
    }

    &__list {
        @apply overflow-x-auto;

        &__table {
            @apply table-auto border-separate w-full text-left mt-12 border-spacing-y-2;

            // Set border-radius on the top-left and bottom-left of the first table data on the table row
            td:first-child,
            th:first-child {
                border-radius: 10px 0 0 10px;
            }

            // Set border-radius on the top-right and bottom-right of the last table data on the table row
            td:last-child,
            th:last-child {
                border-radius: 0 10px 10px 0;
            }

            td:first-child {
                @apply font-bold;
            }

            th {
                padding: 0.5rem;
            }
        }
    }

    &__item {
        @apply w-full py-2 bg-black-gray-5 hover:bg-blue-light text-black-gray border-white h-20;

        &:hover {
            cursor: pointer;

            .c-button {
                @apply opacity-100;
            }
        }

        .c-button {
            @apply opacity-0;
            margin-right: 2.188rem;
        }

        td:nth-of-type(4) {
            @apply font-bold;
        }

        &.-pending,
        &.-deactivated {
            @apply italic bg-gray-dark text-white;

            td:nth-of-type(4) {
                @apply font-book;
            }
        }

        &.-inactive {

            td:nth-of-type(4) {
                @apply font-book;
            }
        }

        &__content {
            @apply flex-1 min-w-0;

            p {
                @apply text-sm text-gray truncate text-left;

                &.c-user-list__item__content__name {
                    @apply font-medium text-gray;
                }
            }

            &__tag {
                @apply ml-2 flex-shrink-0 inline-block px-2 py-0.5 text-black-gray bg-orange text-xs font-medium rounded-full;
            }
        }

        &__photo {
            @apply flex-shrink-0 w-16 ml-1;

            img,
            svg {
                @apply h-10 w-10 rounded-full;
                margin-left: 10px;
            }
        }

        td {
            padding: 0.5rem;
        }

        td:last-of-type {
            text-align: right;

            button {
                margin: 0.5rem 1.5rem 0.5rem 0;
            }
        }

        &__actions {
            @apply flex items-center justify-end;
            min-height: 80px;
            white-space: nowrap;

            svg {
                width: 26px;
            }
        }


        .c-tag {
            @apply ml-2;
        }
    }

    &__pagination {
        @apply text-center mt-6;

        ul>li {
            @apply inline-block py-2;

            &.ais-Pagination-item {
                &--disabled {
                    @apply text-gray;
                }

                &--firstPage {
                    @apply hidden;
                }
            }

            .ais-Pagination-link {
                @apply px-3 py-2 font-bold rounded;

                &--selected {
                    @apply bg-blue-light hover:bg-blue-light text-white;
                }
            }
        }
    }

    &__search {
        padding-top: 1rem;
        width: 40%;

        button {
            @apply hidden;
        }

        input {
            @apply w-full;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }
    }

    .ais-SortBy {
        @apply hidden;
    }
}

// @media only screen and (max-width: 1503px) {
//     .c-user-list {
//         &__item {
//             &__actions {
//                 @apply flex flex-col justify-center;

//             }
//         }
//     }
// }

@media only screen and (max-width: 767.98px) {
    .c-user-list {
        &__header {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            text-align: center;
        }

        &__actions {
            width: 100%;

            button {
                width: 100%;
            }
        }

        &__search {
            width: 100%;
        }

        &__item {
            height: auto;
            word-break: break-word;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            padding: 1rem;

            td {
                width: 70%;
                padding-left: 0 !important;
                padding-right: 0 !important;

                svg {
                    margin: 0;
                }

                &:last-of-type {
                    button {
                        margin: 0;
                    }
                }
            }

            .c-tag {
                margin: 0;
            }

            .email {
                @apply text-12;
            }
        }

        table {

            td:first-child,
            th:first-child,
            .empty-cell {
                width: 20%;
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                justify-content: left;
            }
        }
    }
}