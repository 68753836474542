.c-subscription-management {
  height: inherit;
  max-width: 100%;
  width: 100%;
  font-size: 13px;

  &__wrapper {
    height: 100%;
  }

  &__icon {
    width: 22px;
    height: 22px;
    margin-right: 1em;
  }

  &__password_container {
    margin-top: 0.5em;
    justify-content: space-between;
    display: flex;
  }

  &_item {
    display: flex;
    margin-bottom: 0.75em;
  }

  &__content {
    @apply mx-16;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__password-form {
    &_input {
      font-size: 13px !important;
      line-height: 1.25rem !important;
      width: 100%;
      margin-bottom: 0px !important;
    }

    &_label {
      margin-bottom: 0 !important;
    }
  }

  &__actions {
    border-top: 1px solid #12162233;
    padding-top: 24px;
    display: flex;
    flex-flow: wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 0.75rem;
    text-align: right;

    &__cancel {
      @apply inline-flex px-4 py-2 bg-white text-sm font-medium text-black-gray underline;
    }
  }

  &__badge {
    @apply bg-green-dark;

    padding: 0.375rem 0.5rem;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

}