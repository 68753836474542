.c-payment-cycle-toggle {
  @apply bg-black-gray;
  width: 50%;
  display: flex;
  border-radius: 30px;
  margin-top: 1rem;

  &__payment-cycle-monthly,
  &__payment-cycle-yearly {
    @apply bg-black-gray border border-solid border-black-gray-20 text-white;
    border-radius: 30px;
    width: 50%;
    padding: 0.5rem;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;

    &.active {
      @apply text-black-gray bg-white;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .c-payment-cycle-toggle {
    width: 90%;
  }
}