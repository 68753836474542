.c-group-list {
    @apply h-full;

    &__actions {
        display: flex;
        justify-content: right;
    }

    &__button {
        @apply bg-blue-light hover:bg-blue-light text-white font-bold py-2 px-4 rounded;
    }

    &__settings {
        .c-group-list__control {
            @apply rounded-3xl;
        }
    }

    &__edit-content {
        @apply mt-6;

        textarea {
            @apply w-full border-solid border border-gray rounded p-2;
        }
    }

    &__email-invites {
        @apply mt-6;
    }

    &__header {
        @apply w-full mb-4;
    }

    &__list {
        @apply pt-6;

        p {
            @apply my-6 w-3/4 font-light;
        }
    }

    &__organization {
        @apply flex border-b border-gray-light font-bold py-2;

        &__data {
            @apply inline-block pr-3 my-auto;
            margin-right: 92px;
        }

        &__title {
            @apply flex-1 my-auto;
        }
    }

    &__search {
        @apply mb-2 pr-4;

        input {
            @apply bg-transparent leading-snug appearance-none text-sm text-gray py-1 px-4 rounded-sm;

            &:focus-visible {
                outline: none;
            }

            /* clears the ‘X’ from Internet Explorer */
            &[type=search]::-ms-clear,
            &[type=search]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }

            /* clears the ‘X’ from Chrome */
            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button,
            &[type="search"]::-webkit-search-results-button,
            &[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }

        button {
            @apply hidden;
        }
    }

    &__user-emails {

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }
    }

    &__user-list {
        @apply mt-6;

        h4 {
            @apply text-base font-bold text-gray mb-2;
        }

        ul {
            @apply border border-gray rounded overflow-auto;
            max-height: 28vh;
        }

        &__action {
            @apply text-center text-sm text-gray;

            button {
                @apply underline;

                &:disabled {
                    @apply text-gray;
                }
            }
        }

        &__item {
            @apply px-4 py-2 cursor-pointer;

            &:hover {
                @apply bg-gray;
            }

            >div {
                display: inline-block;
            }

            &.-unregistered {
                @apply italic text-gray;
            }

            &__email {
                width: 30%;
            }

            &__name {
                width: 65%;

                .c-tag {
                    @apply ml-2;
                }
            }
        }
    }
}

@media only screen and (max-width: 767.98px) {
    .c-group-list {
        &__header {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: center;
            text-align: center;
            row-gap: 1rem;
        }

        &__actions {
            width: 100%;

            button {
                width: 100%;
            }
        }

        &__list {
            p {
                width: 100%;
            }
        }

        &__organization__data {
            margin: 0;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .c-group-list {
        &__header {
            display: flex;
            flex-wrap: wrap;
            row-gap: 1rem;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                width: 66%;
            }
        }

        &__actions {
            width: 100%;
        }
    }
}