.c-add-on-dialog-box {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield !important;
  }

  &__description-list {
    list-style: inside !important;
    list-style-type: disc !important;
  }

  &__description-list-item {
    display: list-item !important;
    margin: 0.5rem 0 !important;
  }

  &__description-list-item::marker {
    font-size: 12px !important;
  }

  &__container {
    position: relative;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: auto !important;
    overflow: hidden;
  }

  &__button-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0px 2.5rem;
  }

  &__remove-information-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__remove-hr {
    margin: 1rem 0 !important;
  }

  &__remove-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__remove-checkboxes {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
    gap: 1.5rem;
  }

  &__remove-checkbox {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    input {
      width: 1.25rem !important;
    }

    input:checked {
      accent-color: black;
    }

    label {
      margin-bottom: 0rem !important;
      flex: 1;
    }
  }

  &__remove-input-container {
    @apply bg-black-gray-5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin: 2rem 0;
  }

  &__remove-input-header {
    @apply text-14 font-medium;
    font-style: normal;
    line-height: 24px;
  }

  &__remove-input {
    width: 200px !important;
    height: 50px;
    text-align: center;
  }

  &__light-text {
    @apply text-14 font-light;
    font-style: normal;
    line-height: 22px;
  }

  &__light-text-16 {
    @apply text-16 font-light;
    font-style: normal;
    line-height: 26px;
  }

  &__bold-text {
    @apply text-14 font-bold;
    font-style: normal;
    line-height: 22px;
  }

  &__bold-text-16 {
    @apply text-16 font-bold;
    font-style: normal;
    line-height: 26px;
  }

  &__interval {
    @apply text-14 font-light;
    color: #82848B;
    font-style: normal;
    line-height: normal;
  }

  &__summary-header {
    @apply text-16 font-bold;
    font-style: normal;
    line-height: 26px;
    /* 162.5% */
  }

  &__summary-total-due {
    @apply text-14 font-bold;
    font-style: normal;
    line-height: 26px;
  }

  &__extra-header {
    @apply text-14 font-bold;
    color: rgba(0, 0, 0, 0.50);
    font-style: normal;
    line-height: 30px;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  }

  &__description {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    &_container {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;

      h1,
      ul {
        @apply text-12 font-bold;
        margin-bottom: 0.5rem;
      }

      &_description {
        width: 60%;
      }

      &_button {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  &__summary-description {
    @apply text-14 font-light;
    margin-top: 2rem;
    font-style: normal;
    line-height: 26px;
  }

  &__summary {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    &_container {
      display: flex;
      flex-direction: column;

      justify-content: space-between;

      h1 {
        @apply font-book;
        font-size: 15px !important;
        margin-bottom: 0.5rem;
      }

      &_pay-line {
        @apply text-14 font-light;
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        font-style: normal;
        line-height: 26px;
        /* 185.714% */
      }

      &_button {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
        gap: 0px 2.5rem;
      }
    }
  }



}